import UrlManager from "../../Shared/UrlManager";
import {ROUTES} from "../../../Bootstrap/ROUTEs";
import Axios, {AxiosResponse} from "axios";
import {Attendance, Vacation} from "../../Attandancees/Data/Tyeps";

export default class UserIO {

    public async fetchEmployee(pin: string): Promise<AxiosResponse<Attendance>> {
        const url = UrlManager.getURL(ROUTES.FETCH_EMPLOYEE);
        return await Axios.get(url + pin);
    }

    public async fetchEmployeeWithVacations(id: string): Promise<AxiosResponse<{
        emp: Attendance,
        vacations: Vacation[]
    }>> {
        const url = UrlManager.getURL(ROUTES.FETCH_EMPLOYEE_WITH_VACATIONS);
        return await Axios.get(url + id);
    }

 public async RequestVacation(vacation:Vacation): Promise<AxiosResponse<Vacation | boolean>> {
        const url = UrlManager.getURL(ROUTES.REQUEST_VACATION);
        return await Axios.post(url  , vacation);
    }


}