export interface SearchDate{
    fromDate:Date,
    toDate:Date
}

export interface Report{
    depId:number;
    details:boolean;
    time:boolean;
    fullVacation:boolean;
    presidencyPaidVacation:boolean;
    presidencyUnPaidVacation:boolean;
    partVacation:boolean;
    dispatch:boolean;
    assign:boolean;
    absent:boolean;
    present:boolean;
    late:boolean;
    softAbsent:boolean;
    fromDate:Date;
    toDate:Date;
    setFromDate:string;
    setToDate:string;
    reportType:ReportType;

    full:boolean;
    part:boolean;
    daily:boolean;
}

export enum ReportType{
    DEPARTMENT,
    OVER_ALL,
}