import {Shift} from "../../Admin/Data/Types";

export interface Attendance {
    id: string,
    oldId?: string,
    depName: string,
    name: string,
    depId: number,
    timeIn: Date,
    timeOut: Date,
    email: string,
    isHidden: boolean,
    d1: boolean,
    d2: boolean,
    d3: boolean,
    d4: boolean,
    d5: boolean,
    d6: boolean,
    d7: boolean,

    e1: boolean,
    e2: boolean,
    e3: boolean,
    e4: boolean,
    e5: boolean,
    e6: boolean,
    e7: boolean,

    n1: boolean,
    n2: boolean,
    n3: boolean,
    n4: boolean,
    n5: boolean,
    n6: boolean,
    n7: boolean,

    attendanceType: AttendanceType,
    employeeType?: EmployeeType,
    academicCertificate: AcademicCertificate,
    position: Position,
}

export enum AttendanceType {
    Present,
    Absence,
    Vacation,
    Late,
    Night,
    Free,
    Assign,
    Dispatch,
    SoftAbsence,
    Presidency_Vacation_Paid,
    Presidency_Vacation_UnPaid,
}

export enum EmployeeType {
    FULL,
    PART,
    DAILY
}

export enum AcademicCertificate {
    BACHELORS,
    DEPLOM,
    MASTER,
    PHD
}

export enum Position {
    ADMINISTRATIVE,
    PROGRAMMER,
    ASSISTANT,
    TEACHER,
    RAPPORTEUR,
    HEAD_OF_DEPARTMENT,
    TEACHER_RAPPORTEUR,
    TEACHER_HEAD_OF_DEPARTMENT
}

export interface EmployeeNote {
    id: string;
    employeeId: string;
    title: string;
    description: string;
    imagePath: string;
    imageFile?: File | null;
    createdDate: Date;
    noteType: EmployeeNoteType;

}

export enum EmployeeNoteType {
    NOTE,
    BOOK
}

export interface EmployeeAttendance {
    id: number;
    employeeId: string;
    depId: number;
    date: Date;
    dateIn: Date | null;
    dateOut: Date | null;
    receivedDateIn: string | null;
    receivedDateOut: string | null;
    archive: boolean;
    archiveReason: string;
    type: AttendanceType;
    dayShift: boolean;
    eveningShift: boolean;
    nighShift: boolean;
    isHidden?: boolean | false;
    shift:Shift;
}

export interface AttendanceList {
    id: number,
    employeeId: string,
    date: Date,
    dateIn: Date,
    dateOut: Date,
    type: AttendanceType,
    shift:Shift
}


export interface EmployeeAttendanceInfo {
    absence: number,
    presence: number,
    vacation: number,
    employee: Attendance,
    attendance: AttendanceList[]
}

export interface DepartmentUpdate {
    depId: number,
    name: string,
    isActive: boolean,
    attendeesCount: number,
    absencesCount: number,
    vacationsCount: number,
    lateCount: number,
    nightCount: number,

    ratio: number,
}

export interface Vacation {
    id: string;
    EmployeeID: string;
    vacationType: VacationType;
    fromDate: Date;
    setFromDate?: string;
    toDate: Date;
    setToDate?: string;
    startTime: Date;
    setStartTime?: string;
    endTime: Date;
    setEndTime?: string;
    requestedDate?: Date;
    paid: boolean;
    needPresidency: boolean;
    requiredPermition?: boolean;
    distnation: string;
    resion: string;
    vacationStatus: VacationStatus;
    succeed?: boolean;
    unSucceedMessage?: string;
}

export enum VacationType {
    FUll,
    Part,
    Assign,
    Dispatch
}

export enum VacationStatus {
    PENDING_DEPARTMENT,
    PENDING_ADMIN,
    PENDING_PRESIDENCY,
    APPROVED_DEPARTMENT,
    APPROVED_ADMIN,
    APPROVED_PRESIDENCY_PAID,
    APPROVED_PRESIDENCY_UNPAID,
    REJECTED_DEPARTMENT,
    REJECTED_ADMIN,
    REJECTED_PRESIDENCY
}

export interface Department {
    id: number,
    title: string
}

export interface VacationResponse {
    emp: Attendance;
    vacation: Vacation;
}

export interface VacationEmployee {
    vacation: Vacation,
    employee: Attendance,
    isHidden: boolean
}

export interface VacationPanel {
    approved: boolean,
    pending: boolean,
    rejected: boolean
}

export enum VacationPanelType {
    APPROVED,
    PENDING,
    REJECTED
}

export interface EmployeeAttendancePanel {
    status: boolean,
    attendance: boolean,
    notes: boolean
    remainderNotes: boolean;
    bookNotes: boolean;
}

export enum EmployeeAttendancePanelType {
    STATUS,
    ATTENDANCE,
    NOTES,
    REMAINDER_NOTES,
    BOOK_NOTES
}

export interface EmployeesPanel {
    create: boolean,
    employees: boolean,
}

export enum EmployeesPanelType {
    CREATE,
    EMPLOYEES
}