import React, {Component} from 'react';
import {Attendance, Vacation, VacationType} from "../Data/Tyeps";
import AttendanceIO from "../IO/AttendanceIO";
import CloudLoader from "../../Shared/Loading/CloudLoader";
import {langs} from "../../../Bootstrap/Langs/Lang";
import {AuthInfo} from "../../Login/Data/Types";
import {UserType} from "../../Shared/Types";
import {Store} from "../../../Bootstrap/Store";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import Initializer from "../../Shared/initializer";
import Time from "../../Shared/time";
import {Config} from "../../Admin/Data/Types";
import AdminIo from "../../Admin/IO/AdminIo";

interface Props {
    id: string;
    authInfo: AuthInfo;
}

interface State {
    vacation: Vacation;
    employee: Attendance;
    config: Config;
    loading: boolean;
}

class VacationComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const initializer = new Initializer();
        this.state = {
            loading: false,
            employee: initializer.employee(),
            vacation: initializer.vacation(this.props.id),
            config: initializer.config()
        };
        this._time = new Time();
        this._admin = new AdminIo();
        this._attendanceIo = new AttendanceIO();
    }

    private _time: Time;
    private _admin: AdminIo;
    private _attendanceIo: AttendanceIO;

    async componentDidMount() {
        console.log(this._time.newCnDate());
        this.setState({loading: true});
        try {
            const employee = await this._attendanceIo.fetchEmployee(this.props.id);
            const config = await this._admin.fetchConfig();
            this.setState({employee: employee.data, config: config.data, loading: false});
        } catch {
            this.setState({loading: false});
        }
    }

    async submit() {
        this.setState({loading: true});
        try {
            let res = await this._attendanceIo.postVacation(this.state.vacation);
            if (res.status == 200) {
                if (typeof res.data !== "boolean") {
                    if (!res.data.succeed) {
                        alert(res.data.unSucceedMessage);
                        this.setState({loading: false});
                        return;
                    }
                    const url = "/VacationDisplay/" + res.data.id;
                    window.open(url, "_blank");
                } else {
                    if (!res.data) {
                        alert("الاجازات الزمنية تجاوزت الحد المسموح");
                    }
                }
            } else {
                alert("نظام الاجازات متوقف حاليا");
            }
        } catch {
            alert("نظام الاجازات متوقف حاليا");
        }
        this.setState({loading: false});
    }

    setPartTime = (time: string, type: string) => {
        if (type == "start") {
            this.state.vacation.setStartTime = ((new Date().toLocaleDateString()) + ' ' + time + ":00");
            this.state.vacation.setFromDate = (new Date().toLocaleDateString()) + ' ' + time + ":00";
        } else {
            this.state.vacation.setEndTime = ((new Date().toLocaleDateString()) + ' ' + time + ":00");
            this.state.vacation.setToDate = (new Date().toLocaleDateString()) + ' ' + time + ":00";
        }
        this.setState({
            vacation: this.state.vacation
        })
    }

    setFullTime = (date: string, type: string) => {
        if (type == "from") {
            this.state.vacation.setFromDate = this._time.getDateTimeFromDateInput(date);
            this.state.vacation.fromDate = new Date(date);
            this.state.vacation.setToDate = this._time.getDateTimeFromDateInput(date);
            this.state.vacation.toDate = new Date(date);
        } else {
            this.state.vacation.setToDate = this._time.getDateTimeFromDateInput(date);
            this.state.vacation.toDate = new Date(date);
        }
        this.setState({vacation: this.state.vacation});
        console.log(this.state.vacation);
    }

    render() {
        return (
            <>
                {this.state.loading &&
                    <CloudLoader title = {langs.loading_data} className = "w-1/6 mx-auto text-center" />
                }
                {!this.state.loading &&
                    <div className = "grid gap-4 bg-gray-200 rounded shadow mx-4 m-auto p-4 sm:text-xs md:text-sm">
                        {/* Name and Department */}
                        <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:gap-8 md:gap-16">
                            {/* Name */}
                            <div className = "grid grid-cols-2 items-center">
                                <label className = "border rounded shadow p-2">الاسم</label>
                                <label className = "border rounded shadow p-2">{this.state.employee.name}</label>
                            </div>
                            {/* Department */}
                            <div className = "grid grid-cols-2 items-center">
                                <label className = "border rounded shadow p-2">القسم</label>
                                <label className = "border rounded shadow p-2">{this.state.employee.depName}</label>
                            </div>
                        </div>

                        <hr />

                        {/* Vacation Type and Paid */}
                        <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:gap-8 md:gap-16">
                            {/* Vacation Type */}
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "VacationType" className = "border rounded shadow p-2">نوع الاجازة</label>
                                <select id = "VacationType" className = "p-2 border rounded shadow bg-white" onChange = {(e) => {
                                    let vac = this.state.vacation;
                                    vac.vacationType = parseInt(e.target.value);
                                    this.setState({vacation: vac});
                                }}>
                                    <option value = {VacationType.FUll} selected>يومية</option>
                                    <option value = {VacationType.Part}>زمنية</option>
                                    {(this.props.authInfo.userType == UserType.ADMIN || this.props.authInfo.userType == UserType.SUPER_ADMIN) &&
                                        <>
                                            <option value = {VacationType.Assign}>تكليف</option>
                                            <option value = {VacationType.Dispatch}>ايفاد</option>
                                        </>
                                    }
                                </select>

                            </div>

                            {/* Paid For Admin */}
                            {this.props.authInfo.userType !== UserType.DEPARTMENT &&
                                <div className = "grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 items-center">
                                    <label htmlFor = "paid" className = "w-full cursor-pointer p-2 flex gap-2 items-center shadow hover:bg-gray-300">
                                        <input id = "paid" type = "checkbox"
                                               checked = {this.state.vacation.paid}
                                               onChange = {(e) => {
                                                   let vac = this.state.vacation;
                                                   vac.paid = e.target.checked;
                                                   this.setState({vacation: vac});
                                               }} />
                                        {this.state.vacation.paid ? "مدفوعه" : "غير مدفوعه"}
                                    </label>
                                    <label htmlFor = "needPresidency" className = "w-full cursor-pointer p-2 flex gap-2 items-center shadow hover:bg-gray-300">
                                        <input id = "needPresidency" type = "checkbox"
                                               checked = {this.state.vacation.needPresidency}
                                               onChange = {(e) => {
                                                   let vac = this.state.vacation;
                                                   vac.needPresidency = e.target.checked;
                                                   this.setState({vacation: vac});
                                               }} />
                                        {this.state.vacation.needPresidency ? "بحاجة امر جامعي" : "بدون حاجة الامر الجامعي"}
                                    </label>
                                </div>
                            }

                            {/* Paid For Department */}
                            {this.props.authInfo.userType === UserType.DEPARTMENT &&
                                <div className = "grid grid-cols-2 items-center">
                                    <label htmlFor = "VacationTypeTitle" className = "border rounded shadow p-2">مدفوعه الاجر؟</label>
                                    <label htmlFor = "paid" className = "p-2 border rounded shadow text-gray-600">
                                        صلاحية العمادة فقط
                                    </label>
                                </div>
                            }
                        </div>

                        <hr />

                        {/* Reason and Destination */}
                        <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:gap-8 md:gap-16">
                            {/* Reason */}
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "reason" className = "border rounded shadow p-2">السبب</label>
                                <input id = "reason" className = "p-2 border rounded shadow"
                                       placeholder = "السبب..." autoFocus={true}
                                       onChange = {(e) => {
                                           let vac = this.state.vacation;
                                           vac.resion = e.target.value;
                                           this.setState({vacation: vac});
                                       }} />
                            </div>

                            {/* Destination */}
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "distenation" className = "border rounded shadow p-2">الجهه</label>
                                <input id = "distenation" className = "p-2 border rounded shadow"
                                       placeholder = "الجهه..."
                                       onChange = {(e) => {
                                           let vac = this.state.vacation;
                                           vac.distnation = e.target.value;
                                           this.setState({vacation: vac});
                                       }} />
                            </div>
                        </div>

                        <hr />

                        {/* Full Vacation Dates */}
                        {this.state.vacation.vacationType != VacationType.Part &&
                            <>
                                {this.props.authInfo.userType !== UserType.DEPARTMENT &&
                                    <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:gap-8 md:gap-16">
                                        <div className = "grid grid-cols-2 items-center">
                                            <label htmlFor = "fromDate" className = "border rounded shadow p-2">من تاريخ</label>
                                            <input id = "fromDate" type = "date" placeholder = "dd-mm-yyyy" value = {this._time.dateInputValue(this.state.vacation.fromDate)} className = "p-2 border rounded shadow" onChange = {(e) => this.setFullTime(e.target.value, "from")} />
                                        </div>
                                        <div className = "grid grid-cols-2 items-center">
                                            <label htmlFor = "toDate" className = "border rounded shadow p-2">الى تاريخ</label>
                                            <input id = "toDate" type = "date" placeholder = "dd-mm-yyyy" value = {this._time.dateInputValue(this.state.vacation.toDate)} className = "p-2 border rounded shadow" onChange = {(e) => this.setFullTime(e.target.value, "to")} />
                                        </div>
                                    </div>
                                }
                                {this.props.authInfo.userType === UserType.DEPARTMENT &&
                                    <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:gap-8 md:gap-16">
                                        <div className = "grid grid-cols-2 items-center">
                                            <label htmlFor = "fromDate" className = "border rounded shadow p-2">تاريخ الاجازة</label>
                                            <input id = "fromDate" type = "date" placeholder = "dd-mm-yyyy" value = {this._time.dateInputValue(this.state.vacation.fromDate)} className = "p-2 border rounded shadow"
                                                   onChange = {(e) => this.setFullTime(e.target.value, "from")}
                                            />
                                        </div>
                                        {this.state.config.allowedContinuesVacationForDepartment > 1 &&
                                            <div className = "grid grid-cols-2 items-center">
                                                <label htmlFor = "toDate" className = "border rounded shadow p-2">الى تاريخ</label>
                                                <input id = "toDate" type = "date" placeholder = "dd-mm-yyyy" value = {this._time.dateInputValue(this.state.vacation.toDate)} className = "p-2 border rounded shadow"
                                                       onChange = {(e) => this.setFullTime(e.target.value, "to")}
                                                />
                                            </div>
                                        }
                                        {this.state.config.allowedContinuesVacationForDepartment <= 1 &&
                                            <div className = "grid grid-cols-2 items-center">
                                                <label htmlFor = "toDate" className = "border rounded shadow p-2">الى تاريخ</label>
                                                <input disabled = {true} id = "toDate" type = "date" placeholder = "dd-mm-yyyy" value = {this._time.dateInputValue(this.state.vacation.fromDate)} className = "p-2 border rounded shadow bg-gray-200 text-gray-600" />
                                            </div>
                                        }
                                    </div>
                                }
                            </>
                        }

                        {/* Part Vacation Times */}
                        {this.state.vacation.vacationType === VacationType.Part &&
                            <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:gap-8 md:gap-16">
                                <div className = "grid grid-cols-2 items-center">
                                    <label htmlFor = "fromTime" className = "border rounded shadow p-2">من الساعة</label>
                                    <input id = "fromTime" type = "time" className = "p-2 border rounded shadow" onChange = {(e) => this.setPartTime(e.target.value, "start")} />
                                </div>
                                <div className = "grid grid-cols-2 items-center">
                                    <label htmlFor = "toTime" className = "border rounded shadow p-2">الى الساعة</label>
                                    <input id = "toTime" type = "time" className = "p-2 border rounded shadow"
                                           onChange = {(e) => this.setPartTime(e.target.value, "end")}
                                    />
                                </div>
                            </div>
                        }

                        <hr />

                        {/* Department Access */}
                        {(this.props.authInfo.userType === UserType.DEPARTMENT && this.state.vacation.vacationType !== VacationType.Part) &&
                            <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:gap-8 md:gap-16">
                                <div className = "grid grid-cols-2 items-center">
                                    <label htmlFor = "toDate" className = "border rounded shadow p-2">الاجازات في الشهر الواحد</label>
                                    <label htmlFor = "toDate" className = "border rounded shadow p-2">({this.state.config.allowedVacationCountForDepartment}) اجازات</label>
                                </div>
                                <div className = "grid grid-cols-2 items-center">
                                    <label htmlFor = "toDate" className = "border rounded shadow p-2">الاجازات المتتالية</label>
                                    <label htmlFor = "toDate" className = "border rounded shadow p-2">({this.state.config.allowedContinuesVacationForDepartment}) اجازات</label>
                                </div>
                            </div>
                        }

                        {/* Start Month and End Month */}
                        <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:gap-8 md:gap-16">
                            <div className = "grid grid-cols-2 items-center">
                                <label className = "border rounded shadow p-2">بداية الشهر</label>
                                <label className = "border rounded shadow p-2">{new Date(this.state.config.startMonth).toLocaleDateString()}</label>
                            </div>
                            <div className = "grid grid-cols-2 items-center">
                                <label className = "border rounded shadow p-2">نهاية الشهر</label>
                                <label className = "border rounded shadow p-2">{new Date(this.state.config.endMonth).toLocaleDateString()}</label>
                            </div>
                        </div>

                        <button onClick = {() => this.submit()} className = {" bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white my-2 py-1 px-4 border border-blue-500 hover:border-transparent rounded"}>حفظ</button>
                    </div>
                }
            </>
        );
    }
}

export default connect((store: Store) => {
    return {
        authInfo: store.LoginReducer
    }
})(withRouter(VacationComponent))