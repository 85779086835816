import React, {Component} from 'react';
import AttendanceIO from "../IO/AttendanceIO";
import {
    AcademicCertificate,
    AttendanceType,
    EmployeeType, Position,
    VacationResponse,
    VacationStatus,
    VacationType
} from "../Data/Tyeps";
import CloudLoader from "../../Shared/Loading/CloudLoader";
import {langs} from "../../../Bootstrap/Langs/Lang";
import Initializer from "../../Shared/initializer";
import EnumTranslate from "../../Shared/enumTranslate";

interface Props {
    id: string;
}

interface State {
    loading: boolean;
    error: boolean;
    vacationData: VacationResponse;
}


class VacationDisplayComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const initializer = new Initializer();
        this.state = {
            loading: true,
            error: false,
            vacationData: initializer.vacationData()
        };
        this._attendanceIo = new AttendanceIO();
        this._enumTranslate = new EnumTranslate();
    }

    private _attendanceIo: AttendanceIO;
    private _enumTranslate:EnumTranslate;

    async componentDidMount() {
        this.setState({loading: true, error: false});
        try {
            const vec = await this._attendanceIo.fetchVacation(this.props.id)
            this.setState({
                loading: false,
                error: false,
                vacationData: vec.data
            })
            setTimeout(window.print, 2000);
        } catch {
            this.setState({loading: false, error: true});
        }
    }

    // componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    //     setTimeout(window.print, 2000);
    // }

    private toDate(date: String): String {
        let today = new Date(date.toString());

        return today.getDate() + "-" + parseInt((today.getMonth() + 1) + "") + "-" + today.getFullYear();
    }

    render() {
        return (

            <div>
                {this.state.loading &&
                    <CloudLoader title = {langs.loading_data} className = "w-1/6 mx-auto text-center" />
                }

                {!this.state.loading &&
                    <div className = "p-3 border-2 border-black m-1 bg-white sm:text-xs md:text-sm">

                        {/*Headers*/}
                        <div className = "grid grid-cols-3 text-center items-center justify-center" style = {{justifyItems: "center"}}>
                            <div className = "pl-4 w-1/2">
                                <img alt = "NO IMAGE" style = {{width: "150px"}} src = {"/uni.png"} />
                            </div>
                            <div className = "font-bold">
                                <p>جامعة المستقبل</p>
                                <p>كلية الهندسة والتقنيات الهندسية</p>
                                <p>{this._enumTranslate.GetVacationTypeArByType(this.state.vacationData.vacation.vacationType)}</p>
                            </div>

                            <div className = "pr-4 w-1/2">
                                <img alt = "NO IMAGE" style = {{width: "170px"}} src = {"/university.png"} />
                            </div>
                        </div>

                        {/*Body*/}
                        <div className = "flex sm:flex-col md:flex-row items-center justify-between p-4">
                            {/*QR Image*/}
                            <img alt = "NO QR" className = "sm:m-auto sm:mb-4 md:m-0 h-full border-2 border-black p-3" src = {"https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=" + window.location.href} />

                            <div dir = "rtl" className = "grid grid-cols-2 gap-4 border border-gray-400 rounded p-2">
                                {/* Name */}
                                <div className = "grid grid-cols-2">
                                    <p className = "font-bold p-2 border rounded shadow bg-gray-200">الاسم</p>
                                    <p className="p-2 border rounded shadow bg-gray-200">{this.state.vacationData.emp.name}</p>
                                </div>

                                {/* Department */}
                                <div className = "grid grid-cols-2">
                                    <p className = "font-bold p-2 border rounded shadow bg-gray-200">القسم</p>
                                    <p className="p-2 border rounded shadow bg-gray-200">{this.state.vacationData.emp.depName}</p>
                                </div>

                                {/* Vacation Date and Time */}
                                {this.state.vacationData.vacation.vacationType != VacationType.Part &&
                                    <>
                                        <div className = "grid grid-cols-2">
                                            <p className = "font-bold p-2 border rounded shadow bg-gray-200">من يوم</p>
                                            <p className="p-2 border rounded shadow bg-gray-200">{this.toDate(new Date(this.state.vacationData.vacation.fromDate).toLocaleDateString())}</p>
                                        </div>
                                        <div className = "grid grid-cols-2">
                                            <p className = "font-bold p-2 border rounded shadow bg-gray-200">الى يوم</p>
                                            <p className="p-2 border rounded shadow bg-gray-200">{this.toDate(new Date(this.state.vacationData.vacation.toDate).toLocaleDateString())}</p>
                                        </div>
                                    </>
                                }

                                {this.state.vacationData.vacation.vacationType == VacationType.Part &&
                                    <>
                                        <div className = "grid grid-cols-2 col-span-2 gap-4">
                                            <p className = "font-bold p-2 border rounded shadow bg-gray-200">اليوم</p>
                                            <p className="p-2 border rounded shadow bg-gray-200">{this.toDate(new Date(this.state.vacationData.vacation.fromDate).toLocaleDateString())}</p>
                                        </div>

                                        <div className = "grid grid-cols-2">
                                            <p className = "font-bold p-2 border rounded shadow bg-gray-200">من الساعة</p>
                                            {/*.replace(/(.*)\D\d+/, '$1').replace("PM", "").replace("AM", "")*/}
                                            <p className="p-2 border rounded shadow bg-gray-200">{(new Date(this.state.vacationData.vacation.startTime)).toLocaleTimeString()}</p>
                                        </div>

                                        <div className = "grid grid-cols-2">
                                            <p className = "font-bold p-2 border rounded shadow bg-gray-200">الى الساعة</p>
                                            <p className="p-2 border rounded shadow bg-gray-200">{(new Date(this.state.vacationData.vacation.endTime)).toLocaleTimeString()}</p>
                                        </div>
                                    </>
                                }

                                {/*Reason*/}
                                <div className = "grid grid-cols-2">
                                    <p className = "font-bold p-2 border rounded shadow bg-gray-200">السبب </p>
                                    <p className="p-2 border rounded shadow bg-gray-200">{this.state.vacationData.vacation.resion.trim() == "" ? "لا يوجد" : this.state.vacationData.vacation.resion}</p>
                                </div>

                                {/*To*/}
                                <div className = "grid grid-cols-2">
                                    <p className = "font-bold p-2 border rounded shadow bg-gray-200">الجهه </p>
                                    <p className="p-2 border rounded shadow bg-gray-200">{this.state.vacationData.vacation.distnation.trim() == "" ? "لا يوجد" : this.state.vacationData.vacation.distnation}</p>
                                </div>

                                {/* Paid */}
                                <div className = "grid grid-cols-2">
                                    <p className = "font-bold p-2 border rounded shadow bg-gray-200">طلبت براتب </p>
                                    <p className="p-2 border rounded shadow bg-gray-200">{this.state.vacationData.vacation.paid ? "نعم" : "كلا"}</p>
                                </div>

                                {/* Status */}
                                <div className = "grid grid-cols-2">
                                    <p className = "font-bold p-2 border rounded shadow bg-gray-200">الحالة </p>
                                    <p className="p-2 border rounded shadow bg-gray-200">{this._enumTranslate.GetVacationStatusAr(this.state.vacationData.vacation.vacationStatus)}</p>
                                </div>
                            </div>
                        </div>

                        {/*Footer*/}
                        <div className = "mx-3 py-2 border border-gray-300 rounded grid grid-cols-3 text-center">
                            <div className = "grid grid-rows-2 gap-3">
                                <div className = "w-1/2 m-auto">
                                    {/*this.state.vacationData.vacation.vacationStatus == VacationStatus.APPROVED_ADMIN &&
                                        <img alt = "توقيع المدير" src = {"/userSig.png"} />
                                    */}
                                </div>
                                <div className = "flex items-center justify-center">
                                    <p>توقيع العميد</p>
                                </div>
                            </div>
                            <div className = "grid grid-rows-2 gap-3">
                                <div className = "w-1/2 m-auto">
                                    {/*(this.state.vacationData.vacation.vacationStatus == VacationStatus.APPROVED_ADMIN || this.state.vacationData.vacation.vacationStatus == VacationStatus.APPROVED_DEPARTMENT || this.state.vacationData.vacation.vacationStatus == VacationStatus.PENDING_ADMIN) &&
                                        <img alt = "توقيع المدير" src = {"/userSig.png"} />
                                    */}
                                </div>
                                <div className = "flex items-center justify-center">
                                    <p>توقيع رئيس القسم</p>
                                </div>
                            </div>
                            <div className = "grid grid-rows-2 gap-3">
                                <div className = "w-1/2 m-auto">
                                    {/*<img alt = "توقيع المدير" src = {"/userSig.png"} />*/}
                                </div>
                                <div className = "flex items-center justify-center">
                                    <p>توقيع صاحب الطلب</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default VacationDisplayComponent;