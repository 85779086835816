import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import CloudLoader from "../Shared/Loading/CloudLoader";
import {langs} from "../../Bootstrap/Langs/Lang";
import {withRouter} from "react-router-dom";
import ReportIO from "./IO/ReportIo";
import {Store} from "../../Bootstrap/Store";
import {connect} from "react-redux";
import {AuthInfo} from "../Login/Data/Types";
import {Report, ReportType, SearchDate} from "./Data/Types";

interface Props {
    history: any;
    location: {
        Search: string;
    };
    authInfo: AuthInfo;
}

interface State {
    loading: boolean;
    error: boolean;
    searchDate: SearchDate;
    departments: any;
    departmentPanel: boolean;
    allPanel: boolean;
    report: Report;
    allChecked: boolean;
}

class AttendanceMainComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this._ReportIO = new ReportIO();

        this.state = {
            loading: false,
            error: false,
            allChecked: false,
            searchDate: {fromDate: new Date(), toDate: new Date()},
            departments: null,
            departmentPanel: true,
            allPanel: false,
            report: this._ReportIO.initializeReport()
        };
    }

    async componentDidMount() {
        //this.loadData().then();
        await this.fetchDepartments()
    }

    private _ReportIO: ReportIO;

    // API Request
    fetchReportData = async () => {
        this.state.report.setFromDate = this.state.searchDate.fromDate.toLocaleDateString() + " 00:00";
        this.state.report.setToDate = this.state.searchDate.toDate.toLocaleDateString() + " 00:00";

        this.setState({error: false, loading: true, report:this.state.report});
        try {
            const response = await this._ReportIO.fetchReportData(this.state.report);
            //console.log(response);
            this.exportToCsv("report.csv", response.data);
            this.setState({error: false, loading: false});
        } catch{
            this.setState({loading: false, error: true});
        }
    }
    fetchDepartments = async () => {
        this.setState({loading: true, error: false});
        try {
            const response = await this._ReportIO.fetchDepartments();
            this.state.report.depId = response["data"][0]["id"];
            console.log(this.state.report.depId);
            this.setState({loading: false, error: false, report: this.state.report, departments: response["data"]});
        } catch (e) {
            this.setState({loading: false, error: true});
            setTimeout(() => this.setState({error: false}), 5000);
        }
    }
    processRow = (row) => {
        let finalVal = '';
        for (let j = 0; j < row.length; j++) {
            let innerValue = row[j] === null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            }
            let result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ',';
            finalVal += result;
        }
        return finalVal + '\n';
    };
    exportToCsv(filename:string, rows) {
        let csvFile = '';

        for (let i = 0; i < rows.length; i++) {
            csvFile += this.processRow(rows[i]);
        }

        const BOM = "\uFEFF";
        rows = BOM + rows;

        const blob = new Blob([rows], {type: "text/csv;charset=utf-8"});
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement("a");
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    // UI State
    changeFromDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchDate: {
                fromDate: new Date(e.target.value),
                toDate: new Date(e.target.value)
            }
        });
    };
    changeToDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchDate: {
                fromDate: this.state.searchDate.fromDate,
                toDate: new Date(e.target.value)
            }
        });
    };
    departmentPanel = () => {
        this.state.report.reportType = ReportType.DEPARTMENT;
        this.setState({departmentPanel: true, allPanel: false, report: this.state.report})
    }
    allPanel = () => {
        this.state.report.reportType = ReportType.OVER_ALL;
        this.setState({departmentPanel: false, allPanel: true, report: this.state.report})
    }
    selectAll = (checked: boolean) => {
        this.state.report.details = checked;
        this.state.report.fullVacation = checked;
        this.state.report.partVacation = checked;
        this.state.report.dispatch = checked;
        this.state.report.assign = checked;
        this.state.report.absent = checked;
        this.state.report.present = checked;
        this.state.report.late = checked;
        this.state.report.time = checked;
        this.state.report.full = checked;
        this.state.report.part = checked;
        this.state.report.softAbsent = checked;
        this.state.report.presidencyPaidVacation = checked;
        this.state.report.presidencyUnPaidVacation = checked;
        this.setState({report: this.state.report, allChecked: checked});
    }

    render() {
        return (
            <>
                {!this.state.loading &&
                    <div className = "mx-2 m-auto p-2 rounded shadow sm:text-xxs md:text-sm">
                        {/*Panels*/}
                        <div className = "flex border-b border-blue-600 mb-5 sm:text-xxs md:text-sm">
                            <p className = {"p-3 cursor-pointer hover:text-blue-600" + (this.state.departmentPanel ? " border-b-4 border-blue-600" : "")} onClick = {this.departmentPanel}>تــقــريــر لــقــســم</p>
                            <p className = {"p-3 cursor-pointer hover:text-blue-600" + (this.state.allPanel ? " border-b-4 border-blue-600" : "")} onClick = {this.allPanel}>تــقــريــر شــامــل</p>
                        </div>

                        <div className = "grid gap-4 p-4">
                            {/* Department Report*/}
                            {this.state.departmentPanel &&
                                <div className = "flex flex-col gap-8 p-8 shadow rounded border border-gray-400">
                                    <div>
                                        <h1 className = "text-center">تــقــريــر لــقــســم</h1>
                                    </div>

                                    <div className = " grid gap-2">
                                        <label>اختر القسم</label>
                                        <select className = "border border-gray-400 rounded shadow p-2" onChange = {(e) => {
                                            this.state.report.depId = parseInt(e.target.value)
                                        }}>
                                            {
                                                this.state.departments && this.state.departments.map((value: {
                                                    id: number,
                                                    title: string
                                                }) => (
                                                    <option key = {value.id} value = {value.id}>{value.title}</option>
                                                ))
                                            }
                                        </select>
                                    </div>

                                    {/*Report Content*/}
                                    <div className="grid gap-3 border border-gray-400 p-2">
                                        <p>محتوى التقرير</p>

                                        {/* All and Details */}
                                        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none hover:bg-gray-200">
                                                <input type="checkbox" value="true" checked={this.state.allChecked}
                                                       onChange={(e) => this.selectAll(e.target.checked)}/>
                                                الكل
                                            </label>
                                        </div>

                                        <hr/>

                                        {/* Day and Time */}
                                        <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2">
                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none hover:bg-gray-200">
                                                <input type="checkbox" value="true" checked={this.state.report.details}
                                                       onChange={() => {
                                                           this.state.report.details = !this.state.report.details;
                                                           this.setState({report: this.state.report});
                                                       }}/>
                                                الايام
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none hover:bg-gray-200">
                                                <input type="checkbox" value="true" checked={this.state.report.time}
                                                       onChange={() => {
                                                           this.state.report.time = !this.state.report.time;
                                                           this.setState({report: this.state.report});
                                                       }}/>
                                                الاوقات
                                            </label>
                                        </div>

                                        <hr/>

                                        {/* Contract */}
                                        <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2">
                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none hover:bg-gray-200">
                                                <input type="checkbox" value="true"
                                                       checked={this.state.report.full} onChange={() => {
                                                    this.state.report.full = !this.state.report.full;
                                                    this.setState({report: this.state.report});
                                                }}/>
                                                دائمي
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none hover:bg-gray-200">
                                                <input type="checkbox" value="true"
                                                       checked={this.state.report.part} onChange={() => {
                                                    this.state.report.part = !this.state.report.part;
                                                    this.setState({report: this.state.report});
                                                }}/>
                                                جزئي
                                            </label>

                                            {/*<label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none">
                                                <input type="checkbox" value="true"
                                                       checked={this.state.report.daily} onChange={() => {
                                                    this.state.report.daily = !this.state.report.daily;
                                                    this.setState({report: this.state.report});
                                                }}/>
                                                اجر يومي
                                            </label>*/}
                                        </div>

                                        <hr/>

                                        {/* Other Filters */}
                                        <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2">
                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none hover:bg-gray-200">
                                                <input type="checkbox" value="true"
                                                       checked={this.state.report.fullVacation} onChange={() => {
                                                    this.state.report.fullVacation = !this.state.report.fullVacation;
                                                    this.setState({report: this.state.report});
                                                }}/>
                                                الاجازات الاعتيادية
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none hover:bg-gray-200">
                                                <input type="checkbox" value="true"
                                                       checked={this.state.report.presidencyPaidVacation} onChange={() => {
                                                    this.state.report.presidencyPaidVacation = !this.state.report.presidencyPaidVacation;
                                                    this.setState({report: this.state.report});
                                                }}/>
                                                الاجازات بامر جامعي(براتب)
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none hover:bg-gray-200">
                                                <input type="checkbox" value="true"
                                                       checked={this.state.report.presidencyUnPaidVacation} onChange={() => {
                                                    this.state.report.presidencyUnPaidVacation = !this.state.report.presidencyUnPaidVacation;
                                                    this.setState({report: this.state.report});
                                                }}/>
                                                الاجازات بامر جامعي(بدون راتب)
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none hover:bg-gray-200">
                                                <input type="checkbox" value="true"
                                                       checked={this.state.report.partVacation} onChange={() => {
                                                    this.state.report.partVacation = !this.state.report.partVacation;
                                                    this.setState({report: this.state.report});
                                                }}/>
                                                الاجازات الزمنية
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none hover:bg-gray-200">
                                                <input type="checkbox" value="true" checked={this.state.report.dispatch}
                                                       onChange={() => {
                                                           this.state.report.dispatch = !this.state.report.dispatch;
                                                           this.setState({report: this.state.report});
                                                       }}/>
                                                ايفاد
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none hover:bg-gray-200">
                                                <input type="checkbox" value="true" checked={this.state.report.assign}
                                                       onChange={() => {
                                                           this.state.report.assign = !this.state.report.assign;
                                                           this.setState({report: this.state.report});
                                                       }}/>
                                                تكليف
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none hover:bg-gray-200">
                                                <input type="checkbox" value="true" checked={this.state.report.absent}
                                                       onChange={() => {
                                                           this.state.report.absent = !this.state.report.absent;
                                                           this.setState({report: this.state.report});
                                                       }}/>
                                                غياب
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none hover:bg-gray-200">
                                                <input type="checkbox" value="true" checked={this.state.report.present}
                                                       onChange={() => {
                                                           this.state.report.present = !this.state.report.present;
                                                           this.setState({report: this.state.report});
                                                       }}/>
                                                حظور
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none hover:bg-gray-200">
                                                <input type="checkbox" value="true" checked={this.state.report.late}
                                                       onChange={() => {
                                                           this.state.report.late = !this.state.report.late;
                                                           this.setState({report: this.state.report});
                                                       }}/>
                                                تاخير
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none hover:bg-gray-200">
                                                <input type="checkbox" value="true"
                                                       checked={this.state.report.softAbsent} onChange={() => {
                                                    this.state.report.softAbsent = !this.state.report.softAbsent;
                                                    this.setState({report: this.state.report});
                                                }}/>
                                                قطع يوم
                                            </label>
                                        </div>
                                    </div>

                                    {/*From To*/}
                                    <div className="grid grid-cols-3 items-center justify-center">
                                        <input className="border border-gray-400 rounded shadow p-2" type="Date"
                                               value={this.state.searchDate.fromDate.toISOString().slice(0, 10)}
                                               onChange={this.changeFromDate}/>
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-0" style={{width: "100%"}}/>
                                        <input className="border border-gray-400 rounded shadow p-2" type="Date"
                                               value={this.state.searchDate.toDate.toISOString().slice(0, 10)}
                                               onChange={this.changeToDate}/>
                                    </div>

                                    {/*Download*/}
                                    <button id="btnExport" type="button" title={"تحميل تقرير القسم"}
                                            onClick={this.fetchReportData}
                                            className="shadow bg-transparent hover:bg-blue-500 text-blue-700 font-semibold items-start hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded-sm text-sm">
                                        <FontAwesomeIcon icon={faArrowDown} className="mr-0  sm:text-sm"/>
                                    </button>
                                </div>
                            }

                            {/* Collage Report*/}
                            {/*<div className = "p-4 card">
                            <div className = "flex flex-col gap-8 p-8" style = {{
                                boxShadow: "0 0 10px -5px",
                                borderRadius: "10px"
                            }}>
                                <div>
                                    <h1 style = {{width: "100%", textAlign: "center"}}>تــقــريــر لــكــلــيــة</h1>
                                </div>
                                <div className = "flex flex-col gap-8 flex-grow">
                                    <div className = "flex flex-col">
                                        <label className = "pb-2">اختر الكلية</label>
                                        <select className = "w-full p-2 cursor-pointer shadow">
                                            <option value = "0">الهندسة</option>
                                        </select>
                                    </div>
                                    <div className = "flex flex-col">
                                        <label className = "pb-2">اختر نوع التقرير</label>
                                        <select className = "w-full p-2 cursor-pointer shadow">
                                            <option value = "0" selected>شامل</option>
                                            <option value = "1">غياب</option>
                                            <option value = "2">تاخير</option>
                                            <option value = "3">مسائي</option>
                                            <option value = "4">اجازات</option>
                                        </select>
                                    </div>
                                </div>
                                <div className = "flex flex-col gap-8">
                                    <div className = "flex flex-col items-center">
                                        <input className = "appearance-none bg-transparent border-none font-bold text-gray-700 py-1 px-2 leading-tight focus:outline-none shadow" type = "Date" value = {this.state.searchDate.fromDate.toISOString().slice(0, 10)} onChange = {this.changeFromDate} />
                                        <div className = "text-lg">
                                            <FontAwesomeIcon icon = {faArrowDown} className = "mr-0  sm:text-sm" />
                                        </div>
                                        <input className = "appearance-none bg-transparent border-none font-bold text-gray-700 py-1 px-2 leading-tight focus:outline-none shadow" type = "Date" value = {this.state.searchDate.toDate.toISOString().slice(0, 10)} onChange = {this.changeToDate} />
                                    </div>
                                    <button id = "btnExport" type = "button" title = {"تحميل تقرير الكلية"} onClick = {this.fetchCollageReport} className = "shadow bg-transparent hover:bg-blue-500 text-blue-700 font-semibold items-start hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded-sm text-sm">
                                        <FontAwesomeIcon icon = {faArrowDown} className = "mr-0  sm:text-sm" />
                                    </button>
                                </div>
                            </div>
                        </div>*/}

                            {/* Over All Report*/}
                            {this.state.allPanel &&
                                <div className = "flex flex-col gap-8 p-8 shadow rounded border border-gray-400">
                                    <div>
                                        <h1 className = "text-center">تــقــريــر شــامــل</h1>
                                    </div>

                                    {/*Report Content*/}
                                    <div className="grid gap-3 border border-gray-400 p-2">
                                        <p>محتوى التقرير</p>

                                        {/* All and Details */}
                                        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none">
                                                <input type="checkbox" value="true" checked={this.state.allChecked}
                                                       onChange={(e) => this.selectAll(e.target.checked)}/>
                                                الكل
                                            </label>
                                        </div>

                                        <hr/>

                                        {/* Day and Time */}
                                        <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2">
                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none">
                                                <input type="checkbox" value="true" checked={this.state.report.details}
                                                       onChange={() => {
                                                           this.state.report.details = !this.state.report.details;
                                                           this.setState({report: this.state.report});
                                                       }}/>
                                                الايام
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none">
                                                <input type="checkbox" value="true" checked={this.state.report.time}
                                                       onChange={() => {
                                                           this.state.report.time = !this.state.report.time;
                                                           this.setState({report: this.state.report});
                                                       }}/>
                                                الاوقات
                                            </label>
                                        </div>

                                        <hr/>

                                        {/* Contract */}
                                        <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2">
                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none">
                                                <input type="checkbox" value="true"
                                                       checked={this.state.report.full} onChange={() => {
                                                    this.state.report.full = !this.state.report.full;
                                                    this.setState({report: this.state.report});
                                                }}/>
                                                دائمي
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none">
                                                <input type="checkbox" value="true"
                                                       checked={this.state.report.part} onChange={() => {
                                                    this.state.report.part = !this.state.report.part;
                                                    this.setState({report: this.state.report});
                                                }}/>
                                                جزئي
                                            </label>

                                            {/*<label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none">
                                                <input type="checkbox" value="true"
                                                       checked={this.state.report.daily} onChange={() => {
                                                    this.state.report.daily = !this.state.report.daily;
                                                    this.setState({report: this.state.report});
                                                }}/>
                                                اجر يومي
                                            </label>*/}
                                        </div>

                                        <hr/>

                                        {/* Other Filters */}
                                        <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2">
                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none">
                                                <input type="checkbox" value="true"
                                                       checked={this.state.report.fullVacation} onChange={() => {
                                                    this.state.report.fullVacation = !this.state.report.fullVacation;
                                                    this.setState({report: this.state.report});
                                                }}/>
                                                الاجازات الاعتيادية
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none">
                                                <input type="checkbox" value="true"
                                                       checked={this.state.report.presidencyPaidVacation} onChange={() => {
                                                    this.state.report.presidencyPaidVacation = !this.state.report.presidencyPaidVacation;
                                                    this.setState({report: this.state.report});
                                                }}/>
                                                الاجازات بامر جامعي(براتب)
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none">
                                                <input type="checkbox" value="true"
                                                       checked={this.state.report.presidencyUnPaidVacation} onChange={() => {
                                                    this.state.report.presidencyUnPaidVacation = !this.state.report.presidencyUnPaidVacation;
                                                    this.setState({report: this.state.report});
                                                }}/>
                                                الاجازات بامر جامعي(بدون راتب)
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none">
                                                <input type="checkbox" value="true"
                                                       checked={this.state.report.partVacation} onChange={() => {
                                                    this.state.report.partVacation = !this.state.report.partVacation;
                                                    this.setState({report: this.state.report});
                                                }}/>
                                                الاجازات الزمنية
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none">
                                                <input type="checkbox" value="true" checked={this.state.report.dispatch}
                                                       onChange={() => {
                                                           this.state.report.dispatch = !this.state.report.dispatch;
                                                           this.setState({report: this.state.report});
                                                       }}/>
                                                ايفاد
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none">
                                                <input type="checkbox" value="true" checked={this.state.report.assign}
                                                       onChange={() => {
                                                           this.state.report.assign = !this.state.report.assign;
                                                           this.setState({report: this.state.report});
                                                       }}/>
                                                تكليف
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none">
                                                <input type="checkbox" value="true" checked={this.state.report.absent}
                                                       onChange={() => {
                                                           this.state.report.absent = !this.state.report.absent;
                                                           this.setState({report: this.state.report});
                                                       }}/>
                                                غياب
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none">
                                                <input type="checkbox" value="true" checked={this.state.report.present}
                                                       onChange={() => {
                                                           this.state.report.present = !this.state.report.present;
                                                           this.setState({report: this.state.report});
                                                       }}/>
                                                حظور
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none">
                                                <input type="checkbox" value="true" checked={this.state.report.late}
                                                       onChange={() => {
                                                           this.state.report.late = !this.state.report.late;
                                                           this.setState({report: this.state.report});
                                                       }}/>
                                                تاخير
                                            </label>

                                            <label
                                                className="bg-white border border-gray-400 rounded shadow p-2 flex gap-2 cursor-pointer select-none">
                                                <input type="checkbox" value="true"
                                                       checked={this.state.report.softAbsent} onChange={() => {
                                                    this.state.report.softAbsent = !this.state.report.softAbsent;
                                                    this.setState({report: this.state.report});
                                                }}/>
                                                قطع يوم
                                            </label>
                                        </div>
                                    </div>

                                    {/*From To*/}
                                    <div className="grid grid-cols-3 items-center justify-center">
                                        <input className="border border-gray-400 rounded shadow p-2" type="Date"
                                               value={this.state.searchDate.fromDate.toISOString().slice(0, 10)}
                                               onChange={this.changeFromDate}/>
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-0" style={{width: "100%"}}/>
                                        <input className="border border-gray-400 rounded shadow p-2" type="Date"
                                               value={this.state.searchDate.toDate.toISOString().slice(0, 10)}
                                               onChange={this.changeToDate}/>
                                    </div>

                                    {/*Download*/}
                                    <button id="btnExport" type="button" title={"تحميل تقرير القسم"}
                                            onClick={this.fetchReportData}
                                            className="shadow bg-transparent hover:bg-blue-500 text-blue-700 font-semibold items-start hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded-sm text-sm">
                                        <FontAwesomeIcon icon={faArrowDown} className="mr-0  sm:text-sm"/>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                }
                {this.state.loading &&
                    <CloudLoader title = {langs.loading_data} className = "w-1/6 mx-auto text-center" />
                }
                {this.state.error &&
                    <div id = {"errDiv"} className = "p-2 rounded shadow text-white">
                        <p>حدث خطأ ما. يرجى اعادة المحاولة مجددا</p>
                    </div>
                }
            </>


        );
    }

}


export default connect((store: Store) => {
    return {
        authInfo: store.LoginReducer
    }
})(withRouter(AttendanceMainComponent))