import React, {Component} from 'react';
import AttendanceIO from "../IO/AttendanceIO";
import {
    Vacation,
    VacationEmployee,
    VacationPanel,
    VacationPanelType,
    VacationStatus,
    VacationType
} from "../Data/Tyeps";
import CloudLoader from "../../Shared/Loading/CloudLoader";
import {langs} from "../../../Bootstrap/Langs/Lang";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faCheck, faStopCircle, faSyncAlt, faTrash} from "@fortawesome/free-solid-svg-icons";
import {AuthInfo} from "../../Login/Data/Types";
import {Store} from "../../../Bootstrap/Store";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {UserType} from "../../Shared/Types";
import EnumTranslate from "../../Shared/enumTranslate";

interface Props {
    authInfo: AuthInfo;
    history: any;
}

interface State {
    vacationEmployee: VacationEmployee[],
    displayVacationEmployee: VacationEmployee[],
    loading: boolean;
    Search: string;
    vacationPanel: VacationPanel;
}

class Vacations extends Component <Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            displayVacationEmployee: [],
            vacationPanel: {approved: false, pending: true, rejected: false},
            vacationEmployee: [],
            loading: true,
            Search: ""
        }
        this._attendanceIO = new AttendanceIO();
        this._enumTranslate = new EnumTranslate();
    }

    private _attendanceIO: AttendanceIO;
    private _enumTranslate:EnumTranslate;

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        this.setState({loading: true})
        try {
            let data = await this._attendanceIO.fetchVacations();
            data.data.forEach(item => {
                item.isHidden = false;
            })
            this.setState({
                vacationEmployee: this.props.authInfo.userType == UserType.DEPARTMENT ? data.data.filter(d => d.employee.depId == this.props.authInfo.depId) : data.data,
            })
            if (this.state.vacationPanel.approved) {
                this.setState({displayVacationEmployee: this.state.vacationEmployee.filter(item => (item.vacation.vacationStatus == VacationStatus.APPROVED_ADMIN || item.vacation.vacationStatus == VacationStatus.APPROVED_DEPARTMENT || item.vacation.vacationStatus == VacationStatus.APPROVED_PRESIDENCY_PAID || item.vacation.vacationStatus == VacationStatus.APPROVED_PRESIDENCY_UNPAID))})
            } else if (this.state.vacationPanel.pending) {
                this.setState({displayVacationEmployee: this.state.vacationEmployee.filter(item => (item.vacation.vacationStatus == VacationStatus.PENDING_ADMIN || item.vacation.vacationStatus == VacationStatus.PENDING_DEPARTMENT || item.vacation.vacationStatus == VacationStatus.PENDING_PRESIDENCY))})
            } else {
                this.setState({displayVacationEmployee: this.state.vacationEmployee.filter(item => (item.vacation.vacationStatus == VacationStatus.REJECTED_ADMIN || item.vacation.vacationStatus == VacationStatus.REJECTED_DEPARTMENT || item.vacation.vacationStatus == VacationStatus.REJECTED_PRESIDENCY))})
            }
            this.search(this.state.Search);
        } catch {

        }
        this.setState({loading: false});
        (document.getElementById("search") as HTMLInputElement).focus();
    }

    private async Delete(Id: string) {
        const result = window.confirm("هل انت متاكد من حذف الاجازة");
        if (result) {
            await this._attendanceIO.deleteVacations(Id);
            await this.loadData();
        }
    }

    search = (searchValue: string) => {
        this.setState({Search: searchValue});
        // if (this.props.authInfo.depId > 0) {
        //     searchValue = this.props.authInfo.depName;
        // } else {
        //     this.setState({Search: searchValue});
        // }


        let arr: VacationEmployee[] = [];
        this.state.vacationEmployee.forEach((client, index, array) => {
            array[index].isHidden = !(client.employee.name.includes(searchValue) || client.employee.depName.includes(searchValue) || (client.vacation.paid && searchValue.includes("مدفوعة")));
            arr = array;
        })
        this.setState({vacationEmployee: arr});
    };

    navigate = (e, href: string, newTab: boolean) => {
        const a = document.createElement('a');
        a.href = href;
        if (newTab) {
            a.setAttribute('target', '_blank');
        }
        a.click();
    }

    changePanel = (panel: VacationPanelType) => {
        switch (panel) {
            case VacationPanelType.APPROVED:
                this.state.vacationPanel.approved = true;
                this.state.vacationPanel.pending = false;
                this.state.vacationPanel.rejected = false;
                this.setState({loading:true, displayVacationEmployee: this.state.vacationEmployee.filter(item => (item.vacation.vacationStatus == VacationStatus.APPROVED_ADMIN || item.vacation.vacationStatus == VacationStatus.APPROVED_DEPARTMENT || item.vacation.vacationStatus == VacationStatus.APPROVED_PRESIDENCY_PAID || item.vacation.vacationStatus == VacationStatus.APPROVED_PRESIDENCY_UNPAID))})
                this.setState({loading:false});
                break;
            case VacationPanelType.PENDING:
                this.state.vacationPanel.approved = false;
                this.state.vacationPanel.pending = true;
                this.state.vacationPanel.rejected = false;
                this.setState({loading:true, displayVacationEmployee: this.state.vacationEmployee.filter(item => (item.vacation.vacationStatus == VacationStatus.PENDING_ADMIN || item.vacation.vacationStatus == VacationStatus.PENDING_DEPARTMENT || item.vacation.vacationStatus == VacationStatus.PENDING_PRESIDENCY))})
                this.setState({loading:false});
                break;
            case VacationPanelType.REJECTED:
                this.state.vacationPanel.approved = false;
                this.state.vacationPanel.pending = false;
                this.state.vacationPanel.rejected = true;
                this.setState({loading:true, displayVacationEmployee: this.state.vacationEmployee.filter(item => (item.vacation.vacationStatus == VacationStatus.REJECTED_ADMIN || item.vacation.vacationStatus == VacationStatus.REJECTED_DEPARTMENT || item.vacation.vacationStatus == VacationStatus.REJECTED_PRESIDENCY))})
                this.setState({loading:false});
                break;
        }
        this.setState({vacationPanel: this.state.vacationPanel});
        (document.getElementById("search") as HTMLInputElement).focus();
    }

    Reject = async (vacationId: string) => {
        this.setState({loading: true})
        try {
            const res = await this._attendanceIO.rejectVacation(vacationId);
            await this.loadData();
        } catch {
            console.log("Error Rejected!!")
        }
        this.setState({loading: false});
    }

    Approve = async (vacationId: string) => {
        this.setState({loading: true})
        try {
            const res = await this._attendanceIO.approveVacation(vacationId);
            await this.loadData();
        } catch {
            console.log("Error Approved!!")
        }
        this.setState({loading: false});
    }

    ApprovePaid = async (vacationId: string, status: VacationStatus) => {
        this.setState({loading: true})
        try {
            const res = await this._attendanceIO.approveVacationPaid(vacationId, status);
            await this.loadData();
        } catch {

        }
        this.setState({loading: false});
    }

    render() {
        return (
            <>
                <div className = 'sm:m-0 sm:p-2 md:mr-8 md:ml-8 md:mt-12  md:p-4 bg-white overflow-hidden m-auto rounded shadow-xl'>
                    {/*Panels*/}
                    <div className = "flex border-b border-blue-600 mb-5 text-sm">
                        <p className = {"p-3 cursor-pointer hover:text-blue-600" + (this.state.vacationPanel.approved ? " border-b-4 border-blue-600" : "")} onClick = {() => this.changePanel(VacationPanelType.APPROVED)}>الموافق عليها</p>
                        <p className = {"p-3 cursor-pointer hover:text-blue-600" + (this.state.vacationPanel.pending ? " border-b-4 border-blue-600" : "")} onClick = {() => this.changePanel(VacationPanelType.PENDING)}>في انتظار الموافقة</p>
                        <p className = {"p-3 cursor-pointer hover:text-blue-600" + (this.state.vacationPanel.rejected ? " border-b-4 border-blue-600" : "")} onClick = {() => this.changePanel(VacationPanelType.REJECTED)}>المرفوضة</p>
                    </div>

                    {/*Controllers*/}
                    <div className = "flex sm:gap-4 md:gap-4 flex-row lg:gap-2 pb-8">
                        {/*Buttons*/}
                        <div className = "flex gap-2">
                            <button onClick = {this.loadData} id = "refresh" aria-label = "Refresh" title = {"اعادة تحميل"} className = "flex-1 shadow bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded-sm text-sm">
                                <FontAwesomeIcon icon = {faSyncAlt} className = "mr-0  sm:text-sm" />
                            </button>
                        </div>

                        {/*Inputs*/}
                        <div className = "flex flex-1">
                            <input
                                className = "flex-1 shadow appearance-none bg-transparent border-none text-gray-700 py-1 px-2 focus:outline-none"
                                type = "text" id="search" placeholder = {langs.Search} autoFocus={true} aria-label = "Full name"
                                onChange = {(e) => e.target.value != undefined && this.search(e.target.value)}
                                value = {this.state.Search} />
                        </div>
                    </div>

                    {/*Vacations*/}
                    <div className = "sm:text-xxs md:text-xs lg:text-sm">
                        {this.state.loading &&
                            <CloudLoader title = {langs.loading_data} className = "w-1/6 mx-auto text-center" />
                        }

                        {!this.state.loading &&
                            <table className = "text-center w-full select-none">
                                <thead>
                                <tr>
                                    <th className = "pb-4">{langs.index}</th>
                                    <th className = "pb-4">{langs.name}</th>
                                    <th className = "pb-4">القسم</th>
                                    <th className = "pb-4">{"نوع الاجازة"}</th>
                                    <th className = "pb-4">{"من و الى"}</th>
                                    {!this.state.vacationPanel.pending &&
                                        <th className = "pb-4">{"اليوم"}</th>
                                    }
                                    <th className = "pb-4">{"الحالة"}</th>
                                    <th className = "pb-4"></th>
                                </tr>
                                </thead>

                                <tbody>
                                {this.state.displayVacationEmployee.length > 0 && this.state.displayVacationEmployee.filter(item => !item.isHidden).map((value, index) => {
                                    return (
                                        <tr key = {index} className = {"cursor-pointer hover:bg-blue-200 " + (index % 2 === 0 ? "bg-gray-100" : "")}>
                                            <td className = "py-2 pr-2" onClick = {(e) => this.navigate(e, "/VacationDisplay/" + value.vacation.id, true)}>{index + 1}</td>
                                            <td className = "py-2" onClick = {(e) => this.navigate(e, "/VacationDisplay/" + value.vacation.id, true)}>{value.employee.name}</td>
                                            <td className = "py-2" onClick = {(e) => this.navigate(e, "/VacationDisplay/" + value.vacation.id, true)}>{value.employee.depName}</td>

                                            {/* Vacation Type */}
                                            <>
                                                <td className = "py-2 px-4" onClick = {(e) => this.navigate(e, "/VacationDisplay/" + value.vacation.id, true)}>
                                                    {this._enumTranslate.GetVacationTypeArByVacation(value.vacation)}
                                                </td>
                                            </>

                                            {/* From-To */}
                                            <>
                                                {value.vacation.vacationType != VacationType.Part &&
                                                    <>
                                                        <td className = "py-2" onClick = {(e) => this.navigate(e, "/VacationDisplay/" + value.vacation.id, true)}>
                                                            <div className = "flex flex-col items-center px-4">
                                                                <span>{value.vacation.fromDate != null && (new Date(value.vacation.fromDate).toLocaleDateString('en-GB'))}</span>
                                                                <FontAwesomeIcon icon = {faArrowDown} className = "text-xxs" />
                                                                <span>{value.vacation.toDate != null && (new Date(value.vacation.toDate).toLocaleDateString('en-GB'))}</span>
                                                            </div>
                                                        </td>
                                                    </>
                                                }
                                                {value.vacation.vacationType == VacationType.Part &&
                                                    <>
                                                        <td className = "py-2 flex items-center justify-center" onClick = {(e) => this.navigate(e, "/VacationDisplay/" + value.vacation.id, true)}>
                                                            <div className = "flex flex-col items-center px-4" style = {{width: "max-content"}}>
                                                                <span>{value.vacation.startTime != null && (new Date(value.vacation.startTime).toLocaleTimeString())}</span>
                                                                <FontAwesomeIcon icon = {faArrowDown} className = "text-xxs" />
                                                                <span>{value.vacation.endTime != null && (new Date(value.vacation.endTime).toLocaleTimeString())}</span>
                                                            </div>
                                                        </td>
                                                    </>
                                                }
                                            </>

                                            {!this.state.vacationPanel.pending &&
                                                <td className = "py-2 px-4" onClick = {(e) => this.navigate(e, "/VacationDisplay/" + value.vacation.id, true)}>{(new Date(value.vacation.requestedDate ?? "").toLocaleDateString('en-GB'))} </td>
                                            }

                                            <td className = "py-2" onClick = {(e) => this.navigate(e, "/VacationDisplay/" + value.vacation.id, true)}>{this._enumTranslate.GetVacationStatusAr(value.vacation.vacationStatus)}</td>

                                            <td className = "py-2">
                                                {(this.state.vacationPanel.approved || this.state.vacationPanel.rejected) &&
                                                    <>
                                                        {(this.props.authInfo.userType == UserType.ADMIN || this.props.authInfo.userType == UserType.SUPER_ADMIN) &&
                                                            <button title = "حذف" onClick = {() => this.Delete(value.vacation.id)} className = "btn p-2 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white border border-red-500 hover:border-transparent rounded-sm sm:text-xxs md:text-sm">
                                                                <FontAwesomeIcon icon = {faTrash} />
                                                            </button>
                                                        }
                                                    </>
                                                }
                                                {this.state.vacationPanel.pending &&
                                                    <div className = "flex gap-1 justify-center">
                                                        {(this.props.authInfo.userType == UserType.DEPARTMENT) && (value.vacation.vacationStatus == VacationStatus.PENDING_DEPARTMENT) &&
                                                            <>
                                                                <button title = "رفض" onClick = {(e) => this.Reject(value.vacation.id)} className = "btn p-2 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white border border-red-500 hover:border-transparent rounded-sm sm:text-xxs md:text-sm">
                                                                    <FontAwesomeIcon icon = {faStopCircle} />
                                                                </button>
                                                                <button title = "موافقة" onClick = {() => this.Approve(value.vacation.id)} className = "btn p-2 bg-transparent hover:bg-green-400 text-green-400 font-semibold hover:text-white border border-green-400 hover:border-transparent rounded-sm sm:text-xxs md:text-sm">
                                                                    <FontAwesomeIcon icon = {faCheck} onClick = {() => this.Approve(value.vacation.id)} />
                                                                </button>
                                                            </>
                                                        }
                                                        {(this.props.authInfo.userType == UserType.ADMIN || this.props.authInfo.userType == UserType.SUPER_ADMIN) && (value.vacation.vacationStatus == VacationStatus.PENDING_PRESIDENCY) &&
                                                            <>
                                                                <button title = "رفض" onClick = {(e) => this.Reject(value.vacation.id)} className = "btn p-2 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white border border-red-500 hover:border-transparent rounded-sm sm:text-xxs md:text-sm">
                                                                    <FontAwesomeIcon icon = {faStopCircle} />
                                                                </button>
                                                                <button title = "موافقة" onClick = {() => this.ApprovePaid(value.vacation.id, VacationStatus.APPROVED_PRESIDENCY_PAID)} className = "btn p-2 bg-transparent hover:bg-green-400 text-green-400 font-semibold hover:text-white border border-green-400 hover:border-transparent rounded-sm sm:text-xxs md:text-sm">
                                                                    <FontAwesomeIcon icon = {faCheck} onClick = {() => this.Approve(value.vacation.id)} /><br />براتب
                                                                </button>
                                                                <button title = "موافقة" onClick = {() => this.ApprovePaid(value.vacation.id, VacationStatus.APPROVED_PRESIDENCY_UNPAID)} className = "btn p-2 bg-transparent hover:bg-yellow-600 text-yellow-600 font-semibold hover:text-white border border-yellow-600 hover:border-transparent rounded-sm sm:text-xxs md:text-sm">
                                                                    <FontAwesomeIcon icon = {faCheck} onClick = {() => this.Approve(value.vacation.id)} /><br />بدون-راتب
                                                                </button>
                                                            </>
                                                        }
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    )

                                })
                                }</tbody>

                            </table>
                        }

                        {this.state.displayVacationEmployee.length === 0 && !this.state.loading &&
                            <div className = "bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role = "alert">
                                <p className = "text-sm text-center">{langs.no_data}</p>
                            </div>
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default connect((store: Store) => {
    return {
        authInfo: store.LoginReducer
    }
})(withRouter(Vacations))