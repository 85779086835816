import React, {Component} from 'react';
import SideBar from "./SideBar";

export class Layout extends Component {
    static displayName = Layout.name;

    render() {

        return (
            <div dir={"rtl"} className="flex md:flex-row-reverse flex-wrap">

                <div className="sm:w-full md:w-4/5 lg:w-5/6 bg-gray-100">

                    <div id="bodyContainer" className=" bg-gray-100 pt-16 px-6 sm:px-0">
                        {this.props.children}
                    </div>
                </div>

                <SideBar/>
            </div>



        );
    }
}
