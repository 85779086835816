import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown, faArrowUp, faSyncAlt, faUser} from "@fortawesome/free-solid-svg-icons";
import CloudLoader from "../Shared/Loading/CloudLoader";
import {langs} from "../../Bootstrap/Langs/Lang";
import {withRouter} from "react-router-dom";
import {Attendance, AttendanceType} from "./Data/Tyeps";
import {AxiosResponse} from "axios";
import AttendanceIO from "./IO/AttendanceIO";
import {Department, UserType} from "../Shared/Types";
import {Store} from "../../Bootstrap/Store";
import {connect} from "react-redux";
import {AuthInfo} from "../Login/Data/Types";
import EnumTranslate from "../Shared/enumTranslate";

interface Props {
    history: any;
    location: {
        Search: string;
    };
    authInfo: AuthInfo;
}

interface State {
    loading: boolean;
    Attendance: Attendance[];
    error: boolean;
    Search: string;
    dateTime: Date;
}

class AttendanceMainComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {loading: false, Attendance: [], error: false, Search: "", dateTime: new Date()};
        this._attendanceIO = new AttendanceIO();
        this._enumTranslate = new EnumTranslate();
    }

    private _attendanceIO: AttendanceIO;
    private _enumTranslate:EnumTranslate;

    async componentDidMount() {
        this._attendanceIO.getLastAttendanceDate().then(res => {
            this.loadData(new Date(res.data))
        });
    }

    loadData = async (date: Date) => {
        this.setState({dateTime: date})
        this.setState({error: false, loading: true});
        try {
            const response: AxiosResponse<Attendance[]> = await this._attendanceIO.fetch(date.toLocaleDateString());
            if (this.props.authInfo.userType == UserType.DEPARTMENT) {
                this.setState({Attendance: response.data.filter(x => x.depId == this.props.authInfo.depId)});
            } else {
                this.setState({Attendance: response.data});
            }
            this.setState({loading: false, error: false})
            if (this.props.authInfo.userType !== UserType.DEPARTMENT) {
                if (this.props.location.Search == undefined) {
                    this.search(this.state.Search);
                } else {
                    this.search(this.props.location.Search);
                }
            }
        } catch (e) {
            this.setState({loading: false, error: true});
        }
    }

    /*export = () => {

        let data: string = "المعرف,الاسم,القسم,وقت الدخول,وقت الخروج,الحاله";
        data += '\n';
        this.state.Attendance.filter(item => item.isHidden).map((value) => {
            data += value.id + ',' + value.name.trim() + ',' + value.depName.trim() + ',' + (value.timeIn != null && (new Date(value.timeIn).toLocaleTimeString())) + ',' + (value.timeOut != null && (new Date(value.timeOut).toLocaleTimeString()))
                + "," +
                this.attendanceTypeToString(value.attendanceType)
                + "\n";

        })
        this.exportToCsv("file.csv", data)
    }*/

    exportToCsv(filename, rows) {
        var processRow = function (row) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                var innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                }
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };

        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {

            csvFile += processRow(rows[i]);
        }

        var blob = new Blob([rows], {type: 'text/csv;charset=utf-8;'});
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    search = (searchValue) => {
        this.setState({Search: searchValue});
        // if (this.props.authInfo.depId > 0) {
        //     searchValue = this.props.authInfo.depName;
        // } else {
        //     this.setState({Search: searchValue});
        // }


        let arr: Attendance[] = [];
        this.state.Attendance.forEach((client, index, array) => {
            array[index].isHidden = !(client.name.includes(searchValue) || client.depName.includes(searchValue) || client.id.includes(searchValue));
            arr = array;
        })
        this.setState({Attendance: arr});
    };

    csvTextToJSON = (csv: string) => {
        let lines = csv.split('\n');
        const result: JSON[] = [];
        let spilter = ",";
        if (lines[0].includes(";")) {
            spilter = ";";
        }

        const headers = lines[0].replaceAll(' ', '').split(spilter);

        for (let i = 1; i < lines.length; i++) {
            if (!lines[i])
                continue;
            const obj = {};
            const currentLine = lines[i].split(spilter)
            let dateTime: string = "";
            let userId: string = "";
            for (let j = 0; j < headers.length; j++) {
                if (headers[j] == "UserID") {
                    userId = String(Number(currentLine[j]));
                } else if (headers[j] === "Date") {
                    dateTime = currentLine[j] + " ";
                } else if (headers[j] === "Time") {
                    dateTime = dateTime + currentLine[j];
                } else if (headers[j] === "Name") {
                    obj[headers[j]] = currentLine[j]
                }
            }
            obj["userId"] = userId;
            obj["ReceivedDateTime"] = dateTime;

            result.push(obj as JSON)
        }
        console.log(result);
        return result
    }

    csvToTextAsync = (csvFile: File) => {
        return new Promise((res) => {
            let reader = new FileReader();
            reader.onload = function () {
                const s: string = reader.result + ""
                res(s)
            };
            reader.readAsText(csvFile);
        });
    }

    submitCsv = async (e) => {
        this.setState({loading: true});
        try {
            const csvAsText = await this.csvToTextAsync(e.target.files[0]);
            const result = this.csvTextToJSON(csvAsText + "");
            const res = await this._attendanceIO.uploadAttCsv(result);
            if (res.status == 200) {
                (document.getElementById("upload") as HTMLInputElement).value = "";
                const lastDate = await this._attendanceIO.getLastAttendanceDate();
                await this.loadData(new Date(lastDate.data));
            }
            this.setState({loading: false});
        } catch {
            this.setState({error: true, loading: false});
        }
    }

    reverse = (str: string) => {
        let rev = "";
        for (let i = str.length - 1; i >= 0; i--) {
            rev += str[i];
        }
        return rev;
    }

    reverseDate = (date: string) => {
        const dateSplit = date.replaceAll('/', '-').split('-');
        return (dateSplit[2] + '-'
            + (dateSplit[0].length > 1 ? dateSplit[0] : "0" + dateSplit[0]) + '-'
            + (dateSplit[1].length > 1 ? dateSplit[1] : "0" + dateSplit[1]));
    }

    render() {
        return (
            <div className = 'sm:m-0 sm:p-2 md:mr-8 md:ml-8 md:mt-12  md:p-4 rounded-sm overflow-hidden m-auto bg-white shadow-xl'>
                {/*Controllers*/}
                <div className = "flex sm:flex-col sm:gap-4 md:flex-col md:gap-4 lg:flex-row lg:gap-2 pb-8">
                    {/*Buttons*/}
                    <div className = "flex gap-2">
                        {(this.props.authInfo.userType == "SUPER_ADMIN") &&
                            <>
                                {/*<button id = "btnExport" type = "button" onClick = {this.export} title = {"تحميل تقرير"} className = "flex-1 shadow bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded-sm text-sm">*/}
                                {/*    <FontAwesomeIcon icon = {faArrowDown} className = "mr-0  sm:text-sm" />*/}
                                {/*</button>*/}

                                <button onClick = {() => document.getElementById("upload")?.click()} title = {"رفع تقرير"} className = "flex-1 shadow bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded-sm text-sm">
                                    <FontAwesomeIcon icon = {faArrowUp} className = "mr-0  sm:text-sm" />
                                    <input hidden = {true} id = {"upload"} name = {"upload"} type = "file" accept = {".csv"} onChange = {(e) => this.submitCsv(e)} />
                                </button>

                                {/*<button onClick = {() => this.props.history.push('/createEmployee')} id = "refresh" title = {"ادارة الموظفين"} className = "flex-1 shadow bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded-sm text-sm">*/}
                                {/*    <FontAwesomeIcon icon = {faUser} className = "mr-0  sm:text-sm" />*/}
                                {/*</button>*/}
                            </>
                        }

                        <button onClick = {() => this.loadData(this.state.dateTime)} id = "refresh" aria-label = "Refresh" title = {"اعادة تحميل"} className = "flex-1 shadow bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded-sm text-sm">
                            <FontAwesomeIcon icon = {faSyncAlt} className = "mr-0  sm:text-sm" />
                        </button>
                    </div>

                    {/*Inputs*/}
                    <div className = "flex flex-1">
                        <input
                            className = "flex-1 shadow appearance-none bg-transparent border-none text-gray-700 py-1 px-2 focus:outline-none"
                            type = "text" autoFocus={true} placeholder = {langs.Search} aria-label = "Full name"
                            onChange = {(e) => e.target.value != undefined && this.search(e.target.value)}
                            value = {this.state.Search} />
                        <input
                            className = "text-center text-sm shadow appearance-none bg-transparent border-none font-bold text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                            type = "Date"
                            value = {this.reverseDate(this.state.dateTime.toLocaleDateString())}
                            onChange = {async (e) => {
                                this.setState({dateTime: new Date(e.target.value)}, () => {
                                    this.loadData(this.state.dateTime);
                                });
                            }}
                        />
                    </div>
                </div>

                {/*Attendance*/}
                <div className = "sm:text-xxs md:text-xs lg:text-sm">
                    {/*Loading*/}
                    {this.state.loading &&
                        <CloudLoader title = {langs.loading_data} className = "w-1/6 mx-auto text-center" />
                    }

                    {/*Attendance Table*/}
                    {
                        !this.state.loading &&
                        <table id = "table" className = "table-fixed text-right w-full">
                            <thead>
                            <tr>
                                <th className = "pb-4">{langs.id}</th>
                                <th className = "pb-4">{langs.name}</th>
                                <th className = "pb-4">{langs.department}</th>
                                <th className = "pb-4">{'الحاله'}</th>
                                <th className = "pb-4">{langs.TimeIn}</th>
                                <th className = "pb-4">{langs.TimeOut}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.Attendance.length > 0 && this.state.Attendance.filter(item => !item.isHidden).map((value, index) => {
                                const color: string = this._enumTranslate.GetAttendanceTypeColor(value.attendanceType);
                                return (
                                    <tr key = {index} onClick = {() => this.props.history.push('/Employee/' + value.id)}
                                        className = {"cursor-pointer hover:bg-blue-200 hover:font-bold " + (index % 2 === 0 ? "bg-gray-100" : "")}>
                                        <td className = "py-2 pr-2">{value.id}</td>
                                        <td className = "py-2">{value.name}</td>
                                        <td className = "py-2">{value.depName}</td>
                                        <td className = "py-2" style = {{color: color}}>{this._enumTranslate.GetAttendanceTypeAr(value.attendanceType)}</td>
                                        <td className = "py-2">{value.timeIn != null && (new Date(value.timeIn).toLocaleTimeString())}</td>
                                        <td className = "py-2">{value.timeOut != null && (new Date(value.timeOut).toLocaleTimeString())}</td>

                                    </tr>
                                )
                            })
                            }
                            </tbody>
                        </table>
                    }

                    {/*No Data*/}
                    {(this.state.Attendance.length === 0 || this.state.Attendance.filter(att => !att.isHidden).length === 0) && !this.state.loading &&
                        <div className = "bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role = "alert">
                            <p className = "text-sm text-center">{langs.no_data}</p>
                        </div>
                    }
                </div>
            </div>
        );
    }

}


export default connect((store: Store) => {
    return {
        authInfo: store.LoginReducer
    }
})(withRouter(AttendanceMainComponent))