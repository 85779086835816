import React, {Component} from 'react';
import {Attendance, DepartmentUpdate} from "../Data/Tyeps";
import {withRouter} from "react-router";
import AttendanceIO from "../IO/AttendanceIO";
import {AxiosResponse} from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import CloudLoader from "../../Shared/Loading/CloudLoader";
import {langs} from "../../../Bootstrap/Langs/Lang";
import {AuthInfo} from "../../Login/Data/Types";
import {UserType} from "../../Shared/Types";
import {Store} from "../../../Bootstrap/Store";
import {connect} from "react-redux";

interface Props {
    history: any;
    authInfo: AuthInfo;
}

interface State {
    loading: boolean;
    departments: DepartmentUpdate[];
    error: boolean;
    Search: string;
    dateTime: Date;
}

class DepartmentsComponents extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {loading: false, departments: [], error: false, Search: "", dateTime: new Date()};
        this._attendanceIO = new AttendanceIO();
        if (this.props.authInfo.userType == UserType.DEPARTMENT) {
            this.props.history.push("/Attendance");
        }
    }

    async componentDidMount() {
        this._attendanceIO.getLastAttendanceDate().then(res => {
            this.setState({dateTime: new Date(res.data)})
            this.loadData(this.state.dateTime)
        });
    }

    loadData = async (date: Date) => {
        this.setState({error: false, loading: true});
        try {
            const response: AxiosResponse<DepartmentUpdate[]> = await this._attendanceIO.fetchDepartments(date.toLocaleDateString());
            this.setState({error: false, loading: false, departments: response.data});
        } catch (e) {
            this.setState({loading: false, error: true});
            return;
        }
    };

    reverseDate = (date: string) => {
        const dateSplit = date.replaceAll('/', '-').split('-');
        return (dateSplit[2] + '-'
            + (dateSplit[0].length > 1 ? dateSplit[0] : "0" + dateSplit[0]) + '-'
            + (dateSplit[1].length > 1 ? dateSplit[1] : "0" + dateSplit[1]));
    }

    private _attendanceIO: AttendanceIO;

    render() {
        return (
            <>
                <div className = 'sm:m-0 sm:p-2 md:mr-8 md:ml-8 md:mt-12  md:p-4 rounded-sm overflow-hidden m-auto bg-white shadow-xl'>
                    <div className = "flex justify-between">
                        <button onClick = {() => this.loadData(this.state.dateTime)} id = "refresh" aria-label = "Refresh" className = "bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded-sm text-sm">
                            <FontAwesomeIcon icon = {faSyncAlt} className = "mr-0  sm:text-sm" />
                        </button>

                        <input
                            className = "text-center py-2 text-sm shadow appearance-none bg-transparent border-none font-bold text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                            type = "Date"
                            value = {this.reverseDate(this.state.dateTime.toLocaleDateString())}
                            onChange = {async (e) => {
                                this.setState({dateTime: new Date(e.target.value)}, () => {
                                    this.loadData(this.state.dateTime);
                                });
                            }}
                        />
                    </div>

                    <br /><br />

                    <div className = "sm:text-xxs md:text-xs lg:text-sm">
                        {this.state.loading &&
                            <CloudLoader title = {langs.loading_data} className = "w-1/6 mx-auto text-center" />
                        }

                        {!this.state.loading &&
                            <table className = "table-fixed text-right w-full">
                                <thead>
                                <tr>
                                    <th className = "w-1/12 px-4 py-2">{langs.index}</th>
                                    <th className = "w-3/12 px-4 py-2">{langs.department}</th>
                                    <th className = "w-1/6 px-4 py-2 text-center">{"عدد الحضور"}</th>
                                    <th className = "w-1/6 px-4 py-2 text-center">{"عدد التاخير"}</th>
                                    <th className = "w-1/6 px-4 py-2 text-center">{"عدد المسائي"}</th>
                                    <th className = "w-1/6 px-4 py-2 text-center">{"عدد الاجازات"}</th>

                                    <th className = "w-1/6 px-4 py-2 text-center">{"عدد الغياب"}</th>
                                    <th className = "w-1/6 px-4 py-2 text-center">{"نسبه الحضور"}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.departments.length > 0 && this.state.departments.map((value, index) => {
                                    return (
                                        <tr key = {index} onClick = {() => {
                                            this.props.history.push({pathname: '/Attendance', Search: value.name})
                                        }}
                                            className = {"cursor-pointer hover:bg-blue-200 hover:font-bold " + (index % 2 === 0 ? "bg-gray-100" : "")}>
                                            <td className = "px-4 py-2">{value.depId}</td>
                                            <td className = "px-4 py-2">{value.name}</td>
                                            <td className = "px-4 py-2 text-center">{value.attendeesCount}</td>
                                            <td className = "px-4 py-2 text-center">{value.lateCount}</td>
                                            <td className = "px-4 py-2 text-center">{value.nightCount}</td>

                                            <td className = "px-4 py-2 text-center">{value.vacationsCount}</td>
                                            <td className = "px-4 py-2 text-center">{value.absencesCount}</td>
                                            <td className = "px-4 py-2 text-center">{value.ratio} %</td>

                                        </tr>
                                    )

                                })
                                }
                                </tbody>
                            </table>
                        }

                        {this.state.departments.length === 0 && !this.state.loading &&
                            <div className = "bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role = "alert">
                                <p className = "text-sm text-center">{langs.no_data}</p>
                            </div>
                        }
                    </div>
                </div>
                <br />
                <br />
                <br />
            </>
        );
    }


}

export default connect((store: Store) => {
    return {
        authInfo: store.LoginReducer
    }
})(withRouter(DepartmentsComponents))