export enum UserType {
    SUPER_ADMIN = "SUPER_ADMIN",
    ADMIN = "ADMIN",
    DEPARTMENT = "DEPARTMENT",
    USER = "USER",
}

export enum Days{
    D1,
    D2,
    D3,
    D4,
    D5,
    D6,
    D7,

    E1,
    E2,
    E3,
    E4,
    E5,
    E6,
    E7,

    N1,
    N2,
    N3,
    N4,
    N5,
    N6,
    N7
}

export class Department {
    Name = (id: number) => {
        switch (id) {
            case 1:
                return "القانون";
            case 2:
                return "التكييف والتبريد";
            case 3:
                return "طب الأسنان";
            case 4:
                return "الحاسبات";
            case 5:
                return "البناء والانشاءات";
            case 6:
                return "الكيمياوي";
            case 7:
                return "ادارة الأعمال";
            case 8:
                return "تقنيات المختبرات الطبية";
            case 9:
                return "المحاسبة";
            case 10:
                return "التربية البدنية وعلوم الرياضة";
            case 11:
                return "الفيزياء الطبية";
            case 12:
                return "الصيدلة";
            case 13:
                return "الطب الحياتي";
            case 14:
                return "تقنيات التخدير";
            case 15:
                return "تقنيات الاشعة";
            case 16:
                return "الآثار";
            case 17:
                return "اللغة الانجليزية وآدابها";
            case 18:
                return "الاجهزة الطبية";
            case 19:
                return "التمريض";
            case 20:
                return "الاعلام";
            case 21:
                return "تقنيات البصريات";
            case 23:
                return "الحاضنة التكنولوجية";
            case 24:
                return "العمادة";
            case 56:
                return "تقنيات الوقود والطاقة";

        }
        return "";
    }
}
