import {AttendanceType} from "../../Attandancees/Data/Tyeps";

export interface Config {
    id: number,
    dayInShift: string,
    dayInShiftLate: string,
    dayOutShift: string;
    eveningInShift: string,
    eveningInShiftLate: string,
    eveningOutShift: string;
    nightInShift: string,
    nightInShiftLate: string,
    nightOutShift: string;

    dayShiftBeforeInTweak: string;
    dayShiftAfterInTweak: string;
    dayShiftBeforeOutTweak: string;
    dayShiftAfterOutTweak: string;
    eveningShiftBeforeInTweak: string;
    eveningShiftAfterInTweak: string;
    eveningShiftBeforeOutTweak: string;
    eveningShiftAfterOutTweak: string;
    nightShiftBeforeInTweak: string;
    nightShiftAfterInTweak: string;
    nightShiftBeforeOutTweak: string;
    nightShiftAfterOutTweak: string;

    startMonth: Date;
    endMonth: Date;

    disableVacation: boolean
    disableFree: boolean
    allowedVacationCountForDepartment: number;
    allowedContinuesVacationForDepartment: number;
}

export interface AdminPanel {
    config: boolean;
    attendance: boolean;
    users: boolean;
}

export enum AdminPanelType {
    CONFIG,
    ATTENDANCE,
    USERS
}

export interface Dates{
    from:Date;
    to:Date;
}

export interface UpdateAttendanceStatus{
    from:Date;
    to:Date;
    attendanceType:AttendanceType;
    present:boolean;
    absence:boolean;
    vacation:boolean;
    late:boolean;
    night:boolean;
    free:boolean;
    assign:boolean;
    dispatch:boolean;
    softAbsence:boolean;
    presidency_Vacation_Paid:boolean;
    presidency_Vacation_UnPaid:boolean;
    withShift:boolean;
    shift:Shift;
}

export enum Shift {
    DayShift,
    EveningShift,
    NighShift,
    Free
}