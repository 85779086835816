import React, {Component} from 'react';
import AttendanceIO from "../IO/AttendanceIO";
import CloudLoader from "../../Shared/Loading/CloudLoader";
import {langs} from "../../../Bootstrap/Langs/Lang";
import {
    AcademicCertificate,
    Attendance,
    AttendanceType,
    Department,
    EmployeeAttendancePanelType, EmployeesPanel, EmployeesPanelType,
    EmployeeType,
    Position
} from "../Data/Tyeps";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Days, UserType} from "../../Shared/Types";
import {AuthInfo} from "../../Login/Data/Types";
import {Store} from "../../../Bootstrap/Store";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import Initializer from "../../Shared/initializer";

interface Props {
    authInfo: AuthInfo;
}

interface State {
    loading: boolean;
    search: string;
    departments: Department[];
    employees: Attendance[];
    employee: Attendance;
    employeesPanel: EmployeesPanel;
    create:boolean;
    edit:boolean;
    cancel:boolean;
    cardId:boolean;
}

class CreateEmployee extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const initializer = new Initializer();
        this.state = {
            cancel: true, create: false, edit: true, cardId:false,
            employeesPanel: {create: true, employees: false},
            loading: true,
            search: "",
            departments: [],
            employees: [],
            employee: initializer.employee()
        };
        this.attendanceIo = new AttendanceIO();
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        const departments = await this.attendanceIo.fetchDepartment();
        const employees = await this.attendanceIo.fetchEmployees();
        if (departments.status == 200 && employees.status == 200) {
            if (this.props.authInfo.userType == UserType.DEPARTMENT) {
                departments.data = departments.data.filter(d => d.id == this.props.authInfo.depId);
                employees.data = employees.data.filter(e => e.depId == this.props.authInfo.depId);
            }
            this.setState({loading: false, departments: departments.data, employees: employees.data});
        }
    }

    inputsIsValid = () => {
        const {id, name, depId, depName, email} = this.state.employee;
        return (
            name != '' &&
            depId > 0 &&
            depName != '' &&
            email != ''
        );
    }

    hideResMessages = () => {
        document.getElementById("error")?.toggleAttribute("hidden", true);
        document.getElementById("invalidInputs")?.toggleAttribute("hidden", true);
        document.getElementById("employeeCreated")?.toggleAttribute("hidden", true);
    }

    submitCreate = async () => {
        this.hideResMessages();

        if (!this.inputsIsValid()) {
            window.scrollTo({top: 0, behavior: "smooth"});
            document.getElementById("invalidInputs")?.toggleAttribute("hidden", false);
            return false;
        } else {
            document.getElementById("invalidInputs")?.toggleAttribute("hidden", true);
        }

        this.setState({loading: true});
        try {
            const res = await this.attendanceIo.createEmployee(this.state.employee);
            this.state.employees.push({...res.data});
            this.setState({
                employees: this.state.employees
            });
            window.scrollTo({top: 0, behavior: "smooth"});
            document.getElementById("employeeCreated")?.toggleAttribute("hidden", false);
            this.setState({loading: false});
            (document.getElementById("empName") as HTMLParagraphElement).innerText = res.data.name;
            (document.getElementById("empId") as HTMLParagraphElement).innerText = res.data.id;
            document.getElementById("empCreated")?.toggleAttribute("hidden", false);
        } catch {
            this.setState({loading: false});
            document.getElementById("error")?.toggleAttribute("hidden", false);
        }
    }

    submitEdit = async () => {
        this.hideResMessages();

        if (!this.inputsIsValid()) {
            window.scrollTo({top: 0, behavior: "smooth"});
            document.getElementById("invalidInputs")?.toggleAttribute("hidden", false);
            return false;
        } else {
            document.getElementById("invalidInputs")?.toggleAttribute("hidden", true);
        }

        this.setState({loading: true});

        try {
            const res = await this.attendanceIo.editEmployee(this.state.employee)
            this.state.employees.map(emp => {
                if (emp.id == this.state.employee.oldId) {
                    this.state.employees[this.state.employees.indexOf(emp)] = {...this.state.employee};
                }
            });
            this.setState({
                employees: this.state.employees
            });
            this.setState({loading: false});
            document.getElementById("employeeUpdated")?.toggleAttribute("hidden", false);
            this.cancel();
            this.setState({create:false, edit:true,cancel:true,cardId:false});
        } catch {
            this.setState({loading: false});
            document.getElementById("error")?.toggleAttribute("hidden", false);
        }
        window.scrollTo({top: 0, behavior: "smooth"});
    }

    submitDelete = async (id: string) => {
        const empName = this.state.employees.find(emp => emp.id == id);
        let pop = window.confirm("سيتم حذف الموضف " + "( " + empName?.name + " )" + " بشكل نهائي!");
        if (pop) {
            this.setState({loading: true})
            const res = await this.attendanceIo.deleteEmployee(id);
            if (res.data == 200) {
                this.setState({
                    loading: false,
                    employees: this.state.employees.filter(emp => emp.id != id)
                });
            }
        }
    }

    editInfo = (id: string) => {
        const selectedEmployee = this.state.employees.find(emp => emp.id == id);

        if (selectedEmployee) {
            selectedEmployee.oldId = id;
            if (selectedEmployee.email == null) {
                selectedEmployee.email = "";
            }

            this.setState({employee: {...selectedEmployee}, employeesPanel: {create:true, employees:false}});

            document.getElementById("cardNumber")?.toggleAttribute("disabled", true);
            //document.getElementById("createBtn")?.toggleAttribute("hidden", true);
            const departmentsSelect: HTMLSelectElement = document.getElementById('departments') as HTMLSelectElement;

            console.log("edit")

            if (departmentsSelect) {
                departmentsSelect.childNodes.forEach(child => {
                    const option = child as HTMLOptionElement;
                    if (option.value == selectedEmployee.depId.toString()) {
                        option.selected = true;
                    }
                })

                window.scroll(window.scrollX, 0);
            }

            this.setState({create:true, edit:false,cancel:false,cardId:true});
            //document.getElementById("editBtn")?.toggleAttribute("hidden", false);
            //document.getElementById("cancelBtn")?.toggleAttribute("hidden", false);
        }

        //window.scrollTo({top: 0, behavior: "smooth"});
    }

    cancel = () => {
        this.state.employee.id = "";
        this.state.employee.name = "";
        this.state.employee.depId = 0;
        this.state.employee.depName = "";
        this.state.employee.email = "";
        this.state.employee.d1 = true;
        this.state.employee.d2 = true;
        this.state.employee.d3 = true;
        this.state.employee.d4 = true;
        this.state.employee.d5 = true;
        this.state.employee.d6 = true;
        this.state.employee.d7 = false;
        this.state.employee.e1 = false;
        this.state.employee.e2 = false;
        this.state.employee.e3 = false;
        this.state.employee.e4 = false;
        this.state.employee.e5 = false;
        this.state.employee.e6 = false;
        this.state.employee.e7 = false;
        this.state.employee.n1 = false;
        this.state.employee.n2 = false;
        this.state.employee.n3 = false;
        this.state.employee.n4 = false;
        this.state.employee.n5 = false;
        this.state.employee.n6 = false;
        this.state.employee.n7 = false;
        this.state.employee.employeeType = EmployeeType.PART;
        this.state.employee.academicCertificate = AcademicCertificate.BACHELORS;
        this.state.employee.position = Position.ADMINISTRATIVE;

        this.setState({employee: this.state.employee});

        document.getElementById("editBtn")?.toggleAttribute("hidden", true);
        document.getElementById("cancelBtn")?.toggleAttribute("hidden", true);
        document.getElementById("createBtn")?.toggleAttribute("hidden", false);
        document.getElementById('cardNumber')?.toggleAttribute("disabled", false);
    }

    changeDayShift = (day: Days) => {
        //console.log(day)
        switch (day) {
            case Days.D1:
                this.state.employee.d1 = !this.state.employee.d1;
                this.state.employee.e1 = false;
                this.state.employee.n1 = false;
                break;
            case Days.D2:
                this.state.employee.d2 = !this.state.employee.d2;
                this.state.employee.e2 = false;
                this.state.employee.n2 = false;
                break;
            case Days.D3:
                this.state.employee.d3 = !this.state.employee.d3;
                this.state.employee.e3 = false;
                this.state.employee.n3 = false;
                break;
            case Days.D4:
                this.state.employee.d4 = !this.state.employee.d4;
                this.state.employee.e4 = false;
                this.state.employee.n4 = false;
                break;
            case Days.D5:
                this.state.employee.d5 = !this.state.employee.d5;
                this.state.employee.e5 = false;
                this.state.employee.n5 = false;
                break;
            case Days.D6:
                this.state.employee.d6 = !this.state.employee.d6;
                this.state.employee.e6 = false;
                this.state.employee.n6 = false;
                break;
            case Days.D7:
                this.state.employee.d7 = !this.state.employee.d7;
                this.state.employee.e7 = false;
                this.state.employee.n7 = false;
                break;
            case Days.E1:
                this.state.employee.d1 = false;
                this.state.employee.e1 = !this.state.employee.e1;
                this.state.employee.n1 = false;
                break;
            case Days.E2:
                this.state.employee.d2 = false;
                this.state.employee.e2 = !this.state.employee.e2;
                this.state.employee.n2 = false;
                break;
            case Days.E3:
                this.state.employee.d3 = false;
                this.state.employee.e3 = !this.state.employee.e3;
                this.state.employee.n3 = false;
                break;
            case Days.E4:
                this.state.employee.d4 = false;
                this.state.employee.e4 = !this.state.employee.e4;
                this.state.employee.n4 = false;
                break;
            case Days.E5:
                this.state.employee.d5 = false;
                this.state.employee.e5 = !this.state.employee.e5;
                this.state.employee.n5 = false;
                break;
            case Days.E6:
                this.state.employee.d6 = false;
                this.state.employee.e6 = !this.state.employee.e6;
                this.state.employee.n6 = false;
                break;
            case Days.E7:
                this.state.employee.d7 = false;
                this.state.employee.e7 = !this.state.employee.e7;
                this.state.employee.n7 = false;
                break;
            case Days.N1:
                this.state.employee.d1 = false;
                this.state.employee.e1 = false;
                this.state.employee.n1 = !this.state.employee.n1;
                break;
            case Days.N2:
                this.state.employee.d2 = false;
                this.state.employee.e2 = false;
                this.state.employee.n2 = !this.state.employee.n2;
                break;
            case Days.N3:
                this.state.employee.d3 = false;
                this.state.employee.e3 = false;
                this.state.employee.n3 = !this.state.employee.n3;
                break;
            case Days.N4:
                this.state.employee.d4 = false;
                this.state.employee.e4 = false;
                this.state.employee.n4 = !this.state.employee.n4;
                break;
            case Days.N5:
                this.state.employee.d5 = false;
                this.state.employee.e5 = false;
                this.state.employee.n5 = !this.state.employee.n5;
                break;
            case Days.N6:
                this.state.employee.d6 = false;
                this.state.employee.e6 = false;
                this.state.employee.n6 = !this.state.employee.n6;
                break;
            case Days.N7:
                this.state.employee.d7 = false;
                this.state.employee.e7 = false;
                this.state.employee.n7 = !this.state.employee.n7;
                break;
        }
        this.setState({employee: this.state.employee});
    }

    navigate = (e, href: string, newTab: boolean) => {
        const a = document.createElement('a');
        a.href = href;
        if (newTab) {
            a.setAttribute('target', '_blank');
        }
        a.click();
    }

    search = (searchValue) => {
        this.setState({search: searchValue});
        let arr: Attendance[] = [];
        this.state.employees.forEach((client, index, array) => {
            array[index].isHidden = !(client.name.includes(searchValue) || client.depName.includes(searchValue) || client.id.includes(searchValue) || client.email.includes(searchValue));
            arr = array;
        })
        this.setState({employees: arr});
    };

    changePanel = (employeesPanelType: EmployeesPanelType) => {
        switch (employeesPanelType){
            case EmployeesPanelType.CREATE:
                this.setState({employeesPanel:{create:true,employees:false}});
                break;
            case EmployeesPanelType.EMPLOYEES:
                this.setState({employeesPanel:{create:false,employees:true}});
                break;
        }
    }

    day = {
        display: "flex",
        gap: "5px",
        padding: "5px 20px",
        borderRadius: "5px",
        boxShadow: "0 0 5px -2px",
        cursor: "pointer",
    };

    private attendanceIo: AttendanceIO;

    render() {
        return <div>

            {this.state.loading &&
                <CloudLoader title = {langs.loading_data} className = "w-1/6 mx-auto text-center" />
            }

            {!this.state.loading &&
                <div className = "grid gap-2">

                    {/*Panels*/}
                    {this.props.authInfo.userType != UserType.DEPARTMENT &&
                        <div style = {{width: "95%"}} className = "flex border-b border-blue-600 mb-5 mx-auto sm:text-xxs md:text-sm">
                            <p className = {"p-3 cursor-pointer hover:text-blue-600" + (this.state.employeesPanel.create ? " border-b-4 border-blue-600" : "")} onClick = {() => this.changePanel(EmployeesPanelType.CREATE)}>اضافة موظف</p>
                            <p className = {"p-3 cursor-pointer hover:text-blue-600" + (this.state.employeesPanel.employees ? " border-b-4 border-blue-600" : "")} onClick = {() => this.changePanel(EmployeesPanelType.EMPLOYEES)}>الموظفين</p>
                        </div>
                    }

                    {/*Create Info*/}
                    {this.state.employeesPanel.create && this.props.authInfo.userType !== UserType.DEPARTMENT &&
                        <>
                            {/*Created Message*/}
                            <div id = "empCreated" hidden = {true} className = "fixed w-full h-full top-0 right-0" style = {{backgroundColor: "rgba(0,0,0,.5)"}}>
                                <div className = "flex flex-col gap-4 text-lg bg-green-500 rounded shadow mx-auto mt-4 py-8 px-2 sm:w-10/12 md:w-6/12 items-center justify-center text-white">
                                    <p id = "empName" style = {{textShadow: "0 0 5px black"}}>ضياء منير كاظم حمدي</p>
                                    <p id = "empId" style = {{textShadow: "0 0 5px black"}}>2320140</p>
                                    <button className = "border py-2 px-4 rounded shadow hover:bg-green-700"
                                            onClick = {() => {
                                                document.getElementById('empCreated')?.toggleAttribute("hidden", true);
                                                this.cancel();
                                            }}
                                    >موافق
                                    </button>
                                </div>
                            </div>

                            {/*Create Info*/}
                            <div style = {{width: "95%"}} className = "grid gap-2 bg-white rounded shadow p-4 m-auto text-sm">
                                <div className = "grid gap-4">
                                    {/*Employee Info*/}
                                    <div className = "grid grid-cols-1 row-gap-4 bg-gray-100 rounded border p-2">
                                        <div className = "grid grid-cols-3 gap-2">
                                            {/*Employee Name*/}
                                            <div className = "grid gap-2">
                                                <label htmlFor = "fullName">اسم الموظف</label>
                                                <input type = "text" autoFocus={true} id = "fullName"
                                                       className = "border border-gray-300 rounded shadow p-2"
                                                       placeholder = "الاسم الكامل..." value = {this.state.employee.name}
                                                       required onChange = {(e) => {
                                                    this.state.employee.name = e.target.value;
                                                    this.setState({employee: this.state.employee});
                                                }} />
                                            </div>
                                            {/* Card ID */}
                                            <div className = "grid gap-2">
                                                <label htmlFor = "cardNumber">الهوية</label>
                                                <input type = "text" id = "cardNumber"
                                                       disabled={false}
                                                       className = "border border-gray-300 rounded shadow p-2"
                                                       placeholder = "رقم الهوية..." value = {this.state.employee.id}
                                                       required onChange = {(e) => {
                                                    this.state.employee.id = e.target.value;
                                                    this.setState({employee: this.state.employee});
                                                }} />
                                            </div>
                                            {/*Employee Phone Number*/}
                                            <div className = "grid gap-2">
                                                <label htmlFor = "phone">رقم الهاتف</label>
                                                <input type = "text" maxLength = {11} id = "phone"
                                                       className = "border border-gray-300 rounded shadow p-2"
                                                       placeholder = "+946" value = {this.state.employee.email} required
                                                       onChange = {(e) => {
                                                           this.state.employee.email = e.target.value;
                                                           this.setState({employee: this.state.employee});
                                                       }} />
                                            </div>
                                        </div>
                                        <div className = "grid grid-cols-2 gap-2">
                                            {/* Department */}
                                            <div className = "grid gap-2">
                                                <label htmlFor = "departments">اختر القسم</label>
                                                <select id = "departments"
                                                        className = "border border-gray-300 bg-white rounded shadow p-2"
                                                        value = {this.state.employee.depId} required onChange = {(e) => {
                                                    this.state.employee.depId = parseInt(e.target.selectedOptions[0].value);
                                                    this.state.employee.depName = e.target.selectedOptions[0].innerText;
                                                    this.setState({employee: this.state.employee});
                                                }}>
                                                    <option key = {0} value = {0} disabled>-القسم-</option>
                                                    {this.state.departments.map((dep, index) =>
                                                        <option key = {index} value = {dep.id}>{dep.title}</option>
                                                    )}
                                                </select>
                                            </div>
                                            {/* Employee Type */}
                                            <div className = "grid gap-2">
                                                <label htmlFor = "userType">نوع العقد</label>
                                                <select id = "userType"
                                                        className = "border border-gray-300 bg-white rounded shadow p-2"
                                                        value = {this.state.employee.employeeType} required onChange = {(e) => {
                                                    this.state.employee.employeeType = parseInt(e.target.selectedOptions[0].value);
                                                    this.setState({employee: this.state.employee});
                                                }}>
                                                    <option value = {EmployeeType.FULL}>دائمي</option>
                                                    <option value = {EmployeeType.PART}>جزئي</option>
                                                    {/*<option value={EmployeeType.DAILY}>اجر يومي</option>*/}
                                                </select>
                                            </div>
                                        </div>
                                        <div className = "grid grid-cols-2 gap-2">
                                            {/* Certificate */}
                                            <div className = "grid gap-2">
                                                <label htmlFor = "departments">اختر الشهاده</label>
                                                <select id = "certificate"
                                                        className = "border border-gray-300 bg-white rounded shadow p-2"
                                                        value = {this.state.employee.academicCertificate} required onChange = {(e) => {
                                                    this.state.employee.academicCertificate = parseInt(e.target.selectedOptions[0].value);
                                                    this.setState({employee: this.state.employee});
                                                }}>
                                                    <option key = {0} value = {AcademicCertificate.DEPLOM}>دبلوم</option>
                                                    <option key = {1} value = {AcademicCertificate.BACHELORS}>بكالوريوس</option>
                                                    <option key = {2} value = {AcademicCertificate.MASTER}>ماجستير</option>
                                                    <option key = {3} value = {AcademicCertificate.PHD}>دكتوراه</option>
                                                </select>
                                            </div>
                                            {/* Position */}
                                            <div className = "grid gap-2">
                                                <label htmlFor = "userType">المنصب الوظيفي</label>
                                                <select id = "userType"
                                                        className = "border border-gray-300 bg-white rounded shadow p-2"
                                                        value = {this.state.employee.position} required onChange = {(e) => {
                                                    this.state.employee.position = parseInt(e.target.selectedOptions[0].value);
                                                    this.setState({employee: this.state.employee});
                                                }}>
                                                    <option key = {0} value = {Position.ADMINISTRATIVE}>اداري</option>
                                                    <option key = {1} value = {Position.PROGRAMMER}>مبرمج</option>
                                                    <option key = {2} value = {Position.ASSISTANT}>معيد</option>
                                                    <option key = {3} value = {Position.TEACHER}>تدريسي</option>
                                                    <option key = {4} value = {Position.RAPPORTEUR}>مقرر قسم</option>
                                                    <option key = {5} value = {Position.HEAD_OF_DEPARTMENT}>رئيس قسم</option>
                                                    <option key = {6} value = {Position.TEACHER_RAPPORTEUR}>تدريسي-مقرر قسم</option>
                                                    <option key = {7} value = {Position.TEACHER_HEAD_OF_DEPARTMENT}>تدريسي-رئيس قسم</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    {/*Shifts*/}
                                    <div className = "grid grid-cols-1 row-gap-4 bg-gray-100 rounded border p-2">
                                        <p>ايام الدوام</p>
                                        <hr />
                                        <div className = "grid grid-cols-1 row-gap-4 p-1">
                                            <p>الشفت الصباحي</p>
                                            <div className = "grid sm:grid-cols-4 md:grid-cols-3 lg:grid-cols-7 gap-1">
                                                <label htmlFor = {"d1"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"d1"} value = {"true"}
                                                           checked = {this.state.employee.d1}
                                                           onChange = {() => this.changeDayShift(Days.D1)} /> السبت
                                                </label>
                                                <label htmlFor = {"d2"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"d2"} value = {"true"}
                                                           checked = {this.state.employee.d2} onChange = {() => this.changeDayShift(Days.D2)} /> الاحد
                                                </label>
                                                <label htmlFor = {"d3"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"d3"} value = {"true"} checked = {this.state.employee.d3} onChange = {() => this.changeDayShift(Days.D3)} /> الاثنين
                                                </label>
                                                <label htmlFor = {"d4"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"d4"} value = {"true"} checked = {this.state.employee.d4} onChange = {() => this.changeDayShift(Days.D4)} /> الثلاثاء
                                                </label>
                                                <label htmlFor = {"d5"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"d5"} value = {"true"} checked = {this.state.employee.d5} onChange = {() => this.changeDayShift(Days.D5)} /> الاربعاء
                                                </label>
                                                <label htmlFor = {"d6"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"d6"} value = {"true"} checked = {this.state.employee.d6} onChange = {() => this.changeDayShift(Days.D6)} /> الخميس
                                                </label>
                                                <label htmlFor = {"d7"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"d7"} value = {"true"} checked = {this.state.employee.d7} onChange = {() => this.changeDayShift(Days.D7)} /> الجمعة
                                                </label>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className = "grid grid-cols-1 row-gap-4 items-center p-1">
                                            <p>الشفت المسائي</p>
                                            <div className = "grid sm:grid-cols-4 md:grid-cols-3 lg:grid-cols-7 gap-1">
                                                <label htmlFor = {"e1"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"e1"} value = {"true"} checked = {this.state.employee.e1} onChange = {() => this.changeDayShift(Days.E1)} /> السبت
                                                </label>
                                                <label htmlFor = {"e2"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"e2"} value = {"true"} checked = {this.state.employee.e2} onChange = {() => this.changeDayShift(Days.E2)} /> الاحد
                                                </label>
                                                <label htmlFor = {"e3"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"e3"} value = {"true"} checked = {this.state.employee.e3} onChange = {() => this.changeDayShift(Days.E3)} /> الاثنين
                                                </label>
                                                <label htmlFor = {"e4"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"e4"} value = {"true"} checked = {this.state.employee.e4} onChange = {() => this.changeDayShift(Days.E4)} /> الثلاثاء
                                                </label>
                                                <label htmlFor = {"e5"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"e5"} value = {"true"} checked = {this.state.employee.e5} onChange = {() => this.changeDayShift(Days.E5)} /> الاربعاء
                                                </label>
                                                <label htmlFor = {"e6"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"e6"} value = {"true"} checked = {this.state.employee.e6} onChange = {() => this.changeDayShift(Days.E6)} /> الخميس
                                                </label>
                                                <label htmlFor = {"e7"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"e7"} value = {"true"} checked = {this.state.employee.e7} onChange = {() => this.changeDayShift(Days.E7)} /> الجمعة
                                                </label>
                                            </div>
                                        </div>
                                        {/*<hr />*/}
                                        {/*<div className = "grid grid-cols-1 row-gap-4 items-center p-1">
                                            <p>الشفت الليلي</p>
                                            <div className = "grid sm:grid-cols-4 md:grid-cols-3 lg:grid-cols-7 gap-1">
                                                <label htmlFor = {"n1"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"n1"} value = {"true"}
                                                           checked = {this.state.employee.n1}
                                                           onChange = {() => this.changeDayShift(Days.N1)} /> السبت
                                                </label>
                                                <label htmlFor = {"n2"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"n2"} value = {"true"}
                                                           checked = {this.state.employee.n2}
                                                           onChange = {() => this.changeDayShift(Days.N2)} /> الاحد
                                                </label>
                                                <label htmlFor = {"n3"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"n3"} value = {"true"}
                                                           checked = {this.state.employee.n3}
                                                           onChange = {() => this.changeDayShift(Days.N3)} /> الاثنين
                                                </label>
                                                <label htmlFor = {"n4"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"n4"} value = {"true"}
                                                           checked = {this.state.employee.n4}
                                                           onChange = {() => this.changeDayShift(Days.N4)} /> الثلاثاء
                                                </label>
                                                <label htmlFor = {"n5"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"n5"} value = {"true"}
                                                           checked = {this.state.employee.n5}
                                                           onChange = {() => this.changeDayShift(Days.N5)} /> الاربعاء
                                                </label>
                                                <label htmlFor = {"n6"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"n6"} value = {"true"}
                                                           checked = {this.state.employee.n6}
                                                           onChange = {() => this.changeDayShift(Days.N6)} /> الخميس
                                                </label>
                                                <label htmlFor = {"n7"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"n7"} value = {"true"}
                                                           checked = {this.state.employee.n7}
                                                           onChange = {() => this.changeDayShift(Days.N7)} /> الجمعة
                                                </label>
                                            </div>
                                        </div>*/}
                                    </div>
                                    <hr />
                                    {/*Buttons*/}
                                    <div className = {" flex gap-5 "}>
                                        <button id = "createBtn" hidden={this.state.create} className = "flex-auto text-green-500 bg-white border border-green-500 hover:bg-green-500 hover:text-white duration-75 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded py-2 text-center"
                                                onClick = {() => this.submitCreate()}>انشاء
                                        </button>
                                        <button id = "editBtn" hidden = {this.state.edit} className = "flex-auto text-blue-500 bg-white border border-blue-500 hover:bg-blue-500 hover:text-white duration-75 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg px-5 py-2 text-center "
                                                onClick = {() => this.submitEdit()}>تعديل
                                        </button>
                                        <button id = "cancelBtn" hidden = {this.state.cancel} className = "flex-auto text-red-500 bg-white border border-red-500 hover:bg-red-500 hover:text-white duration-75 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg px-5 py-2 text-center "
                                                onClick = {() => this.cancel()}>الغاء
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {/*Employees*/}
                    {(this.state.employeesPanel.employees || this.props.authInfo.userType == UserType.DEPARTMENT) &&
                        <div style = {{width: "95%"}} className = "grid gap-4 border border-gray-200 mx-auto rounded">
                            <input
                                className = "flex-1 shadow bg-white appearance-none bg-transparent border-none text-gray-700 p-3 sm:text-xxs md:text-xs lg:text-sm"
                                type = "text" placeholder = {langs.Search} autoFocus={true} aria-label = "Full name"
                                onChange = {(e) => e.target.value != undefined && this.search(e.target.value)}
                                value = {this.state.search}
                            />

                            <table id = "table" className = "text-center w-full select-none mx-auto mb-16 sm:text-xxs md:text-xs lg:text-sm">
                                <thead>
                                <tr>
                                    <th className = "pb-4">{'ت'}</th>
                                    <th className = "pb-4">{langs.name}</th>
                                    <th className = "pb-4">{langs.id}</th>
                                    <th className = "pb-4">{langs.department}</th>
                                    <th className = "pb-4">{'رقم الهاتف'}</th>
                                    {this.props.authInfo.userType !== UserType.DEPARTMENT &&
                                        <th className = "pb-4">{'خيارات'}</th>
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.employees.filter(att => !att.isHidden).map((emp, index) => {
                                    return (
                                        <tr key = {index} className = {"cursor-pointer hover:bg-blue-200 " + (index % 2 === 0 ? "bg-gray-200" : "")}>
                                            <td className = "py-2" onClick = {(e) => this.navigate(e, "/employee/" + emp.id, true)}>{index + 1}</td>
                                            <td className = "py-2" onClick = {(e) => this.navigate(e, "/employee/" + emp.id, true)}>{emp.name}</td>
                                            <td className = "py-2" onClick = {(e) => this.navigate(e, "/employee/" + emp.id, true)}>{emp.id}</td>
                                            <td className = "py-2 sm:inline-block sm:w-min md:table-cell" onClick = {(e) => this.navigate(e, "/employee/" + emp.id, true)}>{emp.depName}</td>
                                            <td className = "py-2" onClick = {(e) => this.navigate(e, "/employee/" + emp.id, true)}>{emp.email}</td>
                                            {this.props.authInfo.userType !== UserType.DEPARTMENT &&
                                                <td className = "py-2 flex gap-1 justify-center">
                                                    <button title = "تعديل" onClick = {() => this.editInfo(emp.id)} className = "btn p-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white border border-blue-500 hover:border-transparent rounded-sm sm:text-xxs md:text-sm">
                                                        <FontAwesomeIcon icon = {faEdit} />
                                                    </button>
                                                    <button title = "حذف" onClick = {(e) => this.submitDelete(emp.id)} className = "btn p-2 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white border border-red-500 hover:border-transparent rounded-sm sm:text-xxs md:text-sm">
                                                        <FontAwesomeIcon icon = {faTrash} />
                                                    </button>
                                                </td>
                                            }
                                        </tr>
                                    )
                                })
                                }
                                {(this.state.employees.filter(att => !att.isHidden).length === 0 && !this.state.loading) &&
                                    <tr>
                                        <td colSpan = {6}>
                                            <div className = "bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role = "alert">
                                                <p className = "text-sm text-center">{langs.no_data}</p>
                                            </div>
                                        </td>
                                    </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    }

                </div>
            }
        </div>;
    }
}

export default connect((store: Store) => {
    return {
        authInfo: store.LoginReducer
    }
})(withRouter(CreateEmployee))