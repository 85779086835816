import Axios, {AxiosResponse} from "axios";
import {
    Attendance,
    AttendanceList, Department,
    DepartmentUpdate, EmployeeAttendance,
    EmployeeAttendanceInfo,
    Vacation,
    VacationResponse, VacationEmployee, EmployeeNote, VacationStatus
} from "../Data/Tyeps";
import UrlManager from "../../Shared/UrlManager";
import {ROUTES} from "../../../Bootstrap/ROUTEs";

export default class AttendanceIO {

    public async fetchEmployee(id: string): Promise<AxiosResponse<Attendance>> {
        const url = UrlManager.getURL(ROUTES.FETCH_EMPLOYEE);
        return await Axios.get(url + id);
    }

    public async postVacation(vacation: Vacation):Promise<AxiosResponse<Vacation | boolean>> {
        const url = UrlManager.getURL(ROUTES.UPDATE_VACATION);
        return await Axios.post(url, vacation);
    }

    public async fetchVacation(id: String): Promise<AxiosResponse<VacationResponse>> {
        const url = UrlManager.getURL(ROUTES.FETCH_VACATION) + "/" + id;
        return await Axios.get(url);
    }
    public async getLastAttendanceDate(): Promise<AxiosResponse<Date>> {
        const url = UrlManager.getURL(ROUTES.GET_LAST_ATTENDANCE_DATE);
        return await Axios.get(url);
    }

    public async fetch(date: string): Promise<AxiosResponse<Attendance[]>> {
        const url = UrlManager.getURL(ROUTES.FETCH_ATTENDANCE) + '?date=' + date;
        return await Axios.get(url);
    }

    public async fetchDepartments(date: string): Promise<AxiosResponse<DepartmentUpdate[]>> {
        const url = UrlManager.getURL(ROUTES.FETCH_DEPARTMENT) + '?date=' + date;
        return await Axios.get(url);
    }

    public async fetchByEmpId(id: string): Promise<AxiosResponse<EmployeeAttendanceInfo>> {
        const url = UrlManager.getURL(ROUTES.FETCH_EMPLOYEE_ATTENDANCE) + id;
        return await Axios.get(url);
    }

    public async updateEmp(emp: Attendance): Promise<AxiosResponse<Attendance>> {
        const url = UrlManager.getURL(ROUTES.UPDATE_EMPLOYEE_INFO);
        return await Axios.post(url, emp);
    }

    public async updateAttendance(att: EmployeeAttendance): Promise<AxiosResponse<EmployeeAttendance>> {
        const url = UrlManager.getURL(ROUTES.UPDATE_EMPLOYEE_ATTENDANCE);
        return await Axios.post(url, att);
    }

    public async deleteAttendance(id:number): Promise<AxiosResponse<number>> {
        const url = UrlManager.getURL(ROUTES.DELETE_EMPLOYEE_ATTENDANCE);
        return await Axios.get(url + "/" + id);
    }

    async setvication(att: AttendanceList): Promise<AxiosResponse<AttendanceList>> {
        const url = UrlManager.getURL(ROUTES.UPDATE_VACATION);
        return await Axios.post(url, att);
    }

    async fetchVacations(): Promise<AxiosResponse<VacationEmployee[]>> {
        const url = UrlManager.getURL(ROUTES.FETCH_VACATIONS);
        return await Axios.get(url);
    }

    async deleteVacations(id: string): Promise<AxiosResponse> {
        const url = UrlManager.getURL(ROUTES.DELETE_VACATION + '/' + id);
        return await Axios.get(url);
    }

    async fetchDepartment(): Promise<AxiosResponse<Department[]>> {
        const url = UrlManager.getURL(ROUTES.FETCH_DEPARTMENTS);
        return await Axios.get(url);
    }

    public async fetchEmployees(): Promise<AxiosResponse<Attendance[]>> {
        const url = UrlManager.getURL(ROUTES.FETCH_EMPLOYEES);
        return await Axios.get(url);
    }

    public async createEmployee(emp: Attendance):Promise<AxiosResponse<Attendance>> {
        const url = UrlManager.getURL(ROUTES.CREATE_EMPLOYEE);
        return await Axios.post(url, emp);
    }

    public async deleteEmployee(id: string): Promise<AxiosResponse<any>> {
        const url = UrlManager.getURL(ROUTES.DELETE_EMPLOYEE + '/' + id);
        return await Axios.get(url);
    }

    public async editEmployee(emp:Attendance): Promise<AxiosResponse<number>> {
        const url = UrlManager.getURL(ROUTES.EDIT_EMPLOYEE);
        return await Axios.post(url, emp);
    }

    public async fetchEmployeeNotes(empId:string): Promise<AxiosResponse<Array<EmployeeNote>>> {
        const url = UrlManager.getURL(ROUTES.FETCH_EMPLOYEE_NOTES);
        return await Axios.get(url + empId);
    }
    public async deleteEmployeeNotes(noteId:string): Promise<AxiosResponse<EmployeeNote>> {
        const url = UrlManager.getURL(ROUTES.DELETE_EMPLOYEE_NOTE);
        return await Axios.get(url + noteId);
    }
    public async addEmployeeNotes(note:EmployeeNote): Promise<AxiosResponse<EmployeeNote>> {
        const url = UrlManager.getURL(ROUTES.ADD_EMPLOYEE_NOTE);
        const fd = new FormData();
        fd.append("id", "");
        fd.append("title", note.title);
        fd.append("description", note.description);
        fd.append("imagePath", "");
        fd.append("createdDate", new Date().toLocaleDateString());
        fd.append("noteType", note.noteType.toString());
        fd.append("employeeId", note.employeeId);
        console.log(note.employeeId);
        fd.append("imageFile", note.imageFile as Blob);
        return await Axios.post(url,fd);
    }
    public async updateEmployeeNotes(note:EmployeeNote): Promise<AxiosResponse<EmployeeNote>> {
        const url = UrlManager.getURL(ROUTES.UPDATE_EMPLOYEE_NOTE);
        return await Axios.post(url);
    }

    public async uploadAttCsv(attCsv:JSON[]): Promise<AxiosResponse<number>> {
        const url = UrlManager.getURL(ROUTES.UPLOAD_ATT_CSV);
        return await Axios.post(url, attCsv);
    }

    // VACATION IO
    async rejectVacation(id: string): Promise<AxiosResponse<Vacation>> {
        const url = UrlManager.getURL(ROUTES.REJECT_VACATION + '/' + id);
        return await Axios.get(url);
    }
    async approveVacation(id: string): Promise<AxiosResponse<number>> {
        const url = UrlManager.getURL(ROUTES.APPROVE_VACATION + '/' + id);
        return await Axios.get(url);
    }
    async approveVacationPaid(id: string, status:VacationStatus): Promise<AxiosResponse<Vacation>> {
        const url = UrlManager.getURL(ROUTES.APPROVE_VACATION_PAID + '?id=' + id + "&status=" + status);
        return await Axios.get(url);
    }
    // END OF VACATION IO

}