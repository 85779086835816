export enum ROUTES {

    // USER API
    LOGIN = "/api/user/login",
    // END OF USER API

    // ATTENDANCE API
    FETCH_ATTENDANCE = "/api/attendance",
    FETCH_DEPARTMENT = "/api/attendance/dep",
    UPLOAD_ATT_CSV = "/api/attendance/attCsv",
    FETCH_DEPARTMENTS = "/api/attendance/deps",
    FETCH_EMPLOYEE_ATTENDANCE = "/api/attendance/",
    UPDATE_EMPLOYEE_INFO = "/api/attendance/employee",
    UPDATE_EMPLOYEE_ATTENDANCE = "/api/attendance/update",
    DELETE_EMPLOYEE_ATTENDANCE = "/api/attendance/delete",
    UPDATE_EMPLOYEE_ATTENDANCES = "/api/attendance/updateRange",
    GET_LAST_ATTENDANCE_DATE = "/api/attendance/lastAttendanceDate",
    // END OF ATTENDANCE API

    // VACATION API
    REJECT_VACATION = "/api/vacation/reject",
    FETCH_VACATION = "/api/vacation/vacation",
    APPROVE_VACATION = "/api/vacation/approve",
    FETCH_VACATIONS = "/api/vacation/Vacations",
    UPDATE_VACATION = "/api/vacation/AddVacation",
    DELETE_VACATION = "/api/vacation/DeleteVacation",
    REQUEST_VACATION = "/api/Vacation/RequestVacation/",
    APPROVE_VACATION_PAID = "/api/vacation/approvePaid",
    FETCH_EMPLOYEE_WITH_VACATIONS = "/api/Vacation/FetchUserPendingVacation/",
    // END OF VACATION API

    // EMPLOYEE API
    FETCH_EMPLOYEE = "/api/Employee/",
    EDIT_EMPLOYEE = "/api/Employee/edit",
    DELETE_EMPLOYEE = "/api/Employee/delete",
    FETCH_EMPLOYEES = "/api/Employee/employee",
    ADD_EMPLOYEE_NOTE = "/api/Employee/addNote",
    FETCH_EMPLOYEE_NOTES = "/api/Employee/note/",
    CREATE_EMPLOYEE = "/api/Employee/createEmployee",
    DELETE_EMPLOYEE_NOTE = "/api/Employee/deleteNote/",
    UPDATE_EMPLOYEE_NOTE = "/api/Employee/updateNote",
    // END OF EMPLOYEE API

    // REPORT API
    FETCH_REPORT_DATA = "/api/report/ad",
    // END OF REPORT API

    // DATA API
    FETCH_CONFIG = "/api/data/config",
    UPDATE_CONFIG = "/api/data/updateConfig",
    // END OF DATA API

    // EXTRA API
    OFFICES = "/api/office",
    DELETE_CONTENERS = "/api/Contener/",
    CREATE_OFFICE = "/api/office/Create",
    CREATE_CONTENERS = "/api/Contener/Create",
    ADDDATA_CONTENERS = "/api/Contener/AddData",
    CREATE_NEWS_ITEM = "/api/News/CreateNewsItem",
    // END OF EXTRA API
}