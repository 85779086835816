import React, {Component} from 'react';
import CloudLoader from "../Shared/Loading/CloudLoader";
import {langs} from "../../Bootstrap/Langs/Lang";
import AdminIo from "./IO/AdminIo";
import {AdminPanel, AdminPanelType, Config, Dates, Shift, UpdateAttendanceStatus} from "./Data/Types";
import Initializer from "../Shared/initializer";
import {AttendanceType} from "../Attandancees/Data/Tyeps";
import EnumTranslate from "../Shared/enumTranslate";

interface Props {
}

interface State {
    loading: boolean;
    error: boolean;
    config: Config;
    panels: AdminPanel;
    dates: Dates;
    updateAttendanceStatus: UpdateAttendanceStatus;
}

class AdminMainComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const initializer = new Initializer();
        this.state = {
            panels: {config: true, attendance: false, users: false},
            loading: false,
            error: false,
            config: initializer.config(),
            dates: initializer.dates(),
            updateAttendanceStatus: initializer.updateAttendanceStatus()
        };

        this._AdminIO = new AdminIo();
        this._enumTranslate = new EnumTranslate();
    }

    private _AdminIO: AdminIo;
    private _enumTranslate: EnumTranslate;

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        try {
            this.setState({error: false, loading: true});
            const res = await this._AdminIO.fetchConfig();
            this.setState({error: false, loading: false, config: res.data});
        } catch (e) {
            this.setState({loading: false, error: true});
        }
    }

    submitEdit = async () => {
        this.setState({loading: true, error: false});
        try {
            const res = await this._AdminIO.updateConfig(this.state.config);
            this.setState({config: res.data, loading: false, error: false});
        } catch {
            this.setState({loading: false, error: true});
        }
    }

    changeTime = (time: string, type: string) => {
        const h = time.split(':')[0];
        const m = time.split(':')[1];
        switch (type) {
            case "di":
                this.state.config.dayInShift = h + ':' + m + ':00';
                break;
            case "dil":
                this.state.config.dayInShiftLate = h + ':' + m + ':00';
                break;
            case "do":
                this.state.config.dayOutShift = h + ':' + m + ':00';
                break;
            case "dai":
                this.state.config.dayShiftAfterInTweak = h + ':' + m + ':00';
                break;
            case "dbi":
                this.state.config.dayShiftBeforeInTweak = h + ':' + m + ':00';
                break;
            case "dao":
                this.state.config.dayShiftAfterOutTweak = h + ':' + m + ':00';
                break;
            case "dbo":
                this.state.config.dayShiftBeforeOutTweak = h + ':' + m + ':00';
                break;
            case "ei":
                this.state.config.eveningInShift = h + ':' + m + ':00';
                break;
            case "eil":
                this.state.config.eveningInShiftLate = h + ':' + m + ':00';
                break;
            case "eo":
                this.state.config.eveningOutShift = h + ':' + m + ':00';
                break;
            case "eai":
                this.state.config.eveningShiftAfterInTweak = h + ':' + m + ':00';
                break;
            case "ebi":
                this.state.config.eveningShiftBeforeInTweak = h + ':' + m + ':00';
                break;
            case "eao":
                this.state.config.eveningShiftAfterOutTweak = h + ':' + m + ':00';
                break;
            case "ebo":
                this.state.config.eveningShiftBeforeOutTweak = h + ':' + m + ':00';
                break;
            case "ni":
                this.state.config.nightInShift = h + ':' + m + ':00';
                break;
            case "nil":
                this.state.config.nightInShiftLate = h + ':' + m + ':00';
                break;
            case "no":
                this.state.config.nightOutShift = h + ':' + m + ':00';
                break;
            case "nai":
                this.state.config.nightShiftAfterInTweak = h + ':' + m + ':00';
                break;
            case "nbi":
                this.state.config.nightShiftBeforeInTweak = h + ':' + m + ':00';
                break;
            case "nao":
                this.state.config.nightShiftAfterOutTweak = h + ':' + m + ':00';
                break;
            case "nbo":
                this.state.config.nightShiftBeforeOutTweak = h + ':' + m + ':00';
                break;
            default:
                return false;
        }
        this.setState({config: this.state.config});
    }

    reverseDate = (date: Date) => {
        const dateSplit = new Date(date).toLocaleDateString().replaceAll('/', '-').split('-');
        return (dateSplit[2] + '-'
            + (dateSplit[0].length > 1 ? dateSplit[0] : "0" + dateSplit[0]) + '-'
            + (dateSplit[1].length > 1 ? dateSplit[1] : "0" + dateSplit[1]));
    }

    changePanel = (type: AdminPanelType) => {
        switch (type) {
            case AdminPanelType.CONFIG:
                this.setState({panels: {config: true, attendance: false, users: false}});
                break;
            case AdminPanelType.ATTENDANCE:
                this.setState({panels: {config: false, attendance: true, users: false}});
                break;
            case AdminPanelType.USERS:
                this.setState({panels: {config: false, attendance: false, users: true}});
                break;
        }
    }

    submitDeleteAttendances = async () => {
        const confirm = window.confirm(`سيتم حذف الحضور للفترة ${this.state.dates.from.toLocaleDateString()} الى ${this.state.dates.to.toLocaleDateString()}`);
        if (!confirm) {
            return;
        }
        this.setState({loading: true, error: false});
        try {
            const res = await this._AdminIO.deleteAttendances(this.state.dates);
            if (res.status == 200) {
                if (res.data >= 1) {
                    // Success
                } else {
                    // Fail
                }
            }
            this.setState({loading: false, error: false});
        } catch {
            this.setState({loading: false, error: true});
        }
    }

    submitUpdateAttendances = async () => {
        const confirm = window.confirm(`سيتم تعديل الحضور للفترة ${this.state.updateAttendanceStatus.from.toLocaleDateString()} الى ${this.state.updateAttendanceStatus.to.toLocaleDateString()}`);
        if (!confirm) {
            return;
        }
        this.setState({loading: true, error: false});
        try {
            const res = await this._AdminIO.updateAttendances(this.state.updateAttendanceStatus);
            if (res.status == 200) {
                if (res.data >= 1) {
                    // Success
                } else {
                    // Fail
                }
            }
            this.setState({loading: false, error: false});
        } catch {
            this.setState({loading: false, error: true});
        }
    }

    render() {
        return (
            <>
                {/* Panels */}
                <div className = "flex border-b border-blue-600 mb-5 mx-auto sm:text-xxs md:text-sm">
                    <p className = {"p-3 cursor-pointer hover:text-blue-600" + (this.state.panels.config ? " border-b-4 border-blue-600" : "")} onClick = {() => this.changePanel(AdminPanelType.CONFIG)}>اعدادات النظام</p>
                    <p className = {"p-3 cursor-pointer hover:text-blue-600" + (this.state.panels.attendance ? " border-b-4 border-blue-600" : "")} onClick = {() => this.changePanel(AdminPanelType.ATTENDANCE)}>الحضور</p>
                    <p className = {"p-3 cursor-pointer hover:text-blue-600" + (this.state.panels.users ? " border-b-4 border-blue-600" : "")} onClick = {() => this.changePanel(AdminPanelType.USERS)}>المستخدمين</p>
                </div>

                {/* Config */}
                {!this.state.loading && this.state.panels.config &&
                    <div className = "grid row-gap-4 m-auto p-8 bg-gray-200 sm:text-xs md:text-sm">
                        <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:row-gap-4 md:row-gap-2 col-gap-8">
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "dayIn" className = "p-2 border rounded shadow">حظور الشفت الصباحي</label>
                                <input id = "dayIn" className = "p-2 border rounded shadow" type = "time" value = {this.state.config.dayInShift}
                                       onChange = {(e) => {
                                           this.changeTime(e.target.value, "di")
                                       }} />
                            </div>
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "dayInLate" className = "p-2 border rounded shadow">تاخير الشفت الصباحي</label>
                                <input id = "dayInLate" className = "p-2 border rounded shadow" type = "time" value = {this.state.config.dayInShiftLate}
                                       onChange = {(e) => {
                                           this.changeTime(e.target.value, "dil")
                                       }} />
                            </div>
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "dayOut" className = "p-2 border rounded shadow">خروج الشفت الصباحي</label>
                                <input id = "dayOut" className = "p-2 border rounded shadow" type = "time" value = {this.state.config.dayOutShift}
                                       onChange = {(e) => {
                                           this.changeTime(e.target.value, "do")
                                       }} />
                            </div>
                        </div>
                        <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:row-gap-4 md:row-gap-2 col-gap-8">
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "beforeDayIn" className = "p-2 border rounded shadow">الصباحي قبل الدخول</label>
                                <input id = "beforeDayIn" className = "p-2 border rounded shadow" type = "time" value = {this.state.config.dayShiftBeforeInTweak}
                                       onChange = {(e) => {
                                           this.changeTime(e.target.value, "dbi")
                                       }} />
                            </div>
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "afterDayIn" className = "p-2 border rounded shadow">الصباحي بعد الدخول</label>
                                <input id = "afterDayIn" className = "p-2 border rounded shadow" type = "time" value = {this.state.config.dayShiftAfterInTweak}
                                       onChange = {(e) => {
                                           this.changeTime(e.target.value, "dai")
                                       }} />
                            </div>
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "beforeDayOut" className = "p-2 border rounded shadow">الصباحي قبل الخروج</label>
                                <input id = "beforeDayOut" className = "p-2 border rounded shadow" type = "time" value = {this.state.config.dayShiftBeforeOutTweak}
                                       onChange = {(e) => {
                                           this.changeTime(e.target.value, "dbo")
                                       }} />
                            </div>
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "afterDayOut" className = "p-2 border rounded shadow">الصباحي بعد الخروج</label>
                                <input id = "afterDayOut" className = "p-2 border rounded shadow" type = "time" value = {this.state.config.dayShiftAfterOutTweak}
                                       onChange = {(e) => {
                                           this.changeTime(e.target.value, "dao")
                                       }} />
                            </div>
                        </div>

                        <hr />

                        <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:row-gap-4 md:row-gap-2 col-gap-8">
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "eveningIn" className = "p-2 border rounded shadow">حظور الشفت المسائي</label>
                                <input id = "eveningIn" className = "p-2 border rounded shadow" type = "time" value = {this.state.config.eveningInShift}
                                       onChange = {(e) => {
                                           this.changeTime(e.target.value, "ei")
                                       }} />
                            </div>
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "eveningInLate" className = "p-2 border rounded shadow">تاخير الشفت المسائي</label>
                                <input id = "eveningInLate" className = "p-2 border rounded shadow" type = "time" value = {this.state.config.eveningInShiftLate}
                                       onChange = {(e) => {
                                           this.changeTime(e.target.value, "eil")
                                       }} />
                            </div>
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "eveningOut" className = "p-2 border rounded shadow">خروج الشفت المسائي</label>
                                <input id = "eveningOut" className = "p-2 border rounded shadow" type = "time" value = {this.state.config.eveningOutShift}
                                       onChange = {(e) => {
                                           this.changeTime(e.target.value, "eo")
                                       }} />
                            </div>
                        </div>
                        <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:row-gap-4 md:row-gap-2 col-gap-8">
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "beforeEveningIn" className = "p-2 border rounded shadow">المسائي قبل الدخول</label>
                                <input id = "beforeEveningIn" className = "p-2 border rounded shadow" type = "time" value = {this.state.config.eveningShiftBeforeInTweak}
                                       onChange = {(e) => {
                                           this.changeTime(e.target.value, "ebi")
                                       }} />
                            </div>
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "afterEveningIn" className = "p-2 border rounded shadow">المسائي بعد الدخول</label>
                                <input id = "afterEveningIn" className = "p-2 border rounded shadow" type = "time" value = {this.state.config.eveningShiftAfterInTweak}
                                       onChange = {(e) => {
                                           this.changeTime(e.target.value, "eai")
                                       }} />
                            </div>
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "baforeEveningOut" className = "p-2 border rounded shadow">المسائي قبل الخروج</label>
                                <input id = "baforeEveningOut" className = "p-2 border rounded shadow" type = "time" value = {this.state.config.eveningShiftBeforeOutTweak}
                                       onChange = {(e) => {
                                           this.changeTime(e.target.value, "ebo")
                                       }} />
                            </div>
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "afterEveningOut" className = "p-2 border rounded shadow">المسائي بعد الخروج</label>
                                <input id = "afterEveningOut" className = "p-2 border rounded shadow" type = "time" value = {this.state.config.eveningShiftAfterOutTweak}
                                       onChange = {(e) => {
                                           this.changeTime(e.target.value, "eao")
                                       }} />
                            </div>
                        </div>

                        {/*<hr />
                        <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:row-gap-4 md:row-gap-0 col-gap-8">
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "nightIn">حظور الشفت الليلي</label>
                                <input id = "nightIn" className = "p-2 rounded shadow" type = "time" value = {this.state.config.nightInShift}
                                       onChange = {(e) => {
                                           this.changeTime(e.target.value, "ni")
                                       }} />
                            </div>
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "nightInLate">تاخير الشفت الليلي</label>
                                <input id = "nightInLate" className = "p-2 rounded shadow" type = "time" value = {this.state.config.nightInShiftLate}
                                       onChange = {(e) => {
                                           this.changeTime(e.target.value, "nil")
                                       }} />
                            </div>
                        </div>*/}
                        <hr />

                        {/* Start and End Month */}
                        <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:row-gap-4 md:row-gap-2 col-gap-8">
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "startMonth" className = "p-2 border rounded shadow">بداية الشهر</label>
                                <input id = "startMonth" className = "p-2 border rounded shadow" type = "date" value = {this.reverseDate(this.state.config.startMonth)}
                                       onChange = {(e) => {
                                           this.state.config.startMonth = new Date(e.target.value);
                                           this.setState({config: this.state.config})
                                       }} />
                            </div>
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "endMonth" className = "p-2 border rounded shadow">نهاية الشهر</label>
                                <input id = "endMonth" className = "p-2 border rounded shadow" type = "date" value = {this.reverseDate(this.state.config.endMonth)}
                                       onChange = {(e) => {
                                           this.state.config.endMonth = new Date(e.target.value);
                                           this.setState({config: this.state.config})
                                       }} />
                            </div>
                        </div>

                        <hr />

                        <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:row-gap-4 md:row-gap-2 col-gap-8">
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "vacationInOneMonth" className = "p-2 border shadow rounded select-none">الاجازات للشهر الواحد</label>
                                <input id = "vacationInOneMonth" className = "p-2 border shadow rounded select-none" type = "number" min = {0} value = {this.state.config.allowedVacationCountForDepartment}
                                       onChange = {(e) => {
                                           this.state.config.allowedVacationCountForDepartment = parseInt(e.target.value);
                                           this.setState({config: this.state.config})
                                       }} />
                            </div>
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "continuesVacation" className = "p-2 border shadow rounded select-none">اجازات متتالية</label>
                                <input id = "continuesVacation" className = "p-2 border shadow rounded select-none" type = "number" min = {0} value = {this.state.config.allowedContinuesVacationForDepartment}
                                       onChange = {(e) => {
                                           this.state.config.allowedContinuesVacationForDepartment = parseInt(e.target.value);
                                           this.setState({config: this.state.config})
                                       }} />
                            </div>
                        </div>

                        <hr />

                        <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:row-gap-4 md:row-gap-2 col-gap-8">
                            <label htmlFor = "disableVacation" className = "flex items-center justify-center shadow rounded gap-2 p-2 cursor-pointer hover:bg-gray-400 select-none">
                                <input id = "disableVacation" type = "checkbox" value = "true" checked = {this.state.config.disableVacation}
                                       onChange = {() => {
                                           this.state.config.disableVacation = !this.state.config.disableVacation
                                           this.setState({config: this.state.config})
                                       }} /> ايقاف نظام الاجازات
                            </label>

                            <label htmlFor = "disableFree" className = "flex items-center justify-center shadow rounded gap-2 p-2 cursor-pointer hover:bg-gray-400 select-none">
                                <input id = "disableFree" type = "checkbox" value = "true" checked = {this.state.config.disableFree}
                                       onChange = {() => {
                                           this.state.config.disableFree = !this.state.config.disableFree
                                           this.setState({config: this.state.config})
                                       }} /> ايقاف نظام التفرغات
                            </label>
                        </div>

                        <hr />

                        <button className = "grid border border-blue-500 rounded shadow py-2 text-blue-500 hover:bg-blue-500 hover:text-white hover:border-transparent " onClick = {this.submitEdit}>حفظ</button>
                    </div>
                }

                {/* Attendance */}
                {!this.state.loading && this.state.panels.attendance &&
                    <div className = "grid row-gap-4 m-auto p-8 bg-gray-200 sm:text-xs md:text-sm">
                        {/* Delete Attendance */}
                        <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:row-gap-4 md:row-gap-2 col-gap-2">
                            {/* Title */}
                            <label className = "p-2 border rounded shadow bg-gray-300 sm:col-span-1 md:col-span-2">حذف حضور لفترة محدده</label>
                            {/* From */}
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "deleteFrom" className = "p-2 border rounded shadow">من</label>
                                <input id = "deleteFrom" className = "p-2 border rounded shadow" type = "date" value = {this.reverseDate(this.state.dates.from)}
                                       onChange = {(e) => {
                                           this.state.dates.from = new Date(e.target.value);
                                           this.state.dates.to = new Date(e.target.value);
                                           this.setState({dates: this.state.dates})
                                       }} />
                            </div>
                            {/* To */}
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "deleteTo" className = "p-2 border rounded shadow">الى</label>
                                <input id = "deleteTo" className = "p-2 border rounded shadow" type = "date" min = {this.reverseDate(this.state.dates.from)} value = {this.reverseDate(this.state.dates.to)}
                                       onChange = {(e) => {
                                           this.state.dates.to = new Date(e.target.value);
                                           this.setState({dates: this.state.dates})
                                       }} />
                            </div>
                            {/* Button */}
                            <button className = "grid sm:col-span-1 md:col-span-2 border border-red-500 rounded shadow py-2 text-red-500 hover:bg-red-500 hover:text-white hover:border-transparent " onClick = {this.submitDeleteAttendances}>حذف</button>
                        </div>

                        <hr className = "border-gray-500" />

                        {/* Change Attendance Type */}
                        <div className = "grid sm:grid-cols-1 md:grid-cols-2 sm:row-gap-4 md:row-gap-2 col-gap-2">
                            {/* Title */}
                            <label className = "p-2 border rounded shadow bg-gray-300 sm:col-span-1 md:col-span-2">تغيير حالة الحضور لفترة محدده</label>
                            {/* Exceptions */}
                            <div className = "p-2 grid row-gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 border rounded shadow sm:col-span-1 md:col-span-2">
                                <label className = "p-2 border rounded shadow sm:col-span-2 md:col-span-3 lg:col-span-4">باستثناء</label>
                                <label className = "flex gap-2 p-2 items-center border shadow rounded cursor-pointer select-none hover:bg-gray-300">
                                    <input type = "checkbox" checked = {this.state.updateAttendanceStatus.present} onChange = {() => {
                                        this.state.updateAttendanceStatus.present = !this.state.updateAttendanceStatus.present;
                                        this.setState({updateAttendanceStatus: this.state.updateAttendanceStatus});
                                    }} />
                                    حضور
                                </label>
                                <label className = "flex gap-2 p-2 items-center border shadow rounded cursor-pointer select-none hover:bg-gray-300">
                                    <input type = "checkbox" checked = {this.state.updateAttendanceStatus.absence} onChange = {() => {
                                        this.state.updateAttendanceStatus.absence = !this.state.updateAttendanceStatus.absence;
                                        this.setState({updateAttendanceStatus: this.state.updateAttendanceStatus});
                                    }} />
                                    غياب
                                </label>
                                <label className = "flex gap-2 p-2 items-center border shadow rounded cursor-pointer select-none hover:bg-gray-300">
                                    <input type = "checkbox" checked = {this.state.updateAttendanceStatus.night} onChange = {() => {
                                        this.state.updateAttendanceStatus.night = !this.state.updateAttendanceStatus.night;
                                        this.setState({updateAttendanceStatus: this.state.updateAttendanceStatus});
                                    }} />
                                    مسائي
                                </label>
                                <label className = "flex gap-2 p-2 items-center border shadow rounded cursor-pointer select-none hover:bg-gray-300">
                                    <input type = "checkbox" checked = {this.state.updateAttendanceStatus.free} onChange = {() => {
                                        this.state.updateAttendanceStatus.free = !this.state.updateAttendanceStatus.free;
                                        this.setState({updateAttendanceStatus: this.state.updateAttendanceStatus});
                                    }} />
                                    تفرغ
                                </label>
                                <label className = "flex gap-2 p-2 items-center border shadow rounded cursor-pointer select-none hover:bg-gray-300">
                                    <input type = "checkbox" checked = {this.state.updateAttendanceStatus.late} onChange = {() => {
                                        this.state.updateAttendanceStatus.late = !this.state.updateAttendanceStatus.late;
                                        this.setState({updateAttendanceStatus: this.state.updateAttendanceStatus});
                                    }} />
                                    تاخير
                                </label>
                                <label className = "flex gap-2 p-2 items-center border shadow rounded cursor-pointer select-none hover:bg-gray-300">
                                    <input type = "checkbox" checked = {this.state.updateAttendanceStatus.assign} onChange = {() => {
                                        this.state.updateAttendanceStatus.assign = !this.state.updateAttendanceStatus.assign;
                                        this.setState({updateAttendanceStatus: this.state.updateAttendanceStatus});
                                    }} />
                                    تكليف
                                </label>
                                <label className = "flex gap-2 p-2 items-center border shadow rounded cursor-pointer select-none hover:bg-gray-300">
                                    <input type = "checkbox" checked = {this.state.updateAttendanceStatus.dispatch} onChange = {() => {
                                        this.state.updateAttendanceStatus.dispatch = !this.state.updateAttendanceStatus.dispatch;
                                        this.setState({updateAttendanceStatus: this.state.updateAttendanceStatus});
                                    }} />
                                    ايفاد
                                </label>
                                <label className = "flex gap-2 p-2 items-center border shadow rounded cursor-pointer select-none hover:bg-gray-300">
                                    <input type = "checkbox" checked = {this.state.updateAttendanceStatus.vacation} onChange = {() => {
                                        this.state.updateAttendanceStatus.vacation = !this.state.updateAttendanceStatus.vacation;
                                        this.setState({updateAttendanceStatus: this.state.updateAttendanceStatus});
                                    }} />
                                    اجازة
                                </label>
                                <label className = "flex gap-2 p-2 items-center border shadow rounded cursor-pointer select-none hover:bg-gray-300">
                                    <input type = "checkbox" checked = {this.state.updateAttendanceStatus.presidency_Vacation_Paid} onChange = {() => {
                                        this.state.updateAttendanceStatus.presidency_Vacation_Paid = !this.state.updateAttendanceStatus.presidency_Vacation_Paid;
                                        this.setState({updateAttendanceStatus: this.state.updateAttendanceStatus});
                                    }} />
                                    اجازة (امر براتب)
                                </label>
                                <label className = "flex gap-2 p-2 items-center border shadow rounded cursor-pointer select-none hover:bg-gray-300">
                                    <input type = "checkbox" checked = {this.state.updateAttendanceStatus.presidency_Vacation_UnPaid} onChange = {() => {
                                        this.state.updateAttendanceStatus.presidency_Vacation_UnPaid = !this.state.updateAttendanceStatus.presidency_Vacation_UnPaid;
                                        this.setState({updateAttendanceStatus: this.state.updateAttendanceStatus});
                                    }} />
                                    اجازة (امر بدون راتب)
                                </label>
                                <label className = "flex gap-2 p-2 items-center border-red-200 shadow rounded cursor-pointer select-none bg-red-200 hover:bg-red-400">
                                    <input type = "checkbox" checked = {this.state.updateAttendanceStatus.withShift} onChange = {() => {
                                        this.state.updateAttendanceStatus.withShift = !this.state.updateAttendanceStatus.withShift;
                                        this.setState({updateAttendanceStatus: this.state.updateAttendanceStatus});
                                    }} />
                                    تعديل الشفتات
                                </label>
                            </div>
                            {/* From */}
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "updateFrom" className = "p-2 border rounded shadow">من</label>
                                <input id = "updateFrom" className = "p-2 border rounded shadow" type = "date" value = {this.reverseDate(this.state.updateAttendanceStatus.from)}
                                       onChange = {(e) => {
                                           this.state.updateAttendanceStatus.from = new Date(e.target.value);
                                           this.state.updateAttendanceStatus.to = new Date(e.target.value);
                                           this.setState({updateAttendanceStatus: this.state.updateAttendanceStatus})
                                       }} />
                            </div>
                            {/* To */}
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "updateTo" className = "p-2 border rounded shadow">الى</label>
                                <input id = "updateTo" className = "p-2 border rounded shadow" type = "date" min = {this.reverseDate(this.state.updateAttendanceStatus.from)} value = {this.reverseDate(this.state.updateAttendanceStatus.to)}
                                       onChange = {(e) => {
                                           this.state.updateAttendanceStatus.to = new Date(e.target.value);
                                           this.setState({updateAttendanceStatus: this.state.updateAttendanceStatus})
                                       }} />
                            </div>
                            {/* Attendance Type */}
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "attendanceType" className = "p-2 border rounded shadow">الحالة</label>
                                <select id = "attendanceType" className = "p-2 border rounded bg-white shadow" value = {this.state.updateAttendanceStatus.attendanceType} onChange = {(e) => {
                                    this.state.updateAttendanceStatus.attendanceType = parseInt(e.target.value);
                                    this.setState({updateAttendanceStatus: this.state.updateAttendanceStatus});
                                }}>
                                    {Object.keys(AttendanceType).filter(item => !isNaN(parseInt(item))).map((a, b) => {
                                        return (
                                            <option value = {b}>{this._enumTranslate.GetAttendanceTypeAr(b)}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            {/* Attendance Shift */}
                            <div className = "grid grid-cols-2 items-center">
                                <label htmlFor = "attendanceShift" className = "p-2 border rounded shadow">الشفت</label>
                                <select id = "attendanceShift" disabled = {!this.state.updateAttendanceStatus.withShift} className = {`p-2 border rounded shadow ${(!this.state.updateAttendanceStatus.withShift) ? "bg-gray-300 text-gray-600" : "bg-white"}`} value = {this.state.updateAttendanceStatus.shift} onChange = {(e) => {
                                    this.state.updateAttendanceStatus.shift = parseInt(e.target.value);
                                    this.setState({updateAttendanceStatus: this.state.updateAttendanceStatus});
                                }}>
                                    {Object.keys(Shift).filter(item => !isNaN(parseInt(item))).map((a, b) => {
                                        return (
                                            <option key = {b} value = {b}>{this._enumTranslate.GetShiftAr(b)}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            {/* Button */}
                            <button className = "grid border border-blue-500 rounded shadow py-2 text-blue-500 hover:bg-blue-500 hover:text-white hover:border-transparent sm:col-span-1 md:col-span-2" onClick = {this.submitUpdateAttendances}>تعديل</button>
                        </div>
                    </div>
                }

                {/* Users */}
                {!this.state.loading && this.state.panels.users &&
                    <CloudLoader title = {"...COMING SOON"} className = "w-1/6 mx-auto text-center mb-6" />
                }

                {this.state.loading &&
                    <CloudLoader title = {langs.loading_data} className = "w-1/6 mx-auto text-center" />
                }
            </>
        )
            ;
    }

}


export default AdminMainComponent;