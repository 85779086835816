import {ILang} from "./Ilang";

export const En: ILang = {
    vacation: "vacation",
    vacations: "vacations",
    d2: "Sunday", d3: "Monday", d4: "Tuesday", d5: "Wednesday", d6: "thursday", d7: "Friday",
    d1: "Saturday",
    index: "No.",
    uploadTime: "uploadTime",
    Departments: "Departments",
    login: "Login",
    Search: "Search",
    Absence: "Absence", Presence: "Presence",
    employeeInfo: "Employee Info",
    date: "Date",
    counter: "C.",
    logout: "Logout",
    lang: "Arabic",
    Attandance: "Attandance",
    TimeIn: "Time in", TimeOut: "Time out", department: "department",
    id: "Id",
    delete: "Delete",
    AddContener: "Create Contener",
    save: "Save",
    unexpected_error: "unexpected error",
    RFID: "Rfid",
    des: "description",
    fingerPrintId: "Fid",
    loading_data: "Loading Data from server",
    lpId: "Car Num",
    no_data: "No Data",
    nothing: "There is no Data",
    clients: "Clients",
    name: "Name",
    report: "Reports",
    admin: "Admin",
    employees: "Employees"
};