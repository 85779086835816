import Axios, {AxiosResponse} from "axios";
import UrlManager from "../../Shared/UrlManager";
import {ROUTES} from "../../../Bootstrap/ROUTEs";
import {Report, ReportType, SearchDate} from "../Data/Types";

export default class ReportIO {
    public async fetchReportData(reportInfo: Report) {
        const url = UrlManager.getURL(ROUTES.FETCH_REPORT_DATA);
        return await Axios.post(url, reportInfo);
    }

    public async fetchDepartments(): Promise<[]> {
        const url = UrlManager.getURL(ROUTES.FETCH_DEPARTMENTS);
        return await Axios.get(url);
    }

    public initializeReport(): Report {
        return {
            fromDate:new Date(),
            toDate:new Date(),
            setFromDate: "",
            setToDate: "",
            depId: 0,
            softAbsent: false,
            details: true,
            time:false,
            dispatch: false,
            fullVacation: true,
            presidencyPaidVacation:false,
            presidencyUnPaidVacation:false,
            partVacation: false,
            absent: true,
            assign: false,
            late: false,
            present: true,
            reportType: ReportType.DEPARTMENT,
            full:false,
            part:false,
            daily:false
        }
    }
}