import React, {Component} from 'react';
import UserIO from "./IO/UserIO";
import {
    AcademicCertificate,
    Attendance,
    AttendanceType,
    EmployeeType, Position,
    Vacation,
    VacationStatus,
    VacationType
} from "../Attandancees/Data/Tyeps";
import CloudLoader from "../Shared/Loading/CloudLoader";
import {langs} from "../../Bootstrap/Langs/Lang";
import {AxiosResponse} from "axios";
import Initializer from "../Shared/initializer";

interface Props {
    id: string;
}

interface State {
    loading: boolean;
    error: boolean;
    employee: Attendance;
    vacations: Vacation[];
    vacation: Vacation;
    OneDayVacation: boolean;
    MultiDayVacation: boolean;
    TimedVacation: boolean;
}

class RequestVacation extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const initializer = new Initializer();
        this._userIo = new UserIO();
        this.state = {
            MultiDayVacation: false, TimedVacation: false, OneDayVacation: false,
            employee: initializer.employee(),
            vacation: initializer.vacation(this.props.id), vacations: [], error: false, loading: true
        }
    }

    private _userIo: UserIO;

    async componentDidMount() {
        await this._userIo.fetchEmployeeWithVacations(this.props.id).then((res) => {
            this.setState({loading: false, error: false, employee: res.data.emp, vacations: res.data.vacations})

        }, (error) => {
            console.log(error);
            window.location.href = "/UserRequestLogin"

        })

        document.getElementById("day")?.setAttribute("min", this.state.vacation.fromDate.toISOString().slice(0, 10) + "");
        document.getElementById("from")?.setAttribute("min", this.state.vacation.fromDate.toISOString().slice(0, 10) + "");
    }

    submit = async () => {
        const fromTime: HTMLInputElement = (document.getElementById("fromTime")) as HTMLInputElement;
        const toTime: HTMLInputElement = document.getElementById("toTime") as HTMLInputElement;
        if (this.state.vacation.vacationType == VacationType.Part && (fromTime.value == '' || toTime.value == '')) {
            return;
        }
        this.setState({loading: true, error: false});
        try {
            const vacation: AxiosResponse<Vacation | boolean> = await this._userIo.RequestVacation(this.state.vacation);
            if(typeof  vacation.data === "boolean"){
                alert("الاجازات الزمنية تجاوزت الحد المسموح");
            }else{
                this.setState({vacations: [...this.state.vacations, vacation.data], loading: false, error: false});
            }
        } catch {
            alert("نظام الاجازات متوقف حاليا");
        }
        this.setState({loading: false, error: false});
    }

    setToDate = (value: number | undefined) => {
        if (value == undefined) {
            let x = document.getElementById("daysNum")?.getAttribute("value");
            if (x != null) {
                value = parseInt(x);

            } else {
                return;
            }
        }
        value--;
        this.state.vacation.toDate.setDate(this.state.vacation.fromDate.getDate());
        this.setState({
            vacation: this.state.vacation
        })
        this.state.vacation.toDate.setDate(this.state.vacation.fromDate.getDate() + value)
        this.setState({
            vacation: this.state.vacation
        })
    }

    setPartTime = (time: string, type: string) => {
        this.state.vacation.fromDate = new Date();
        this.state.vacation.toDate = new Date();
        let timeSplit = time.split(' ');
        timeSplit = timeSplit[0].split(':');

        if (type == "start") {
            this.state.vacation.setStartTime = (new Date().toLocaleDateString() + ' ' + timeSplit[0] + ':' + timeSplit[1]);
        } else {
            this.state.vacation.setEndTime = (new Date().toLocaleDateString() + ' ' + timeSplit[0] + ':' + timeSplit[1]);
        }
        this.setState({
            vacation: this.state.vacation
        })
    }

    VacToAr = (value: VacationStatus) => {
        switch (value) {
            case VacationStatus.PENDING_DEPARTMENT:
                return "بانتضار موافقة القسم";
            case VacationStatus.PENDING_ADMIN:
                return "بانتظار موافقة المدير";
            case VacationStatus.APPROVED_DEPARTMENT:
                return "تمت الموافقة من القسم";
            case VacationStatus.APPROVED_ADMIN:
                return "تمت الموافقة من المدير";
            case VacationStatus.REJECTED_DEPARTMENT:
                return "مرفوضه من القسم";
            case VacationStatus.REJECTED_ADMIN:
                return "مرفوضه من المدير";
            default:
                return "حالة غير معروفة";
        }
    }

    render() {
        return (
            <>
                {/*Request Vacation*/}
                <div className = "text-center">
                    {/*Employee Info*/}
                    <div className = "text-center text-xl font-bold py-4">
                        <h1>{this.state.employee.name} / {this.state.employee.depName}</h1>
                    </div>

                    {/*Choose Vacation Type*/}
                    <div dir = "rtl" className = "sm:flex-col sm:p-0 md:px-5 md:flex-row lg:flex-row text-center flex items-center justify-center gap-4">
                        {/*One Day Vacation BTN*/}
                        <button className = {"hover:bg-blue-700 w-4/5 text-white font-bold py-2 px-4 rounded " + (this.state.OneDayVacation ? "bg-blue-500" : "bg-gray-600")} onClick = {() => {
                            this.state.vacation.vacationType = VacationType.FUll;
                            this.setState({
                                OneDayVacation: !this.state.OneDayVacation,
                                MultiDayVacation: false,
                                TimedVacation: false,
                                vacation: this.state.vacation
                            })
                        }}>
                            اجازة يوم واحد
                        </button>

                        {/*Multi Day Vacation BTN*/}
                        <button className = {"hover:bg-blue-700 w-4/5 text-white font-bold py-2 px-4 rounded " + (this.state.MultiDayVacation ? "bg-blue-500" : "bg-gray-600")} onClick = {() => {
                            this.state.vacation.vacationType = VacationType.FUll;
                            this.setState({
                                OneDayVacation: false,
                                MultiDayVacation: !this.state.MultiDayVacation,
                                TimedVacation: false,
                                vacation: this.state.vacation
                            })
                        }}>
                            اجازة لاكثر من يوم
                        </button>

                        {/*Part Day Vacation BTN*/}
                        <button className = {"hover:bg-blue-700 w-4/5 text-white font-bold py-2 px-4 rounded " + (this.state.TimedVacation ? "bg-blue-500" : "bg-gray-600")} onClick = {() => {
                            this.state.vacation.vacationType = VacationType.Part;
                            this.setState({
                                OneDayVacation: false,
                                MultiDayVacation: false,
                                TimedVacation: !this.state.TimedVacation,
                                vacation: this.state.vacation
                            })
                        }}>
                            اجازة زمنية
                        </button>
                    </div>

                    {/*Vacation Info*/}
                    <div dir = "rtl" className = "text-center">
                        {/*One Day Vacation Info*/}
                        <div hidden = {!this.state.OneDayVacation}>
                            <div className = "grid grid-cols-1 gap-4 py-8 sm:w-4/5 md:w-1/2 lg:w-1/3 m-auto px-8 border border-gray-400 rounded my-5 shadow">
                                <h3>تقديم اجازة ليوم واحد</h3>
                                <div className = "grid grid-cols-2 items-center">
                                    <label htmlFor = "reason1">السبب</label>
                                    <input id = "reason1" className = "p-3 rounded shadow" defaultValue = {this.state.vacation.resion} onChange = {(e) => {
                                        this.state.vacation.resion = e.target.value;
                                        this.setState({
                                            vacation: this.state.vacation
                                        })
                                    }} />
                                </div>
                                <div className = "grid grid-cols-2 items-center">
                                    <label htmlFor = "day">يوم الاجازة</label>
                                    <input id = "day" className = "p-3 sm:text-xs md:text-sm rounded shadow" type = "date" defaultValue = {this.state.vacation.fromDate.toISOString().slice(0, 10)} onChange = {(e) => {
                                        this.state.vacation.fromDate = new Date(e.target.value);
                                        this.state.vacation.toDate = new Date(e.target.value);
                                        this.setState({
                                            vacation: this.state.vacation
                                        })
                                        // this.state.vacation.toDate.setDate(this.state.vacation.fromDate.getDate() +1)
                                        // this.setState({
                                        //     vacation: this.state.vacation
                                        // })
                                    }} />
                                </div>
                            </div>
                        </div>

                        {/*Multi Day Vacation Info*/}
                        <div hidden = {!this.state.MultiDayVacation}>
                            <div className = "grid grid-cols-1 gap-4 py-8 sm:w-4/5 md:w-1/2 lg:w-1/3 m-auto px-8 border border-gray-400 rounded my-5 shadow">
                                <h3>تقديم اجازة لاكثر من يوم</h3>
                                <div className = "grid grid-cols-2 items-center">
                                    <label htmlFor = "reason2">السبب</label>
                                    <input id = "reason2" className = "p-3 rounded shadow" defaultValue = {this.state.vacation.resion} onChange = {(e) => {
                                        this.state.vacation.resion = e.target.value;
                                        this.setState({
                                            vacation: this.state.vacation
                                        })
                                    }} />
                                </div>
                                <div className = "grid grid-cols-2 items-center">
                                    <label htmlFor = "day">تاريخ الاجازة</label>
                                    <input id = "from" className = "p-3 sm:text-xs md:text-sm rounded shadow" type = "date" defaultValue = {this.state.vacation.fromDate.toISOString().slice(0, 10)} onChange = {(e) => {
                                        this.state.vacation.fromDate = new Date(e.target.value);
                                        this.setState({
                                            vacation: this.state.vacation
                                        })
                                        this.setToDate(undefined);
                                    }} />
                                </div>
                                <div className = "grid grid-cols-2 items-center">
                                    <label htmlFor = "daysNum">عدد الايام</label>
                                    <input id = "daysNum" className = "p-3 sm:text-xs md:text-sm rounded shadow" type = "number" defaultValue = "2" min = "2" onChange = {(e) => {
                                        this.setToDate(parseInt(e.target.value))
                                    }} />
                                </div>
                            </div>
                        </div>

                        {/*Part Day Vacation Info*/}
                        <div hidden = {!this.state.TimedVacation}>
                            <div className = "grid grid-cols-1 gap-4 py-8 sm:w-4/5 md:w-1/2 lg:w-1/3 m-auto px-8 border border-gray-400 rounded my-5 shadow">
                                <h3>تقديم اجازة زمنية</h3>
                                <div className = "grid grid-cols-2 items-center">
                                    <label htmlFor = "reason3">السبب</label>
                                    <input id = "reason3" className = "p-3 rounded shadow" defaultValue = {this.state.vacation.resion} onChange = {(e) => {
                                        this.state.vacation.resion = e.target.value;
                                        this.setState({
                                            vacation: this.state.vacation
                                        })
                                    }} />
                                </div>
                                <div className = "grid grid-cols-2 items-center">
                                    <label htmlFor = "fromTime">من</label>
                                    <input id = "fromTime" className = "p-3 sm:text-xs md:text-sm rounded shadow" type = "time" onChange = {(e) => this.setPartTime(e.target.value, "start")} />
                                </div>
                                <div className = "grid grid-cols-2 items-center">
                                    <label htmlFor = "toTime">الى</label>
                                    <input id = "toTime" className = "p-3 sm:text-xs md:text-sm rounded shadow" type = "time" onChange = {(e) => this.setPartTime(e.target.value, "end")} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Submit BTN*/}
                    {(this.state.OneDayVacation || this.state.MultiDayVacation || this.state.TimedVacation) &&
                        <div>
                            <button disabled = {this.state.loading}
                                    className = "bg-blue-500 sm:w-4/5 md:w-1/2 lg:w-1/3 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    onClick = {this.submit}
                            >ارسال
                            </button>
                        </div>
                    }
                </div>

                {/*Horizontal Divider*/}
                <div className = "border-2 my-10 border-gray-300"></div>

                {/*Employee Vacations*/}
                <div>
                    {this.state.loading &&
                        <CloudLoader title = {langs.loading_data} className = "w-1/6 mx-auto text-center" />
                    }

                    {!this.state.loading &&
                        <div className = "text-center">
                            <h3 className = "text-2xl font-bold border-b pb-3">الاجازات</h3>
                            <table className = "table-fixed w-full text-center sm:text-xs md:text-sm lg:text-sm" dir = "rtl">
                                <thead>
                                <tr>
                                    <th className = "w-1/12 px-4 py-2 text-center">{langs.index}</th>
                                    <th className = "w-1/6 px-4 py-2 text-center">{"نوع الاجازة"}</th>
                                    <th className = "w-1/6 px-4 py-2 text-center">{"من"}</th>
                                    <th className = "w-1/6 px-4 py-2 text-center">{"الى"}</th>
                                    <th className = "w-1/6 px-4 py-2 text-center">{"اليوم"}</th>
                                    <th className = "w-1/6 px-4 py-2 text-center">{"الحالة"}</th>
                                </tr>
                                </thead>

                                <tbody>
                                {this.state.vacations.length > 0 && this.state.vacations.map((value, index) => {
                                    return (
                                        <tr key = {index} className = {" cursor-pointer text-right hover:bg-blue-200 hover:font-bold " + (index % 2 === 0 ? "bg-gray-100" : "")}>
                                            <td className = "px-4 py-2  text-center  ">{index + 1}</td>

                                            {value.vacationType == VacationType.FUll &&
                                                <td className = "px-4 py-2 text-center ">{"اجازة يومية"}</td>
                                            } {value.vacationType == VacationType.Part &&
                                            <td className = "px-4 py-2 text-center">{"اجازة زمنية"}</td>
                                        }


                                            {value.vacationType == VacationType.FUll &&
                                                <>
                                                    <td className = "px-4 py-2 text-center">{value.fromDate != null && (new Date(value.fromDate).toLocaleDateString('en-GB'))}</td>
                                                    <td className = "px-4 py-2 text-center">{value.toDate != null && (new Date(value.toDate).toLocaleDateString('en-GB'))}</td>

                                                </>
                                            } {value.vacationType == VacationType.Part &&
                                            <>
                                                <td className = "px-4 py-2 text-center">{value.startTime != null && (new Date(value.startTime).toLocaleTimeString())}</td>
                                                <td className = "px-4 py-2 text-center">{value.endTime != null && (new Date(value.endTime).toLocaleTimeString())}</td>

                                            </>
                                        }

                                            <td className = "px-4 py-2 text-center">{(new Date(value.fromDate).toLocaleDateString('en-GB'))} </td>
                                            <td className = "px-4 py-2 text-center">{this.VacToAr(value.vacationStatus)} </td>

                                        </tr>
                                    )
                                })
                                }
                                </tbody>
                            </table>
                        </div>
                    }

                    {this.state.vacations.length === 0 && !this.state.loading &&
                        <div className = "bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role = "alert">
                            <p className = "text-sm text-center">{langs.no_data}</p>
                        </div>
                    }

                </div>
            </>
        );
    }
}

export default RequestVacation;