export interface AppConfig {
    base_url: string;
}


const ApplicationConfiguration: AppConfig = {
    //base_url: "http://localhost:5000"
    base_url: ""
};

export default ApplicationConfiguration;