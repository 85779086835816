import React, {Component} from 'react';
import {langs} from "../../Bootstrap/Langs/Lang";
import {AuthInfo, Login} from "../Login/Data/Types";
import {AxiosResponse} from "axios";
import {LoginAction} from "../Login/Data/Actions/Actions";
import UserIO from "./IO/UserIO";
import {Attendance} from "../Attandancees/Data/Tyeps";
import {withRouter} from "react-router";
interface  Props{
    history: any;
}
interface State{
    loading: boolean;
    error: boolean;
    pin:string;
}
class UserLoginComponent extends Component<Props , State> {
    constructor(props:Props) {
        super(props);
        this .state ={
            pin: "",
            error: false, loading: false
        };
        this._userIo = new UserIO();
    }
    private  _userIo:UserIO;
    pin = (event) => {
        this.setState({loading: false, pin: event.target.value, error: false});
    };
    submit = async (event) => {

        event.preventDefault();
        if (this.state.pin === '')
            return;



        this.setState({
            loading: true,
            error:false
        });
        let response: AxiosResponse<Attendance>;
        try {
            response = await this._userIo.fetchEmployee(this.state.pin);
            if (response.data != null){
                this.setState({loading: false});
                window.location.href = "/RequestVacation/"+ this.state.pin;
             }else {
                this.setState({loading: false ,error:true});
            }


        } catch (e) {
            this.setState({loading: false, error: true});
            return;

        }
    };
    render() {
        return (
            <div
                className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 text-right">

                <div className="max-w-md w-full">
                    <div>
                        <img className="mx-auto h-12 w-auto"
                             src="/icon.png" alt="Workflow"/>
                        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                            {langs.login}
                        </h2>

                    </div>
                    <form className="mt-8">
                        <input type="hidden" name="remember" value="true"/>
                        <div className="rounded-md shadow-sm">
                            <div>
                                <input dir='rtl' disabled={this.state.loading} onChange={this.pin}
                                       aria-label="Email address" name="email"
                                       type="text" required
                                       className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                                       placeholder={langs.id}/>
                            </div>

                        </div>

                        {this.state.error &&
                            <div className="mt-6  justify-between">

                                <div className="text-sm leading-5 text-right text-red-700">
                                    المستخدم غير موجود
                                </div>
                            </div>
                        }


                        <div className="mt-6">
                            <button disabled={this.state.loading} type="submit"
                                    onClick={this.submit}
                                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                            <svg
                                                className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150"
                                                fill="currentColor" viewBox="0 0 20 20">
                                              <path fill-rule="evenodd"
                                                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                                    clip-rule="evenodd"/>
                                            </svg>
                                          </span>
                                {langs.login}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

}

export default withRouter(UserLoginComponent);