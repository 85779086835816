import {AttendanceType, Vacation, VacationStatus, VacationType} from "../Attandancees/Data/Tyeps";
import {Shift} from "../Admin/Data/Types";

export default class EnumTranslate {
    public GetAttendanceTypeAr(attendanceType: AttendanceType):string {
        switch (attendanceType) {
            case AttendanceType.Present:
                return "حاضر";
            case AttendanceType.Vacation:
                return "مجاز";
            case AttendanceType.Presidency_Vacation_Paid:
                return "مجاز(امر براتب)";
            case AttendanceType.Presidency_Vacation_UnPaid:
                return "مجاز(امر بدون راتب)";
            case AttendanceType.Absence:
                return "غياب";
            case AttendanceType.Late:
                return "متاخر";
            case AttendanceType.Night:
                return "مسائي";
            case AttendanceType.Free:
                return "تفرغ";
            case AttendanceType.Assign:
                return "تكليف";
            case AttendanceType.Dispatch:
                return "ايفاد";
            case AttendanceType.SoftAbsence:
                return "قطع يوم";
            default:
                return "نوع غير معروف";
        }
    }

    public GetAttendanceTypeColor(attendanceType: AttendanceType):string{
        switch (attendanceType) {
            case AttendanceType.Present:
                return "green";
            case AttendanceType.Vacation:
                return "gray";
            case AttendanceType.Presidency_Vacation_Paid:
                return "gray";
            case AttendanceType.Presidency_Vacation_UnPaid:
                return "gray";
            case AttendanceType.Assign:
                return "gray";
            case AttendanceType.Dispatch:
                return "gray";
            case AttendanceType.Absence:
                return "crimson";
            case AttendanceType.Free:
                return "darkseagreen";
            case AttendanceType.Late:
                return "darkgoldenrod";
            case AttendanceType.Night:
                return "black";
            case AttendanceType.SoftAbsence:
                return "blue";
            default:
                return "";
        }
    }

    public GetShiftAr(shift: Shift):string {
        switch (shift) {
            case Shift.DayShift:
                return "صباحي";
            case Shift.EveningShift:
                return "مسائي";
            case Shift.NighShift:
                return "ليلي";
            case Shift.Free:
                return "تفررغ";
            default:
                return "نوع غير معروف";
        }
    }

    public GetVacationStatusAr(vacationStatus: VacationStatus):string{
        switch (vacationStatus) {
            case VacationStatus.PENDING_DEPARTMENT:
                return "بانتضار القسم";
            case VacationStatus.PENDING_ADMIN:
                return "بانتظار المدير";
            case VacationStatus.PENDING_PRESIDENCY:
                return "بانتظار الامر الجامعي";
            case VacationStatus.APPROVED_DEPARTMENT:
                return "موافقة القسم";
            case VacationStatus.APPROVED_ADMIN:
                return "موافقة المدير";
            case VacationStatus.APPROVED_PRESIDENCY_PAID:
                return "موافقة الامر الجامعي(براتب)";
            case VacationStatus.APPROVED_PRESIDENCY_UNPAID:
                return "موافقة الامر الجامعي(بدون براتب)";
            case VacationStatus.REJECTED_DEPARTMENT:
                return "رفض القسم";
            case VacationStatus.REJECTED_ADMIN:
                return "رفض المدير";
            case VacationStatus.REJECTED_PRESIDENCY:
                return "رفض الامر الجامعي";
            default:
                return "حالة غير معروفة";
        }
    }

    public GetVacationTypeArByType(vacationType:VacationType):string{
        switch (vacationType) {
            case VacationType.FUll:
                return "يومية";
            case VacationType.Part:
                return "زمنية";
            case VacationType.Assign:
                return "تكليف";
            case VacationType.Dispatch:
                return "ايفاد";
            default:
                return "نوع غير معروف";
        }
    }

    public GetVacationTypeArByVacation(vacation: Vacation):string{
        if (vacation.vacationType == VacationType.FUll) {
            if (vacation.paid) {
                return "يومية (مدفوعة)";
            } else {
                return "يومية";
            }
        }
        if (vacation.vacationType == VacationType.Part) {
            return "زمنية";
        }
        if (vacation.vacationType == VacationType.Assign) {
            return "تكليف";
        }
        if (vacation.vacationType == VacationType.Dispatch) {
            return "ايفاد";
        }
        return "نوع غير معروف";
    }
}