import React, {Component} from 'react';
import Route from "react-router-dom/Route";
import {AuthInfo} from "./App/Login/Data/Types";
import LoginComponent from "./App/Login/LoginComponent";
import {connect} from 'react-redux';
import {Store} from "./Bootstrap/Store";
import {Layout} from "./App/Shared/Layout";
import {Langauge} from "./Bootstrap/Langs/Data/Types";
import Lang from "./Bootstrap/Langs/Lang";
import {axiosHook} from "./Bootstrap/AxiosHook";
import ContenerMainComponent from "./App/Attandancees/AttandanceMainComponent";
import EmployeeAttendanceComponent from "./App/Attandancees/UI/EmployeeAttendanceComponent";
import DepartmentsComponents from "./App/Attandancees/UI/DepartmentsComponents";
import VacationComponent from "./App/Attandancees/UI/VacationComponent";
import VacationDisplayComponent from "./App/Attandancees/UI/VacationDisplayComponent";
import Vacations from "./App/Attandancees/UI/Vacations";
import CreateEmployee from "./App/Attandancees/UI/CreateEmployee"
import Report from "./App/Reports/ReportMainComponent"
import Admin from "./App/Admin/AdminMainComponent"
import UserLoginComponent from "./App/Users/UserLoginComponent";
import RequestVacation from  "./App/Users/RequestVacation";

interface Props {
    authInfo: AuthInfo;
    changeLangReducer: Langauge;
    match: any;

}

interface State {
    authorized: boolean;
}

class Main extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {authorized: false};
    }

    checkExpire(date1: string): boolean {
        var authDate = Date.parse(date1);

        var mydate = new Date().getDate();

        if (authDate > mydate) {
            return false
        } else {
            return true
        }

    }


    render() {
        return (

            <>

                {(window.location.href).includes("VacationDisplay") && <Route exact path={"/VacationDisplay/:id/"}
                                                                              component={
                                                                                  ({match}) => <VacationDisplayComponent
                                                                                      id={match.params["id"]}
                                                                                  />
                                                                              }/>
                }

                {(window.location.href).includes("UserRequestLogin") &&
                    <UserLoginComponent/>
                }
                 {(window.location.href).includes("RequestVacation") && <Route exact path={"/RequestVacation/:id/"}
                                                                              component={
                                                                                  ({match}) => <RequestVacation
                                                                                      id={match.params["id"]}
                                                                                  />
                                                                              }/>
                }


                <Lang/>
                {(!(window.location.href).includes("VacationDisplay") &&
                        !(window.location.href).includes("UserRequestLogin") &&
                        !(window.location.href).includes("RequestVacation")
                    ) &&
                    this.checkExpire(this.props.authInfo.expirationDate) && <LoginComponent/>
                }


                {!(window.location.href).includes("VacationDisplay") && !this.checkExpire(this.props.authInfo.expirationDate) &&
                    <Layout>

                        <Route path="/" exact component={DepartmentsComponents}/>
                        <Route path="/Attendance" exact component={ContenerMainComponent}/>
                        <Route path="/Vacations" exact component={Vacations}/>
                        <Route path="/createEmployee" exact component={CreateEmployee}/>
                        <Route path="/Report" exact component={Report}/>
                        <Route path="/Admin" exact component={Admin}/>

                        {/*<Route path="/create" exact component={CreateContenerComponent}/>*/}

                        <Route exact path={"/Employee/:id/"}
                               component={({match}) => <EmployeeAttendanceComponent
                                   id={match.params["id"]}
                               />}/>

                        <Route exact path={"/Vacation/:id/"}
                               component={({match}) => <VacationComponent
                                   id={match.params["id"]}
                               />}/>

                    </Layout>}
                {axiosHook()}
            </>


        );
    }
}

export default connect((store: Store) => {
    return ({
        authInfo: store.LoginReducer,
        changeLangReducer: store.ChangeLangReducer
    });

})(Main);