import Axios, {AxiosResponse} from "axios";
import UrlManager from "../../Shared/UrlManager";
import {ROUTES} from "../../../Bootstrap/ROUTEs";
import {Config, Dates, UpdateAttendanceStatus} from "../Data/Types";

export default class AdminIo {
    public async updateConfig(config: Config):Promise<AxiosResponse<Config>> {
        const url = UrlManager.getURL(ROUTES.UPDATE_CONFIG);
        return await Axios.post(url, config);
    }

    public async deleteAttendances(dates:Dates):Promise<AxiosResponse<number>> {
        const url = UrlManager.getURL(ROUTES.DELETE_EMPLOYEE_ATTENDANCE);
        return await Axios.post(url, dates);
    }

    public async updateAttendances(updateAttendanceStatus:UpdateAttendanceStatus):Promise<AxiosResponse<number>> {
        const url = UrlManager.getURL(ROUTES.UPDATE_EMPLOYEE_ATTENDANCES);
        return await Axios.post(url, updateAttendanceStatus);
    }

    public async fetchConfig():Promise<AxiosResponse<Config>> {
        const url = UrlManager.getURL(ROUTES.FETCH_CONFIG);
        return await Axios.get(url);
    }
}