import React, {Component} from 'react';
import {
    AcademicCertificate,
    Attendance,
    AttendanceType,
    EmployeeAttendance,
    EmployeeAttendanceInfo,
    EmployeeAttendancePanel,
    EmployeeAttendancePanelType,
    EmployeeNote,
    EmployeeNoteType,
    Position
} from "../Data/Tyeps";
import {withRouter} from "react-router";
import CloudLoader from "../../Shared/Loading/CloudLoader";
import {langs} from "../../../Bootstrap/Langs/Lang";
import AttendanceIO from "../IO/AttendanceIO";
import Axios, {AxiosResponse} from "axios";
import {Days, UserType} from "../../Shared/Types";
import ReactApexChart from "react-apexcharts";
import {AuthInfo} from "../../Login/Data/Types";
import {Store} from "../../../Bootstrap/Store";
import {connect} from "react-redux";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UrlManager from "../../Shared/UrlManager";
import {ROUTES} from "../../../Bootstrap/ROUTEs";
import ApplicationConfiguration from "../../../Bootstrap/Config";
import EnumTranslate from "../../Shared/enumTranslate";
import Initializer from "../../Shared/initializer";
import {Shift} from "../../Admin/Data/Types";

interface Props {
    history: any;
    id: string;
    authInfo: AuthInfo;
}

interface State {
    loading: boolean;
    loadNotes: boolean;
    Attendance: EmployeeAttendanceInfo;
    error: boolean;
    data: number[];
    edit: boolean;
    employeeAttendance: EmployeeAttendance;
    search: string;
    department: string;
    employeeAttendancePanel: EmployeeAttendancePanel;
    employeeNotes: Array<EmployeeNote>;
    employeeNote: EmployeeNote;
    createNote: boolean;
}

class EmployeeAttendanceComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const initializer = new Initializer();
        this.state = {
            loadNotes: false,
            createNote: false,
            employeeNotes: [],
            employeeAttendancePanel: {
                status: true,
                attendance: false,
                notes: false,
                remainderNotes: true,
                bookNotes: false,
            },
            data: [3, 5],
            error: false,
            loading: true,
            edit: false,
            search: "",
            department: "",
            employeeNote: initializer.employeeNote(),
            Attendance: initializer.attendance(),
            employeeAttendance: initializer.employeeAttendance()
        };
        this._attendanceIo = new AttendanceIO();
        this._enumTranslate = new EnumTranslate();
    }

    private _attendanceIo: AttendanceIO;
    private _enumTranslate: EnumTranslate;

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        this.setState({error: false, loading: true});
        try {
            const response: AxiosResponse<EmployeeAttendanceInfo> = await this._attendanceIo.fetchByEmpId(this.props.id);
            const depRes = await this._attendanceIo.fetchDepartment();
            const depName = depRes.data.filter(dep => dep.id == response.data.employee.depId)[0].title;
            this.setState({error: false, loading: false, Attendance: response.data, department: depName});
        } catch (e) {
            this.setState({loading: false, error: true});
        }
    }

    changeDayShift = (day: Days) => {
        //console.log(day)
        switch (day) {
            case Days.D1:
                this.state.Attendance.employee.d1 = !this.state.Attendance.employee.d1;
                this.state.Attendance.employee.e1 = false;
                this.state.Attendance.employee.n1 = false;
                break;
            case Days.D2:
                this.state.Attendance.employee.d2 = !this.state.Attendance.employee.d2;
                this.state.Attendance.employee.e2 = false;
                this.state.Attendance.employee.n2 = false;
                break;
            case Days.D3:
                this.state.Attendance.employee.d3 = !this.state.Attendance.employee.d3;
                this.state.Attendance.employee.e3 = false;
                this.state.Attendance.employee.n3 = false;
                break;
            case Days.D4:
                this.state.Attendance.employee.d4 = !this.state.Attendance.employee.d4;
                this.state.Attendance.employee.e4 = false;
                this.state.Attendance.employee.n4 = false;
                break;
            case Days.D5:
                this.state.Attendance.employee.d5 = !this.state.Attendance.employee.d5;
                this.state.Attendance.employee.e5 = false;
                this.state.Attendance.employee.n5 = false;
                break;
            case Days.D6:
                this.state.Attendance.employee.d6 = !this.state.Attendance.employee.d6;
                this.state.Attendance.employee.e6 = false;
                this.state.Attendance.employee.n6 = false;
                break;
            case Days.D7:
                this.state.Attendance.employee.d7 = !this.state.Attendance.employee.d7;
                this.state.Attendance.employee.e7 = false;
                this.state.Attendance.employee.n7 = false;
                break;
            case Days.E1:
                this.state.Attendance.employee.d1 = false;
                this.state.Attendance.employee.e1 = !this.state.Attendance.employee.e1;
                this.state.Attendance.employee.n1 = false;
                break;
            case Days.E2:
                this.state.Attendance.employee.d2 = false;
                this.state.Attendance.employee.e2 = !this.state.Attendance.employee.e2;
                this.state.Attendance.employee.n2 = false;
                break;
            case Days.E3:
                this.state.Attendance.employee.d3 = false;
                this.state.Attendance.employee.e3 = !this.state.Attendance.employee.e3;
                this.state.Attendance.employee.n3 = false;
                break;
            case Days.E4:
                this.state.Attendance.employee.d4 = false;
                this.state.Attendance.employee.e4 = !this.state.Attendance.employee.e4;
                this.state.Attendance.employee.n4 = false;
                break;
            case Days.E5:
                this.state.Attendance.employee.d5 = false;
                this.state.Attendance.employee.e5 = !this.state.Attendance.employee.e5;
                this.state.Attendance.employee.n5 = false;
                break;
            case Days.E6:
                this.state.Attendance.employee.d6 = false;
                this.state.Attendance.employee.e6 = !this.state.Attendance.employee.e6;
                this.state.Attendance.employee.n6 = false;
                break;
            case Days.E7:
                this.state.Attendance.employee.d7 = false;
                this.state.Attendance.employee.e7 = !this.state.Attendance.employee.e7;
                this.state.Attendance.employee.n7 = false;
                break;
            case Days.N1:
                this.state.Attendance.employee.d1 = false;
                this.state.Attendance.employee.e1 = false;
                this.state.Attendance.employee.n1 = !this.state.Attendance.employee.n1;
                break;
            case Days.N2:
                this.state.Attendance.employee.d2 = false;
                this.state.Attendance.employee.e2 = false;
                this.state.Attendance.employee.n2 = !this.state.Attendance.employee.n2;
                break;
            case Days.N3:
                this.state.Attendance.employee.d3 = false;
                this.state.Attendance.employee.e3 = false;
                this.state.Attendance.employee.n3 = !this.state.Attendance.employee.n3;
                break;
            case Days.N4:
                this.state.Attendance.employee.d4 = false;
                this.state.Attendance.employee.e4 = false;
                this.state.Attendance.employee.n4 = !this.state.Attendance.employee.n4;
                break;
            case Days.N5:
                this.state.Attendance.employee.d5 = false;
                this.state.Attendance.employee.e5 = false;
                this.state.Attendance.employee.n5 = !this.state.Attendance.employee.n5;
                break;
            case Days.N6:
                this.state.Attendance.employee.d6 = false;
                this.state.Attendance.employee.e6 = false;
                this.state.Attendance.employee.n6 = !this.state.Attendance.employee.n6;
                break;
            case Days.N7:
                this.state.Attendance.employee.d7 = false;
                this.state.Attendance.employee.e7 = false;
                this.state.Attendance.employee.n7 = !this.state.Attendance.employee.n7;
                break;
        }
        try {
            this.setState({loading: true, Attendance: this.state.Attendance});
            this.updateEmp(this.state.Attendance.employee).then(value => {
                this.state.Attendance.employee = value;
                this.setState({Attendance: this.state.Attendance, loading: false})
            });
        } catch {
            this.setState({loading: false, error: true})
        }
    }

    updateEmp = async (emp: Attendance): Promise<Attendance> => {
        if (this.props.authInfo.depId > 0) {
            await this.loadData();
        }
        const data = await this._attendanceIo.updateEmp(emp);
        return data.data;
    }

    getTimeIn24Format = (time: string): string => {
        const h_m = time.split(' ')[0].split(':');
        const am_pm = time.split(' ')[1];

        let h = parseInt(h_m[0]).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        })
        const m = parseInt(h_m[1]).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
        if (am_pm == "PM" && (parseInt(h)) < 12) {
            h = (parseInt(h) + 12).toString();
        }
        return h + ':' + m
    }

    editAttendance = (rowIndex: number, attendanceId: number, shift:Shift) => {
        const editWindow = document.getElementById("editWindow") as HTMLDivElement;
        const newTimeIn = document.getElementById("dateIn") as HTMLInputElement;
        const newTimeOut = document.getElementById("dateOut") as HTMLInputElement;
        const newAttendanceType = document.getElementById("attendanceType") as HTMLSelectElement;
        const newShift = document.getElementById("shift") as HTMLSelectElement;
        const dateLabel = document.getElementById("date") as HTMLLabelElement;

        const currentTimeIn = document.getElementById("dateIn-" + rowIndex) as HTMLTableColElement;
        const currentTimeOut = document.getElementById("dateOut-" + rowIndex) as HTMLTableColElement;
        const currentAttendanceType = document.getElementById("attendanceType-" + rowIndex) as HTMLParagraphElement;
        const currentAttendanceId = document.getElementById("attendanceId") as HTMLParagraphElement;
        const date = document.getElementById("date-" + rowIndex) as HTMLTableColElement;

        editWindow.classList.add("flex");

        newTimeIn.value = this.getTimeIn24Format(currentTimeIn.innerText);
        newTimeOut.value = this.getTimeIn24Format(currentTimeOut.innerText);
        newAttendanceType.selectedIndex = parseInt(currentAttendanceType.innerText);
        newShift.selectedIndex = shift;

        dateLabel.innerText = date.innerText;
        currentAttendanceId.innerText = attendanceId.toString();
    }

    submitEdit = async () => {
        const newDateIn = document.getElementById("dateIn") as HTMLInputElement;
        const newDateOut = document.getElementById("dateOut") as HTMLInputElement;
        const newAttendanceType = document.getElementById("attendanceType") as HTMLSelectElement;
        const currentShift = document.getElementById("shift") as HTMLSelectElement;
        const currentAttendanceId = document.getElementById("attendanceId") as HTMLParagraphElement;
        const date = document.getElementById("date") as HTMLLabelElement;

        this.state.employeeAttendance.type = newAttendanceType.selectedIndex;
        this.state.employeeAttendance.shift = currentShift.selectedIndex;
        this.state.employeeAttendance.id = parseInt(currentAttendanceId.innerText);
        if (newDateIn.value) {
            this.state.employeeAttendance.receivedDateIn = new Date(date.innerText).toLocaleDateString() + " " + newDateIn.value;
        } else {
            this.state.employeeAttendance.receivedDateIn = null;
        }
        if (newDateOut.value) {
            this.state.employeeAttendance.receivedDateOut = new Date(date.innerText).toLocaleDateString() + " " + newDateOut.value;
        } else {
            this.state.employeeAttendance.receivedDateOut = null;
        }

        this.setState({employeeAttendance: this.state.employeeAttendance, loading: true});

        try {
            const res = await this._attendanceIo.updateAttendance(this.state.employeeAttendance);
            await this.loadData();
        } catch {
            this.setState({loading: false, error: true})
        }
    }

    cancelEdit = () => {
        const editWindow = document.getElementById("editWindow") as HTMLDivElement;
        const currentAttendanceId = document.getElementById("attendanceId") as HTMLParagraphElement;

        editWindow.classList.remove("flex");
        currentAttendanceId.innerText = "";
    }

    deleteAttendance = async (rowIndex: number, attendanceId: number) => {
        this.setState({loading: true, error: false});
        try {
            const res = await this._attendanceIo.deleteAttendance(attendanceId);
            await this.loadData();
        } catch {
            this.setState({loading: false, error: true});
        }
    }

    changePanel = async (employeeAttendancePanelType: EmployeeAttendancePanelType) => {
        switch (employeeAttendancePanelType) {
            case EmployeeAttendancePanelType.STATUS:
                this.setState({
                    employeeAttendancePanel: {
                        status: true,
                        attendance: false,
                        notes: false,
                        remainderNotes: true,
                        bookNotes: false,
                    }
                });
                break;
            case EmployeeAttendancePanelType.ATTENDANCE:
                this.setState({
                    employeeAttendancePanel: {
                        status: false,
                        attendance: true,
                        notes: false,
                        remainderNotes: true,
                        bookNotes: false,
                    }
                });
                break;
            case EmployeeAttendancePanelType.NOTES:
                if (!this.state.loadNotes) {
                    this.setState({loading: true, error: false});
                    try {
                        const notes = await this._attendanceIo.fetchEmployeeNotes(this.state.Attendance.employee.id);
                        this.setState({employeeNotes: notes.data, loading: false, error: false, loadNotes: true});
                    } catch {
                        this.setState({loading: false, error: true});
                    }
                }
                this.setState({
                    employeeAttendancePanel: {
                        status: false,
                        attendance: false,
                        notes: true,
                        remainderNotes: true,
                        bookNotes: false,
                    }
                });
                break;
            case EmployeeAttendancePanelType.REMAINDER_NOTES:
                this.setState({
                    employeeAttendancePanel: {
                        status: false,
                        attendance: false,
                        notes: true,
                        remainderNotes: true,
                        bookNotes: false,
                    }
                });
                break;
            case EmployeeAttendancePanelType.BOOK_NOTES:
                this.setState({
                    employeeAttendancePanel: {
                        status: false,
                        attendance: false,
                        notes: true,
                        remainderNotes: false,
                        bookNotes: true,
                    }
                });
                break;
        }
    }

    validateFileType = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileName = event.target;
        const idxDot = fileName.value.lastIndexOf(".") + 1;
        const extFile = fileName.value.substr(idxDot, fileName.value.length).toLowerCase();
        if (extFile == "jpg" || extFile == "png" || extFile == "jpeg" || extFile == "heic") {
            this.setState({employeeNote: {...this.state.employeeNote, imageFile: fileName.files?.item(0)}})
        } else {
            alert("نوع الصورة غير مسموح به!");
            fileName.value = "";
        }
    }

    addNote = async () => {
        const eid = this.state.Attendance.employee.id;
        this.state.employeeNote.employeeId = eid.toString();
        this.setState({employeeNote: this.state.employeeNote});
        if (this.state.employeeNote.title == "" || this.state.employeeNote.description == "") {
            console.log("gdgdgdg");
            window.alert("يجب ملئ جميع الحقول!");
            return false;
        } else {
            try {
                const res = await this._attendanceIo.addEmployeeNotes(this.state.employeeNote);
                this.setState({employeeNotes: [res.data, ...this.state.employeeNotes]})
            } catch {

            }
        }
    }

    day = {
        display: "flex",
        gap: "5px",
        padding: "5px 20px",
        borderRadius: "5px",
        boxShadow: "0 0 5px -2px",
        cursor: "pointer",
    };

    render() {
        return (
            <div className = "text-sm">
                {this.state.loading &&
                    <CloudLoader title = {langs.loading_data} className = "w-1/6 mx-auto text-center" />
                }

                {!this.state.loading &&
                    <div className = "grid gap-4 sm:mb-16 md:mb-0">
                        {/*Panels*/}
                        <div className = "flex border-b border-blue-600 mb-5 sm:mr-0 md:mr-8 sm:text-xxs md:text-sm">
                            <p className = {"p-3 cursor-pointer hover:text-blue-600" + (this.state.employeeAttendancePanel.status ? " border-b-4 border-blue-600" : "")} onClick = {() => this.changePanel(EmployeeAttendancePanelType.STATUS)}>احصائيه</p>
                            <p className = {"p-3 cursor-pointer hover:text-blue-600" + (this.state.employeeAttendancePanel.attendance ? " border-b-4 border-blue-600" : "")} onClick = {() => this.changePanel(EmployeeAttendancePanelType.ATTENDANCE)}>الحضور</p>
                            <p className = {"p-3 cursor-pointer hover:text-blue-600" + (this.state.employeeAttendancePanel.notes ? " border-b-4 border-blue-600" : "")} onClick = {() => this.changePanel(EmployeeAttendancePanelType.NOTES)}>الملاحظات</p>
                        </div>

                        {/* STATUS */}
                        {this.state.employeeAttendancePanel.status &&
                            <>
                                {/*Employee Info*/}
                                <div className = "flex sm:flex-col md:flex-row sm:mr-0 md:mr-8 gap-4 sm:text-xxs md:text-sm">
                                    <div className = "grid gap-2 shadow md:w-6/12 bg-white rounded p-4">
                                        <div className = "grid grid-cols-2 items-center bg-gray-100 rounded border p-2">
                                            <p className = "text-sm">{this.state.Attendance.employee.name}</p>
                                            <div className = "flex gap-3 flex-row-reverse">
                                                <button className = "bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-8 border border-blue-500 hover:border-transparent rounded" onClick = {() => this.props.history.push("/Vacation/" + this.state.Attendance.employee.id)}>
                                                    اجازه
                                                </button>
                                            </div>
                                        </div>
                                        <div className = "grid grid-cols-2 row-gap-2 items-center bg-gray-100 rounded border p-2" style = {{gridTemplateColumns: "min-content auto"}}>
                                            <p className = "pl-4">{langs.id}</p>
                                            <p>{this.state.Attendance.employee.id}</p>
                                            <hr />
                                            <hr />
                                            <p className = "pl-4">{langs.department}</p>
                                            <p>{this.state.department}</p>
                                            <hr />
                                            <hr />
                                            <p className = "pl-4">{langs.Presence}</p>
                                            <p>{this.state.Attendance.presence}</p>
                                            <hr />
                                            <hr />
                                            <p className = "pl-4">{langs.Absence}</p>
                                            <p>{this.state.Attendance.absence}</p>
                                            <hr />
                                            <hr />
                                            <p className = "pl-4">{langs.vacations}</p>
                                            <p>{this.state.Attendance.vacation}</p>
                                        </div>
                                    </div>
                                    <div className = "shadow md:w-6/12 bg-white rounded">
                                        <ReactApexChart height = "300"
                                                        options = {
                                                            {
                                                                chart: {
                                                                    width: "100%",
                                                                    type: 'pie',
                                                                },
                                                                labels: [langs.Absence, langs.Presence, langs.vacation],
                                                                colors: ['#F44336', '#00F', '#eb9534'],
                                                                responsive: [{
                                                                    breakpoint: 480,
                                                                    options: {
                                                                        chart: {
                                                                            width: "100%"
                                                                        },
                                                                        legend: {
                                                                            position: 'right'
                                                                        }
                                                                    }
                                                                }]
                                                            }
                                                        }
                                                        series = {
                                                            [this.state.Attendance.absence, this.state.Attendance.presence, this.state.Attendance.vacation]
                                                        }
                                                        type = "donut"
                                        />

                                    </div>
                                </div>

                                {/*Shifts*/}
                                {this.props.authInfo.userType !== UserType.DEPARTMENT &&
                                    <div className = "grid grid-cols-1 row-gap-4 items-center bg-gray-100 rounded border p-2 sm:text-xxs md:text-sm sm:mr-0 md:mr-8">
                                        <p>ايام الدوام</p>
                                        <hr />
                                        <div className = "grid grid-cols-1 row-gap-4 items-center p-1">
                                            <p>الشفت الصباحي</p>
                                            <div className = "grid sm:grid-cols-4 md:grid-cols-3 lg:grid-cols-4 gap-1">
                                                <label htmlFor = {"d1"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"d1"} value = {"true"} checked = {this.state.Attendance.employee.d1} onChange = {() => this.changeDayShift(Days.D1)} /> السبت
                                                </label> <label htmlFor = {"d2"} style = {this.day}>
                                                <input type = {"checkbox"} id = {"d2"} value = {"true"} checked = {this.state.Attendance.employee.d2} onChange = {() => this.changeDayShift(Days.D2)} /> الاحد
                                            </label> <label htmlFor = {"d3"} style = {this.day}>
                                                <input type = {"checkbox"} id = {"d3"} value = {"true"} checked = {this.state.Attendance.employee.d3} onChange = {() => this.changeDayShift(Days.D3)} /> الاثنين
                                            </label> <label htmlFor = {"d4"} style = {this.day}>
                                                <input type = {"checkbox"} id = {"d4"} value = {"true"} checked = {this.state.Attendance.employee.d4} onChange = {() => this.changeDayShift(Days.D4)} /> الثلاثاء
                                            </label> <label htmlFor = {"d5"} style = {this.day}>
                                                <input type = {"checkbox"} id = {"d5"} value = {"true"} checked = {this.state.Attendance.employee.d5} onChange = {() => this.changeDayShift(Days.D5)} /> الاربعاء
                                            </label> <label htmlFor = {"d6"} style = {this.day}>
                                                <input type = {"checkbox"} id = {"d6"} value = {"true"} checked = {this.state.Attendance.employee.d6} onChange = {() => this.changeDayShift(Days.D6)} /> الخميس
                                            </label> <label htmlFor = {"d7"} style = {this.day}>
                                                <input type = {"checkbox"} id = {"d7"} value = {"true"} checked = {this.state.Attendance.employee.d7} onChange = {() => this.changeDayShift(Days.D7)} /> الجمعة
                                            </label>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className = "grid grid-cols-1 row-gap-4 items-center p-1">
                                            <p>الشفت المسائي</p>
                                            <div className = "grid sm:grid-cols-4 md:grid-cols-3 lg:grid-cols-4 gap-1">
                                                <label htmlFor = {"e1"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"e1"} value = {"true"}
                                                           checked = {this.state.Attendance.employee.e1}
                                                           onChange = {() => this.changeDayShift(Days.E1)} /> السبت
                                                </label>
                                                <label htmlFor = {"e2"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"e2"} value = {"true"}
                                                           checked = {this.state.Attendance.employee.e2}
                                                           onChange = {() => this.changeDayShift(Days.E2)} /> الاحد
                                                </label>
                                                <label htmlFor = {"e3"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"e3"} value = {"true"}
                                                           checked = {this.state.Attendance.employee.e3}
                                                           onChange = {() => this.changeDayShift(Days.E3)} /> الاثنين
                                                </label>
                                                <label htmlFor = {"e4"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"e4"} value = {"true"}
                                                           checked = {this.state.Attendance.employee.e4}
                                                           onChange = {() => this.changeDayShift(Days.E4)} /> الثلاثاء
                                                </label>
                                                <label htmlFor = {"e5"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"e5"} value = {"true"}
                                                           checked = {this.state.Attendance.employee.e5}
                                                           onChange = {() => this.changeDayShift(Days.E5)} /> الاربعاء
                                                </label>
                                                <label htmlFor = {"e6"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"e6"} value = {"true"}
                                                           checked = {this.state.Attendance.employee.e6}
                                                           onChange = {() => this.changeDayShift(Days.E6)} /> الخميس
                                                </label>
                                                <label htmlFor = {"e7"} style = {this.day}>
                                                    <input type = {"checkbox"} id = {"e7"} value = {"true"}
                                                           checked = {this.state.Attendance.employee.e7}
                                                           onChange = {() => this.changeDayShift(Days.E7)} /> الجمعة
                                                </label>
                                            </div>
                                        </div>
                                        <hr />
                                        {/*<div className = "grid grid-cols-1 row-gap-4 items-center p-1">
                                    <p>الشفت الليلي</p>
                                    <div className = "grid sm:grid-cols-4 md:grid-cols-3 lg:grid-cols-4 gap-1">
                                        <label htmlFor = {"n1"} style = {this.day}>
                                            <input type = {"checkbox"} id = {"n1"} value = {"true"}
                                                   checked = {this.state.Attendance.employee.n1}
                                                   onChange = {() => this.changeDayShift(Days.N1)} /> السبت </label>
                                        <label htmlFor = {"n2"} style = {this.day}>
                                            <input type = {"checkbox"} id = {"n2"} value = {"true"}
                                                   checked = {this.state.Attendance.employee.n2}
                                                   onChange = {() => this.changeDayShift(Days.N2)} /> الاحد </label>
                                        <label htmlFor = {"n3"} style = {this.day}>
                                            <input type = {"checkbox"} id = {"n3"} value = {"true"}
                                                   checked = {this.state.Attendance.employee.n3}
                                                   onChange = {() => this.changeDayShift(Days.N3)} /> الاثنين
                                        </label>
                                        <label htmlFor = {"n4"} style = {this.day}>
                                            <input type = {"checkbox"} id = {"n4"} value = {"true"}
                                                   checked = {this.state.Attendance.employee.n4}
                                                   onChange = {() => this.changeDayShift(Days.N4)} /> الثلاثاء
                                        </label>
                                        <label htmlFor = {"n5"} style = {this.day}>
                                            <input type = {"checkbox"} id = {"n5"} value = {"true"}
                                                   checked = {this.state.Attendance.employee.n5}
                                                   onChange = {() => this.changeDayShift(Days.N5)} /> الاربعاء
                                        </label>
                                        <label htmlFor = {"n6"} style = {this.day}>
                                            <input type = {"checkbox"} id = {"n6"} value = {"true"}
                                                   checked = {this.state.Attendance.employee.n6}
                                                   onChange = {() => this.changeDayShift(Days.N6)} /> الخميس
                                        </label>
                                        <label htmlFor = {"n7"} style = {this.day}>
                                            <input type = {"checkbox"} id = {"n7"} value = {"true"}
                                                   checked = {this.state.Attendance.employee.n7}
                                                   onChange = {() => this.changeDayShift(Days.N7)} /> الجمعة
                                        </label>
                                    </div>
                                </div>*/}
                                    </div>
                                }
                            </>
                        }

                        {/* ATTENDANCE */}
                        {this.state.employeeAttendancePanel.attendance &&
                            <>
                                {/*Attendance Table*/}
                                <div className = 'sm:m-0 sm:p-2 md:mr-8 md:p-4 bg-white overflow-hidden m-auto rounded shadow-xl'>
                                    <p id = "attendanceId" hidden = {true}></p>
                                    <table className = "table-fixed text-center w-full sm:text-xxs md:text-sm">
                                        <thead>
                                        <tr>
                                            <th className = "py-2">{langs.counter}</th>
                                            <th className = "py-2">{langs.date}</th>
                                            <th className = "py-2">{langs.TimeIn}</th>
                                            <th className = "py-2">{langs.TimeOut}</th>
                                            <th className = "py-2">الحالة</th>
                                            {this.props.authInfo.userType == UserType.SUPER_ADMIN &&
                                                <th className = "py-2">خيارات</th>
                                            }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.Attendance.attendance.length > 0 && this.state.Attendance.attendance.map((value, index) => {
                                            return (
                                                <tr key = {index}
                                                    className = {"cursor-pointer hover:bg-blue-200 hover:font-bold " + (index % 2 === 0 ? "bg-gray-100" : "")}>
                                                    <td className = "py-2">{index + 1}</td>
                                                    <td id = {"date-" + index}
                                                        className = "py-2">{value.date != null && (new Date(value.date).toLocaleDateString())}</td>
                                                    <td id = {"dateIn-" + index}
                                                        className = {"py-2 " + (value.dateIn == null && " text-red-500")}>
                                                        {value.dateIn != null ? (new Date(value.dateIn).toLocaleTimeString()) : langs.no_data}
                                                    </td>
                                                    <td id = {"dateOut-" + index}
                                                        className = {"py-2 " + (value.dateOut == null && " text-red-500")}>{value.dateOut != null ? (new Date(value.dateOut).toLocaleTimeString()) : langs.no_data}</td>
                                                    <td>
                                                        <p id = {"attendanceType-" + index} hidden = {true}>{value.type}</p>
                                                        <p id = {"shift-" + index} hidden = {true}>{value.shift}</p>
                                                        {this._enumTranslate.GetAttendanceTypeAr(value.type)}
                                                    </td>
                                                    {this.props.authInfo.userType == UserType.SUPER_ADMIN &&
                                                        <td id = {"options-" + index} className = "flex items-center justify-center gap-2">
                                                            <button id = {"edit-" + index} type = "button"
                                                                    onClick = {() => this.editAttendance(index, value.id, value.shift)}
                                                                    title = {"تعديل"}
                                                                    className = "shadow bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white sm:px-2 py-2 md:px-4 border border-blue-500 hover:border-transparent rounded-sm ">
                                                                <FontAwesomeIcon icon = {faEdit} className = "mr-0" />
                                                            </button>
                                                            <button id = {"delete-" + index} type = "button"
                                                                    onClick = {() => this.deleteAttendance(index, value.id)}
                                                                    title = {"حذف"}
                                                                    className = "shadow bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white sm:px-2 py-2 md:px-4 border border-red-500 hover:border-transparent rounded-sm ">
                                                                <FontAwesomeIcon icon = {faTrash} className = "mr-0" />
                                                            </button>
                                                        </td>

                                                    }
                                                </tr>
                                            )
                                        })
                                        }
                                        </tbody>
                                    </table>
                                </div>

                                {/*Edit Window*/}
                                <div id = "editWindow" hidden = {true} className = "w-full h-full fixed bg-black bg-opacity-50 z-50 items-center justify-center top-0 right-0">
                                    <div className = "flex flex-col gap-4 bg-blue-100 rounded shadow p-8">
                                        <div className = "grid grid-cols-2 items-center">
                                            <label htmlFor = "dateIn">التاريخ</label> <label id = "date"></label>
                                        </div>
                                        <div className = "grid grid-cols-2 items-center">
                                            <label htmlFor = "dateIn">وقت الدخول</label>
                                            <input id = "dateIn" type = "time" className = "p-4 rounded shadow" />
                                        </div>
                                        <div className = "grid grid-cols-2 items-center">
                                            <label htmlFor = "dateOut">وقت الخروج</label>
                                            <input id = "dateOut" type = "time" defaultValue = "00:00"
                                                   className = "p-4 rounded shadow" />
                                        </div>
                                        <div className = "grid grid-cols-2 items-center">
                                            <label htmlFor = "attendanceType">الحالة</label>
                                            <select id = "attendanceType" className = "p-4 rounded shadow">
                                                {Object.keys(AttendanceType).filter(item => !isNaN(parseInt(item))).map((a, b) => {
                                                    return (
                                                        <option key = {b} value = {b} className = "">{this._enumTranslate.GetAttendanceTypeAr(b)}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className = "grid grid-cols-2 items-center">
                                            <label htmlFor = "shift">الشفت</label>
                                            <select id = "shift" className = "p-4 rounded shadow">
                                                {Object.keys(Shift).filter(item => !isNaN(parseInt(item))).map((a, b) => {
                                                    return (
                                                        <option key = {b} value = {b} className = "">{this._enumTranslate.GetShiftAr(b)}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className = "grid grid-cols-2 gap-2 items-center">
                                            <button type = "button"
                                                    className = "shadow bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded-sm text-sm"
                                                    onClick = {() => this.submitEdit()}>
                                                حفظ
                                            </button>
                                            <button type = "button"
                                                    className = "shadow bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded-sm text-sm"
                                                    onClick = {() => this.cancelEdit()}>
                                                الغاء
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {/* NOTES */}
                        {this.state.employeeAttendancePanel.notes &&
                            <>
                                {/*Panels*/}
                                {(this.props.authInfo.userType == UserType.ADMIN || this.props.authInfo.userType == UserType.SUPER_ADMIN) &&
                                    <div className = "flex border-b border-blue-600 mb-5 sm:mr-0 md:mr-8 sm:text-xxs md:text-sm">
                                        <p className = {"p-3 cursor-pointer hover:text-blue-600" + (this.state.employeeAttendancePanel.remainderNotes ? " border-b-4 border-blue-600" : "")} onClick = {() => this.changePanel(EmployeeAttendancePanelType.REMAINDER_NOTES)}>عامه</p>
                                        <p className = {"p-3 cursor-pointer hover:text-blue-600" + (this.state.employeeAttendancePanel.bookNotes ? " border-b-4 border-blue-600" : "")} onClick = {() => this.changePanel(EmployeeAttendancePanelType.BOOK_NOTES)}>كتب</p>
                                        <p className = {"p-3 cursor-pointer text-green-600 " + (this.state.createNote ? " border-b-4 border-green-600" : "")} onClick = {() => this.setState({createNote: true})}>اضافة ملاحظة +</p>
                                    </div>
                                }


                                {/* Add Note */}
                                {(this.props.authInfo.userType == UserType.ADMIN || this.props.authInfo.userType == UserType.SUPER_ADMIN) && this.state.createNote &&
                                    <div className = "grid grid-cols-2 gap-2 items-center mr-8 border border-gray-400 rounded shadow p-4">
                                        <label className = "p-2 grid gap-1">عنوان الملاحظه
                                            <input onChange = {(e) => this.setState({
                                                employeeNote: {
                                                    ...this.state.employeeNote,
                                                    title: e.target.value
                                                }
                                            })} className = "p-2 border border-gray-400 shadow rounded" type = "text" placeholder = "العنوان..." />
                                        </label>
                                        <label className = "p-2 grid gap-1">وصف الملاحظة
                                            <textarea onChange = {(e) => this.setState({
                                                employeeNote: {
                                                    ...this.state.employeeNote,
                                                    description: e.target.value
                                                }
                                            })} style = {{
                                                minHeight: "39px",
                                                height: "39px"
                                            }} className = "p-2 border border-gray-400 shadow rounded" placeholder = "الوصف..."></textarea>
                                        </label>
                                        <hr />
                                        <hr />
                                        <label htmlFor = "noteType" className = "p-2 flex gap-2 cursor-pointer select-none">
                                            <input id = "noteType" type = "checkbox" value = "true" checked = {this.state.employeeNote.noteType == EmployeeNoteType.BOOK} onChange = {(e) => {
                                                if (e.target.checked) {
                                                    this.setState({
                                                        employeeNote: {
                                                            ...this.state.employeeNote,
                                                            noteType: EmployeeNoteType.BOOK
                                                        }
                                                    })
                                                } else {
                                                    this.setState({
                                                        employeeNote: {
                                                            ...this.state.employeeNote,
                                                            noteType: EmployeeNoteType.NOTE
                                                        }
                                                    })
                                                }
                                            }} />
                                            كتاب رسمي او طلب
                                        </label>
                                        <label className = "grid gap-1" htmlFor = "fileName">صورة الكتاب
                                            <input accept = "image/png, image/jpeg, image/heic" disabled = {this.state.employeeNote.noteType == EmployeeNoteType.NOTE} onChange = {(e) => this.validateFileType(e)} className = "p-2 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id = "fileName" type = "file" />
                                        </label>
                                        <hr />
                                        <hr />
                                        <button id = {"createBtn"} className = "flex-auto text-green-500 bg-white border border-green-500 hover:bg-green-500 hover:text-white duration-75 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded py-2 text-center"
                                                onClick = {() => this.addNote()}>اضافة
                                        </button>
                                        <button id = {"createBtn"} className = "flex-auto text-red-500 bg-white border border-red-500 hover:bg-red-500 hover:text-white duration-75 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded py-2 text-center"
                                                onClick = {() => this.setState({createNote: false})}>الغاء
                                        </button>
                                    </div>
                                }

                                {/* Reminder Notes */}
                                {this.state.employeeAttendancePanel.remainderNotes &&
                                    <div className = "grid gap-4 mb-16 sm:mr-0 md:mr-8 sm:text-xxs md:text-sm">
                                        {this.state.employeeNotes.length > 0 &&
                                            this.state.employeeNotes.filter(note => note.noteType == EmployeeNoteType.NOTE).map((note, index) => (
                                                <div className = "grid gap-2 border border-gray-400 p-5 rounded shadow">
                                                    <div key = {index} className = "grid grid-cols-2">
                                                        <h3 className = "font-bold">{note.title}</h3>
                                                        <p style = {{justifySelf: "end"}} dir = "ltr">{new Date(note.createdDate ?? "").toLocaleDateString() + " " + new Date(note.createdDate ?? "").toLocaleTimeString()}</p>
                                                    </div>
                                                    <hr />
                                                    <pre className = "px-4">{note.description}</pre>
                                                </div>
                                            ))
                                        }
                                    </div>
                                }


                                {/* Book Notes */}
                                {(this.state.employeeAttendancePanel.bookNotes || this.props.authInfo.userType == UserType.DEPARTMENT) &&
                                    <div className = "grid gap-4 mb-16 sm:mr-0 md:mr-8 sm:text-xxs md:text-sm">
                                        {this.state.employeeNotes.length > 0 &&
                                            this.state.employeeNotes.filter(note => note.noteType == EmployeeNoteType.BOOK).map((note, index) => (
                                                <div className = "grid gap-2 border border-gray-400 p-5 rounded shadow">
                                                    <div key = {index} className = "grid grid-cols-2">
                                                        <h3 className = "font-bold">{note.title}</h3>
                                                        <p style = {{justifySelf: "end"}} dir = "ltr">{new Date(note.createdDate ?? "").toLocaleDateString() + " " + new Date(note.createdDate ?? "").toLocaleTimeString()}</p>
                                                    </div>
                                                    <hr />
                                                    <pre className = "px-4">{note.description}</pre>
                                                    <hr />
                                                    <div className = "w-full m-auto">
                                                        <a style = {{cursor: "zoom-in"}} target = "_blank" href = {"/employeeBooks/" + note.imagePath}>
                                                            <img style = {{height: "300px"}} className = "max-w-full object-contain m-auto" alt = "No Image" src = {"/employeeBooks/" + note.imagePath} />
                                                        </a>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                            </>
                        }

                    </div>
                }

                {(this.state.Attendance.attendance.length === 0 && !this.state.loading) &&
                    <div className = "bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role = "alert">
                        <p className = "text-sm text-center">{langs.no_data}</p>
                    </div>
                }
            </div>
        );
    }
}

export default connect((store: Store) => {
    return {
        authInfo: store.LoginReducer
    }
})(withRouter(EmployeeAttendanceComponent))