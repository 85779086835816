import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faUsers,
    faUser,
    faArrowAltCircleRight,
    faList,
    faBuilding,
    faHouseUser,
    faPersonBooth
} from "@fortawesome/free-solid-svg-icons";
import {Action, Store} from "../../Bootstrap/Store";
import {connect} from "react-redux";
import {AuthInfo} from "../Login/Data/Types";
import {LoginAction} from "../Login/Data/Actions/Actions";
import {langs} from "../../Bootstrap/Langs/Lang";
import {ChangeLangAction} from "../../Bootstrap/Langs/Data/Actions/Actions";
import {Langauge} from "../../Bootstrap/Langs/Data/Types";
import {withRouter} from 'react-router-dom';
import AttendanceIO from "../Attandancees/IO/AttendanceIO";
import {UserType} from "./Types";


interface Props {
    dispatch: (action: Action<any>) => void;
    authInfo: AuthInfo;
    history: any;
    langauge: Langauge;

}

interface State {
    departmentName: string;
}

class SideBar extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {departmentName: ""};
        this._attendanceIO = new AttendanceIO();
    }

    async componentDidMount() {
        if (this.props.authInfo.userType == UserType.DEPARTMENT) {
            const departments = await this._attendanceIO.fetchDepartment();
            this.setState({departmentName: departments.data.find(dep => dep.id == this.props.authInfo.depId)?.title || "قسم غير معروف"});
        } else {
            this.setState({departmentName: this.props.authInfo.depName});
        }

    }

    private _attendanceIO: AttendanceIO;

    logout = () => {
        this.props.dispatch(LoginAction({
            depName: '',
            depId: 0,
            token: '',
            userId: 0,
            expirationDate: '',
            userType: '',
            username: ''
        }))
    };

    changeLangar = () => {

    };

    changeLangen = () => {
        this.props.dispatch(ChangeLangAction({
            selected: "en"
        }))
    };

    changeLang = () => {
        if (this.props.langauge.selected == "en") {
            this.props.dispatch(ChangeLangAction({
                selected: "ar"
            }))
        } else {
            this.props.dispatch(ChangeLangAction({
                selected: "en"
            }))
        }

    };

    navigate = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, url: string) => {
        e.preventDefault();
        this.props.history.push(url)
    }

    liStyle = {
        borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
        borderLeft: '1px solid rgba(255, 255, 255, 0.05)'
    }

    render() {
        return (
            <div
                className = "bg-gray-900 sm:w-full md:w-1/5 lg:w-1/6 text-center fixed bottom-0  md:top-0 md:right-0 h-16 sm:h-16 md:h-screen md:border-gray-600">

                <div style = {{backgroundImage: `url('/bg1.jpg')`}}
                     className = "w-full h-48 img bg-wrap text-center py-4 relative md:block sm:hidden">
                    <div className = "absolute top-0 right-0 z-0  w-full h-full bg-teal-900 opacity-25"></div>
                    <img className = "rounded-full relative z-10 mx-auto w-24 h-24 mt-5 " src = {"/logo.svg"} alt = "Logo" />
                    <p className = "text-white font-bold relative mt-5 w-full z-10">{this.state.departmentName}</p>
                </div>

                <div className = "md:relative sm:h-full md:h-auto">
                    <ul className = "list-reset flex flex-row md:flex-col text-center md:text-right sm:h-full ">

                        {(this.props.authInfo.userType == "ADMIN" || this.props.authInfo.userType == "SUPER_ADMIN") &&
                            <li className = "flex-1 sm:border-l md:border-0 " style = {this.liStyle}>
                                <a href = "#" onClick = {(e) => this.navigate(e, "/")}
                                   className = "flex sm:flex-col md:flex-row items-center sm:justify-center md:justify-start sm:gap-0 md:gap-3 md:p-4 transition duration-500  text-gray-800 no-underline hover:bg-blue-500  sm:h-full  md:h-auto">
                                    <FontAwesomeIcon icon = {faBuilding} className = "text-white  sm:text-sm" />
                                    <span
                                        className = "pb-1 md:pb-0 sm:text-xs md:text-sm text-white md:text-white block md:inline-block ">{langs.Departments}</span>
                                </a>
                            </li>
                        }

                        <li className = "flex-1 sm:border-l md:border-0 " style = {this.liStyle}>
                            <a href = "#" onClick = {(e) => this.navigate(e, "/Attendance")}
                               className = "flex sm:flex-col md:flex-row items-center sm:justify-center md:justify-start sm:gap-0 md:gap-3 md:p-4 transition duration-500  text-gray-800 no-underline hover:bg-blue-500  sm:h-full  md:h-auto">
                                <FontAwesomeIcon icon = {faUsers} className = "text-white  sm:text-sm" />
                                <span
                                    className = "pb-1 md:pb-0 sm:text-xs md:text-sm text-white md:text-white block md:inline-block ">{langs.Attandance}</span>
                            </a>
                        </li>

                        <li className = "flex-1 sm:border-l md:border-0 " style = {this.liStyle}>
                            <a href = "#" onClick = {(e) => this.navigate(e, "/createEmployee")}
                               className = "flex sm:flex-col md:flex-row items-center sm:justify-center md:justify-start sm:gap-0 md:gap-3 md:p-4 transition duration-500  text-gray-800 no-underline hover:bg-blue-500  sm:h-full  md:h-auto">
                                <FontAwesomeIcon icon = {faUser} className = "text-white  sm:text-sm" />
                                <span
                                    className = "pb-1 md:pb-0 sm:text-xs md:text-sm text-white md:text-white block md:inline-block ">{langs.employees}</span>
                            </a>
                        </li>

                        <li className = "flex-1 sm:border-l md:border-0 " style = {this.liStyle}>
                            <a href = "#" onClick = {(e) => this.navigate(e, "/Vacations")}
                               className = "flex sm:flex-col md:flex-row items-center sm:justify-center md:justify-start sm:gap-0 md:gap-3 md:p-4 transition duration-500 text-gray-800 no-underline hover:bg-blue-500  sm:h-full  md:h-auto">
                                <FontAwesomeIcon icon = {faPersonBooth} className = "text-white  sm:text-sm" />
                                <span
                                    className = "pb-1 md:pb-0 sm:text-xs md:text-sm text-white md:text-white block md:inline-block">{langs.vacations}</span>
                            </a>
                        </li>


                        {(this.props.authInfo.userType == "ADMIN" || this.props.authInfo.userType == "SUPER_ADMIN") &&
                            <li className = "flex-1 sm:border-l md:border-0 " style = {this.liStyle}>
                                <a href = "#" onClick = {(e) => this.navigate(e, "/Report")}
                                   className = "flex sm:flex-col md:flex-row items-center sm:justify-center md:justify-start sm:gap-0 md:gap-3 md:p-4 transition duration-500  text-gray-800 no-underline hover:bg-blue-500  sm:h-full  md:h-auto">
                                    <FontAwesomeIcon icon = {faList} className = "text-white  sm:text-sm" />
                                    <span
                                        className = "pb-1 md:pb-0 sm:text-xs md:text-sm text-white md:text-white blockmd:inline-block ">{langs.report}</span>
                                </a>
                            </li>
                        }

                        {this.props.authInfo.userType === UserType.SUPER_ADMIN &&
                            <li className="flex-1 sm:border-l md:border-0 " style={this.liStyle}>
                                <a href="#" onClick={(e) => this.navigate(e, "/Admin")}
                                   className="flex sm:flex-col md:flex-row items-center sm:justify-center md:justify-start sm:gap-0 md:gap-3 md:p-4 transition duration-500  text-gray-800 no-underline hover:bg-blue-500  sm:h-full  md:h-auto">
                                    <FontAwesomeIcon icon={faHouseUser} className="text-white  sm:text-sm"/>
                                    <span
                                        className="pb-1 md:pb-0 sm:text-xs md:text-sm text-white md:text-white block md:inline-block ">{langs.admin}</span>
                                </a>
                            </li>
                        }

                        <li className = "flex-1 sm:border-l md:border-0" style = {this.liStyle}>
                            <a href = "#" onClick = {this.logout}
                               className = "flex sm:flex-col md:flex-row items-center sm:justify-center md:justify-start sm:gap-0 md:gap-3 md:p-4 transition duration-500 text-gray-800 no-underline hover:bg-blue-500 sm:h-full  md:h-auto">
                                <FontAwesomeIcon icon = {faArrowAltCircleRight} className = "text-white  sm:text-sm" />
                                <span
                                    className = "pb-1 md:pb-0 sm:text-xs md:text-sm text-white md:text-white block md:inline-block ">{langs.logout}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default connect((store: Store) => {
    return {
        authInfo: store.LoginReducer,
        langauge: store.ChangeLangReducer,
    }
})(withRouter(SideBar))