import {Config, Dates, Shift, UpdateAttendanceStatus} from "../Admin/Data/Types";
import {
    AcademicCertificate,
    Attendance,
    AttendanceType,
    EmployeeAttendance,
    EmployeeAttendanceInfo,
    EmployeeNote,
    EmployeeNoteType,
    EmployeeType,
    Position,
    Vacation,
    VacationResponse,
    VacationStatus,
    VacationType
} from "../Attandancees/Data/Tyeps";

export default class Initializer {
    public config(): Config {
        return {
            disableFree: false,
            endMonth: new Date(),
            startMonth: new Date(),
            allowedContinuesVacationForDepartment: 0,
            allowedVacationCountForDepartment: 0,
            dayOutShift: "",
            dayShiftAfterInTweak: "",
            dayShiftAfterOutTweak: "",
            dayShiftBeforeInTweak: "",
            dayShiftBeforeOutTweak: "",
            eveningOutShift: "",
            eveningShiftAfterInTweak: "",
            eveningShiftAfterOutTweak: "",
            eveningShiftBeforeInTweak: "",
            eveningShiftBeforeOutTweak: "",
            nightOutShift: "",
            nightShiftAfterInTweak: "",
            nightShiftAfterOutTweak: "",
            nightShiftBeforeInTweak: "",
            nightShiftBeforeOutTweak: "",
            dayInShift: "",
            dayInShiftLate: "",
            disableVacation: false,
            eveningInShift: "",
            eveningInShiftLate: "",
            id: 0,
            nightInShift: "",
            nightInShiftLate: ""

        }
    }

    public vacation(empId?: string): Vacation {
        return {
            EmployeeID: (empId == null) ? "" : empId,
            distnation: "",

            fromDate: new Date(),
            setFromDate: (new Date().toLocaleDateString() + " 00:00:00"),
            toDate: new Date(),
            setToDate: (new Date().toLocaleDateString() + " 00:00:00"),

            id: "",
            paid: false,
            needPresidency: false,
            requestedDate: undefined,
            requiredPermition: false,
            resion: "",

            startTime: new Date(),
            setStartTime: "",
            endTime: new Date(),
            setEndTime: "",

            succeed: true,

            unSucceedMessage: "",
            vacationStatus: VacationStatus.PENDING_DEPARTMENT,
            vacationType: VacationType.FUll

        }
    }

    public employee(): Attendance {
        return {
            attendanceType: AttendanceType.Present,
            employeeType: EmployeeType.PART,
            depName: '',
            isHidden: false,
            id: "",
            timeOut: new Date(),
            timeIn: new Date(),
            depId: 0,
            email: "",
            name: "",
            academicCertificate: AcademicCertificate.BACHELORS,
            position: Position.ADMINISTRATIVE,

            d1: true,
            d2: true,
            d3: true,
            d4: true,
            d5: true,
            d6: true,
            d7: false,

            e1: false,
            e2: false,
            e3: false,
            e4: false,
            e5: false,
            e6: false,
            e7: false,

            n1: false,
            n2: false,
            n3: false,
            n4: false,
            n5: false,
            n6: false,
            n7: false,
        }
    }

    public vacationData(): VacationResponse {
        return {
            emp: this.employee(),
            vacation: this.vacation()
        }
    }

    public employeeNote(): EmployeeNote {
        return {
            id: "",
            createdDate: new Date(),
            title: "",
            description: "",
            employeeId: "",
            noteType: EmployeeNoteType.NOTE,
            imagePath: ""
        }
    }

    public employeeAttendance(): EmployeeAttendance {
        return {
            id: 0,
            employeeId: "0",
            depId: 0,
            date: new Date(),
            dateIn: null,
            dateOut: null,
            receivedDateIn: null,
            receivedDateOut: null,
            archive: false,
            archiveReason: "",
            type: AttendanceType.Absence,
            dayShift: false,
            eveningShift: false,
            nighShift: false,
            shift:Shift.Free
        }
    }

    public attendance(): EmployeeAttendanceInfo {
        return {
            vacation: 0,
            absence: 0,
            presence: 0,
            attendance: [],
            employee: this.employee()
        }
    }

    public dates(): Dates {
        return {
            from: new Date(),
            to: new Date()
        }
    }

    public updateAttendanceStatus(): UpdateAttendanceStatus {
        return {
            from: new Date(),
            to: new Date(),
            attendanceType: AttendanceType.Absence,
            present: true,
            absence: false,
            vacation: true,
            late: false,
            night: false,
            free: true,
            assign: true,
            dispatch: true,
            softAbsence: false,
            presidency_Vacation_Paid: true,
            presidency_Vacation_UnPaid: true,
            withShift:false,
            shift:Shift.DayShift
        }
    }
}