import {ILang} from "./Ilang";

export const Ar: ILang = {
    vacation: "اجازة",
    vacations: "اجازات",
    d2: "الاحد", d3: "الاثنين", d4: "الثلاثاء", d5: "الاربعاء", d6: "الخميس", d7: "الجمعة",
    d1: "السبت",
    index: "ت.",
    uploadTime: "وقت الرفع",
    Departments: "الاقسام",
    login: "تسجيل الدخول",
    Search: "بحث",
    Absence: "غياب", Presence: "حضور",
    employeeInfo: "معلومات الموظف",
    date: "التاريخ",
    counter: "ت.",
    logout: "تسجيل خروج",
    lang: "انكليزي",
    Attandance: "الحضور",
    TimeIn: "وقت الدخول",
    TimeOut: "وقت الخروج",
    department: "القسم",
    id: "الهوية",
    delete: "Delete",
    AddContener: "Create Contener",
    save: "Save",
    unexpected_error: "unexpected error",
    RFID: "Rfid",
    des: "description",
    fingerPrintId: "Fid",
    loading_data: "جار تحميل البيانات",
    lpId: "Car Num",
    no_data: "لا يوجد",
    nothing: "لا توجد بيانات",
    clients: "Clients",
    name: "الاسم",
    report: "تقارير",
    admin: "مدير النظام",
    employees: "الموظفين"
}