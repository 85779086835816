export default class Time {

    /**
     * @return new date as string. Format d/M/yyyy
     */
    public newUsDate(): string {
        return new Date().toLocaleDateString("en-US")
    }

    /**
     * @return new date as string. Format M/d/yyyy
     */
    public newGbDate(): string {
        return new Date().toLocaleDateString("en-GB")
    }

    /**
     * @return new date as string. Format yyyy/M/d
     */
    public newCnDate(date?: Date | string): string {
        if (date === undefined) {
            return new Date().toLocaleDateString("zh-Hans-CN")
        } else {
            return new Date(date).toLocaleDateString("zh-Hans-CN")
        }
    }

    /**
     * @param date date as string format: yyyy-M-d
     * @return date as string divided by / and 0 time
     * @example arg (2024-1-28) return (2024/1/28 00:00:00)
     */
    public getDateTimeFromDateInput(date: string): string {
        return date.replaceAll('-', '/') + ' ' + "00:00:00";
    }

    /**
     * @param date date as string format: M-d-yyyy
     * @return date as string divided by -
     * @example arg (2024-1-28) return (2024/1/28 00:00:00)
     */
    public reverseDate(date: string) {
        const dateSplit = date.replaceAll('/', '-').split('-');
        return (dateSplit[2] + '-'
            + (dateSplit[0].length > 1 ? dateSplit[0] : "0" + dateSplit[0]) + '-'
            + (dateSplit[1].length > 1 ? dateSplit[1] : "0" + dateSplit[1]));
    }

    public dateInputValue(date?: Date | string): string {
        let newDate:string[];
        if (date === undefined) {
            newDate = this.newCnDate().replaceAll('/', '-').split('-');
        } else {
            newDate = this.newCnDate(date).replaceAll('/', '-').split('-');
        }
        return (newDate[0] + '-' + (newDate[1].length === 1? ("0" + newDate[1]) : newDate[1]) + '-' + (newDate[2].length === 1? ("0" + newDate[2]) : newDate[2]));
    }
}